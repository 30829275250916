import React from "react";
import { Box, Grid, Stack, Button, Typography } from "@mui/material";
import { useAppDispatch } from "../../app/store";
import AssessmentCompleteStep from "../../assets/AssessmentCompleteStep.svg";
import { Styles } from "../../Styles";
import { useNavigate } from "react-router-dom";
import { PagesPath } from "../../PagesPath";
const SDOHNewAssessmentForm_Step4 = ({
  forceListCall,
  backToStatusListCall,
  source = undefined,
  handleSourceSubmit = undefined,
}) => {
  const navigate = useNavigate();
  const closeForm = () => {
    // var idUrl = PagesPath.SDOHAssessmentDashboard_URL;
    // navigate(idUrl, { searchValue: "" });
  };
  const forceCall = () => {
      backToStatusListCall();
      forceListCall();
  };

  return (
    <Box
      sx={{
        p: { xs: 2, sm: 2 },
      }}
    >
      <Grid container style={{ justifyContent: "center" }}>
        <Grid item xs={8}>
          <Stack
            direction="column"
            spacing={1}
            sx={{
              borderRadius: "4px",
              // border: "1px solid #E95110",
              background: "#FFF",
              backgroundColor: "#FFF",
              p: 1,
            }}
          >
            <img src={AssessmentCompleteStep} alt="" />
            <Typography
              sx={{
                textAlign: "center",
                letterSpacing: "1px",
                color: "#DC4F34",
                fontFamily: Styles.FONT_FAMILY_GREYCLIFFREG,
                fontWeight: "400",
                lineHeight: "15.95px",
                fontSize: "20px",
                paddingRight: 2,
              }}
            >
              Thank You For Completing the Assessment
            </Typography>

                        <Typography
                            sx={{
                                textAlign: "center",
                                letterSpacing: "1px",
                                color: "#212121",
                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                fontWeight: "400",
                                lineHeight: "15.95px",
                                fontSize: "16px",
                                paddingRight: 2

                            }}
                        >
                            Your inputs is vital in helping us understand.
                        </Typography>

                        <Typography
                            sx={{
                                textAlign: "center",
                                letterSpacing: "1px",
                                color: "#212121",
                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                fontWeight: "400",
                                lineHeight: "15.95px",
                                fontSize: "16px",
                                paddingRight: 2
                            }}
                        >
                            We truly appreciate your participation!
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
           { (!source || source != "activeGapClosure")?(<Grid container spacing={1} justifyContent="left">
                    <Grid item xs={12}>
                        <Button style={{ color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="contained" color="orangeTheme" disableElevation onClick={forceCall}>Return to SDOH Tool</Button>
                       </Grid>
            </Grid>):
            (
              <Grid container spacing={1} justifyContent="left">
                    <Grid item xs={12}>
                        <Button style={{ color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="contained" color="orangeTheme" disableElevation onClick={()=>handleSourceSubmit()}>Return</Button>
                       </Grid>
            </Grid>
            )}
        </Box>
    );
}
export default SDOHNewAssessmentForm_Step4;
