import { FormControl } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { addDays } from "date-fns/addDays";
import React from "react";

const DatePickerOutlined = ({
  label,
  value,
  helperText,
  onChange,
  sx,
  className,
  disableFuture,
  handleError,
  readOnly,
}) => {
  const minDate = addDays(new Date(1753, 0, 0), 1);
  return (
    <FormControl
      required
      color="orangeTheme"
      //   sx={{ minWidth: "294px", maxWidth: "294px", height: "40px" }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          readOnly={readOnly}
          onError={handleError}
          minDate={minDate}
          disableFuture={disableFuture}
          className={className}
          slotProps={{
            textField: {
              error: !!helperText,
              required: true,
              color: "orangeTheme",
            },
          }}
          sx={{ ...sx }}
          required
          label={label}
          value={value}
          onChange={(e) => onChange(e)}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default DatePickerOutlined;
