import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/Agent";
const initialState = {
  isLoading: false,
  practice:[],
  errorMessage: "",
};

export const getPracticeList = createAsyncThunk(
  "market",
  async ({market,submarket}) => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.GetPracticeDetails({market,submarket});
    return response;
  }
);

const sdohPracticeListSlice = createSlice({
  name: "practiceList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPracticeList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPracticeList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.practice = action.payload;
        state.errorMessage="";
      })
      .addCase(getPracticeList.rejected, (state, action) => {
        state.isLoading = false;
        state.practice = [];
        state.errorMessage = action.error.message;
      })
  },
});




export default sdohPracticeListSlice.reducer;
