import React, { useState, useEffect } from "react";
import {
    Box,
    Modal,
    Stack,
    Button,
    Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Styles } from "../../../Styles";
import { useAppDispatch } from "../../../app/store";
import { axiosRequests } from "../../../app/api/AxiosRequest";
const DeletePopupModal = ({ isOpen, onClose, rowData, information, message, handleDelete }) => {
    const dispatch = useAppDispatch();
   
    const [memberName, setMemberName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [oldContactNumber, setOldContactNumber] = React.useState("");
    const [oldEmailAddress, setOldEmailAddress] = React.useState("");
    
    const { memberDetail } = useSelector((state) => state.filterDetails);

    const closePopup = () =>{
        onClose();
    }

    useEffect(() => {
        // if (memberDetail && memberDetail?.ourId != undefined) {
        //     axiosRequests
        //         .get(
        //             `/SDOH/GetMemberEmailID?ourId=${memberDetail?.ourId
        //             }`
        //         )
        //         .then((resp) => {
        //             if (resp != undefined) {
        //                 setMemberName(resp?.memberName);
        //                 setContactNumber(resp?.contactNumber);
        //                 setEmailAddress(resp?.emailAddress);
        //                 setOldContactNumber(resp?.contactNumber);
        //                 setOldEmailAddress(resp?.emailAddress);
                        
        //             }
        //         });
        // }

    }, [memberDetail]);

    const handleDeleteCall = () => {

        let hasError = false;

        if (!hasError) {
            
            const formData = {
                cohortId: rowData.id,
             }
                axiosRequests.put('/Cohort/DeleteCohortDetails',formData).then(resp => {
                    if(resp===1){
                        handleDelete();
                    }
                })
        }

    }
    if (!rowData) return;
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            slotProps={{ backdrop: { onClick: null } }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    width: "578px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    pt: 1,
                    height: "auto",
                }}
            >
                <h3
                    style={{ fontSize: "16px", fontWeight: 600, lineHeight: "24px", color: "#000000" }}
                >
                    {information}
                    <span>
                        <CloseIcon onClick={closePopup} style={{ float: "right" }} />
                    </span>
                </h3>
                <hr
                    style={{
                        width: "auto",
                        opacity: "0.3",
                        marginTop: "-11px",
                        marginBottom: "5px",
                    }}
                />
                <Stack direction="column" sx={{ marginTop: '23px' }} spacing={2}>
                    <Typography style={{ fontWeight: 500, color: "#000000" }}>
                        {/* <b> */}
                            {message}
                        {/* </b> */}
                    </Typography>
                </Stack>
                {/* <hr
                    style={{
                        width: "auto",
                        opacity: "0.3",
                        marginTop: "16px",
                    }}
                /> */}
                
                <Stack
                    direction="column"
                    sx={{
                        marginTop: 1,
                    }}
                ></Stack>
                <Stack direction="row" spacing={2} style={{ marginTop: '8px' }}>
                    <Button
                        style={{
                            textTransform: "none",
                            borderColor: "black",
                            color: "black",
                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                            marginLeft: "auto",
                        }}
                        variant="outlined"
                        onClick={closePopup}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            textTransform: "none",
                            color: "white",
                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                        }}
                        variant="contained"
                        color="orangeTheme"
                        disableElevation
                        onClick={handleDeleteCall}
                    >
                        Confirm
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default DeletePopupModal;
