import Login from "./pages/login/Login";
import { MsalProvider } from "@azure/msal-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import ApplicationRoute from "./component/common/ApplicationRoute";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import UserAuthorization from "./component/common/userAuthorization/UserAuthorization";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#fff",
    },
    background: {
      default: "#FAFAFA",
    },
    actionButton: {
      main: "#59A96A",
      light: "#E9DB5D",
      dark: "#A29415",
      contrastText: "#fff",
    },
    orangeTheme: {
      main: "#DC4F34",
      light: "#E9DB5D",
      dark: "#DC4F34",
      contrastText: "#000",
    },
    blackTheme: {
      main: "#000",
      light: "#000",
      dark: "#000",
      contrastText: "#000",
    },
    multiline: {
      fontWeight: "bold",
      fontSize: "20px",
      color: "purple",
      width: "50vw",
    },
  },
});

function App({ msalInstance }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <div className="app">
            <main className="content">
              <AuthenticatedTemplate>
                <UserAuthorization>
                  <ApplicationRoute />
                </UserAuthorization>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Login />
              </UnauthenticatedTemplate>
            </main>
          </div>
        </MsalProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
