import { FamilyMemberProfileAgent } from "../api/familyMemberProfile/FamilyMemberProfileAgent";
import { PowerBiAgent } from "../api/powerBi/PowerBiAgent";
import { DerosteringTrackingAgent } from "../api/derosteringTracking/DerosteringTrackingAgent";
import { VoluntaryAlignmentAgent } from "../api/voluntaryAlignment/voluntaryAlignmentAgent";
import { UserDataAgent } from "./userAdminConsole/userDataAgent";
import { UserRolesDataAgent } from "./userAdminConsole/userRolesDataAgent";
import { RoleMappingDataAgent } from "./userAdminConsole/roleMappingDataAgent";
import { UserRoleMappingDataAgent } from "./userAdminConsole/userRoleMappingDataAgent";
import { PillarsToolsScorecardsAgent } from "./pillarsToolsScorecards/pillarsToolsScorecardsAgent";
import {SDOHAssessmentTrackingAgent} from "./sdohassessment/SDOHAssessmentTrackingAgent";
import { LoginAgent } from "./login/LoginAgent";
import { MedicalAdherenceToolAgent } from "./medicalAdherenceTool/medicalAdherenceToolAgent";
import { ProgramDataAgent } from "./programsData/ProgramsDataAgent";
import { ActiveGapClosuresAgent } from "./activeGapClosures/ActiveGapClosuresAgent";
import { HealthHomeEducationAgent } from "./HealthHomeEducation/HealthHomeEducationAgent";

const agent = {
  FamilyMemberProfileAgent,
  DerosteringTrackingAgent,
  VoluntaryAlignmentAgent,
  PowerBiAgent,
  UserDataAgent,
  UserRolesDataAgent,
  RoleMappingDataAgent,
  UserRoleMappingDataAgent,
  PillarsToolsScorecardsAgent,
  SDOHAssessmentTrackingAgent,
  LoginAgent,
  MedicalAdherenceToolAgent,
  ProgramDataAgent,
  ActiveGapClosuresAgent,
  HealthHomeEducationAgent
};

export default agent;
