import { axiosRequests } from "../AxiosRequest";

function getDerosteringTracking(patientName, sortColumn, sortOrder, page = 1) {
  if (
    patientName !== undefined &&
    patientName !== null &&
    patientName.trim() !== ""
  ) {
  } else patientName = "";
  if (sortColumn === undefined) sortColumn = "";
  if (sortOrder === undefined) sortOrder = "";
  return axiosRequests.get(
    `/Deroster/GetRosterMembersListWithCount?patientName=${patientName}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&pageNumber=${page}`
  );
}

function updateDerosteringTracking(formdata) {
  return axiosRequests.put("/Deroster/UpdateDerosteringDetails", formdata);
}

function getDerosteringReasons(payorId, payorContractType) {
  return axiosRequests.get(
    `/Deroster/GetDerosteringReason?PayorId=${payorId}&PayorContractType=${payorContractType}`
  );
}

function getMemberDetailsByOurId(ourId) {
  return axiosRequests.get(`/Deroster/GetMemberDetails?ourId=${ourId}`);
}

function downloadDerosteringMembers() {
  return axiosRequests.downloadFile(`/Deroster/ExportDerosteringMembers`);
}

function getDerosteringTrackingCount(patientName) {
  if (
    patientName !== undefined &&
    patientName !== null &&
    patientName.trim() !== ""
  )
    return axiosRequests.get(
      `/Deroster/GetRosterMembersCount?patientName=${patientName}`
    );
  else return axiosRequests.get("/Deroster/GetRosterMembersCount");
}

export const DerosteringTrackingAgent = {
  getDerosteringTracking: getDerosteringTracking,
  updateDerosteringTracking: updateDerosteringTracking,
  getDerosteringReasons: getDerosteringReasons,
  getMemberDetailsByOurId: getMemberDetailsByOurId,
  downloadDerosteringMembers: downloadDerosteringMembers,
  getDerosteringTrackingCount: getDerosteringTrackingCount,
};
