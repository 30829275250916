import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/Agent";

const initialState = {
  isLoading: false,
  derosteringReasons: [],
  errorMessage: "",
};

export const getDerosteringReasons = createAsyncThunk(
  "getDerosteringReasons",
  async ({ payorId, payorContractType }) => {
    const response =
      await agent.DerosteringTrackingAgent.getDerosteringReasons(
        payorId,
        payorContractType
      );
    return response;
  }
);

const derosteringReasonsSlice = createSlice({
  name: "getDerosteringReasons",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDerosteringReasons.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDerosteringReasons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.derosteringReasons = action.payload;
      })
      .addCase(getDerosteringReasons.rejected, (state, action) => {
        state.isLoading = false;
        state.derosteringReasons = [];
        state.errorMessage = action.error.message;
      });
  },
});

export default derosteringReasonsSlice.reducer;
