import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const VoluntaryAlignmentHeader = () => {
  const title = "Voluntary Alignment";
  const sevenPillarTitle = "Practice VBC Revenue & Growth";
  return <PageHeader title={title} sevenPillarTitle={sevenPillarTitle} />;
};

export default VoluntaryAlignmentHeader;
