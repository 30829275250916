
import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import ScorecardComponent from "../../component/common/scorecard/scorecard";
const SpendSummary = () => {
    const [keyName,setKeyName]=useState("Spend Summary");
    const [isTab,setIsTab]=useState("Yes");
    return (
        <Box >
            <ScorecardComponent keyName={keyName} isTab={isTab} tabParentScorecardId={5}/>
        </Box>
    );
}

export default SpendSummary;