import axios from "axios";
import { getAPI_URL } from "../../webConfig";

import { InteractionRequiredAuthError } from "msal";
import { msalInstance } from "../../index";

export async function getToken() {
  var token = `Bearer ${localStorage.getItem("accessToken")}`;
  const currentAccount = msalInstance.getActiveAccount();
  const accessTokenRequest = {
    scopes: ["user.read"],
    account: currentAccount,
  };
  if (currentAccount) {
    try {
      // if (currentAccount.tenantId == msalConfig.auth.tenantId) {
      const accessTokenResponse = await msalInstance.acquireTokenSilent(
        accessTokenRequest
      );
      localStorage.setItem("accessToken", accessTokenResponse.idToken);
      return `Bearer ${accessTokenResponse.idToken}`;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const accessTokenResponse =
          msalInstance.acquireTokenPopup(accessTokenRequest);
        localStorage.setItem("accessToken", accessTokenResponse.idToken);
        return `Bearer ${accessTokenResponse.idToken}`;
      } else {
        const currentAccount = msalInstance.getActiveAccount();
        await msalInstance.logoutPopup({ account: currentAccount });
      }
    }
    // }
  }
  return null;
}

const baseURL = getAPI_URL();

axios.defaults.baseURL = baseURL;

const responseBody = (response) => response.data;
const response = (response) => response;

export const axiosRequests = {
  get: (url) => axiosPrivate.get(url).then(responseBody),
  post: (url, body) => axiosPrivate.post(url, body).then(responseBody),
  postUpload: (url, body) => axiosPrivate.post(url,body,{
    headers: {'Content-Type': 'multipart/form-data'}
  }),
  put: (url, body) => axiosPrivate.put(url, body).then(responseBody),
  delete: (url) => axiosPrivate.delete(url).then(responseBody),
  downloadFile: (url) =>
    axiosPrivate.get(url, { responseType: "blob" }).then(response),
  downloadFilewithBody: (url, body) =>
    axiosPrivate.post(url, body,{ responseType: "blob" }).then(response),
};

export const axiosPrivate = axios.create({
  // withCredentials: true,
});

axiosPrivate.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.log(error);
    if (
      error.response.status === 401 &&
      error.response.data !== "Unauthorized Access to SevenPillars"
    ) {
      const currentAccount = msalInstance.getActiveAccount();
      await msalInstance.logoutPopup({ account: currentAccount });
    }
    return Promise.reject(error);
  }
);

axiosPrivate.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = await getToken();
    return config;
  },
  async function (error) {
    if (error.response.status === 401) {
      const currentAccount = msalInstance.getActiveAccount();
      await msalInstance.logoutPopup({ account: currentAccount });
    }
    return Promise.reject(error);
  }
);
