import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import ScorecardComponent from "../../component/common/scorecard/scorecard";
const ACOReachReport = () => {
    const [ACOReachReportID,setACOReachReportID]=useState(73);
    return (
        <Box >
            <ScorecardComponent ScoreId={ACOReachReportID} />
        </Box>
    );
}

export default ACOReachReport;
