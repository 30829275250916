import React, { useState } from "react";
import PageHeader from "../../component/common/header/PageHeader";
const HighRiskFamilyMemberCareHeader = () => {
    const title = "Cost and Utilization\/ Medical Expenses";
    const sevenPillarTitle = "High Risk Family Member Care";

    return (
        <>
            <PageHeader title={title} sevenPillarTitle={sevenPillarTitle}>
            </PageHeader>
        </>
    );
};

export default HighRiskFamilyMemberCareHeader;
