
import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import HighRiskFamilyMemberCareHeader from "./HighRiskFamilyMemberCareHeader";
import ScorecardComponent from "../../component/common/scorecard/scorecard";
const HighRiskCareExcelLinkPage = () => {
    const [highRiskCareID,setHighRiskCareID]=useState(61);
    return (
        <Box >
            <HighRiskFamilyMemberCareHeader />
            <ScorecardComponent ScoreId={highRiskCareID} />
        </Box>
    );
}

export default HighRiskCareExcelLinkPage;