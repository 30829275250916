import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/store";
import cinqCareLogo_OnlyC from "../../../assets/cinqCareLogo_OnlyC.png";
import { getPillarsToolsScorecardsList } from "../../../features/pillarsToolsScorecards/pillarsToolsScorecardsSlice";
import SevenPillarSidebarMenuItem from "../sevenPillarSidebarMenuItem/SevenPillarSidebarMenuItem";
import ToolbarContent from "../toolbar/ToolbarContent";
import MiniDrawerHeader from "./MiniDrawerHeader";
import { Box } from "@mui/material";
import { useLayoutEffect } from "react";
const drawerWidth = 305;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "center",
  border: "none",
  width: "100%",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "noAccess",
})(({ theme, open, noAccess }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open
    ? {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    : {
        marginLeft: 64,
        width: noAccess ? "100%" : `calc(100% - 64px)`,
      }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ noAccess = false, children }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { isLoading, pillars, errorMessage } = useSelector(
    (state) => state.pillarsToolsScorecard
  );

  const getPillarsToolsScorecardListData = useCallback(async () => {
    await dispatch(getPillarsToolsScorecardsList());
  }, [dispatch]);

  useLayoutEffect(() => {
    if (pillars === undefined || pillars.length === 0)
      getPillarsToolsScorecardListData();
  }, [getPillarsToolsScorecardListData]);

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const handleDrawerOpenClose = () => {
  //   setOpen((open) => !open);
  // };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        noAccess={noAccess}
        elevation={1}
        sx={{
          background: "#212121 0% 0% no-repeat padding-box",
          borderBottom: "1px solid #E2E2E2",
          opacity: "1",
        }}
        open={open}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            disableFocusRipple={true}
            disableRipple={true}
            type="button"
            style={{ display: noAccess ? "none" : "" }}
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <FormatAlignLeftIcon
              sx={{
                color: "white",
              }}
            />
          </IconButton>
          <ToolbarContent />
        </Toolbar>
      </AppBar>
      <Drawer
        style={{ display: noAccess ? "none" : "" }}
        variant="permanent"
        open={open}
      >
        <DrawerHeader>
          {open ? (
            <MiniDrawerHeader handleDrawerClose={handleDrawerClose} />
          ) : (
            <IconButton
              onClick={() => navigate("/")}
              disableFocusRipple={true}
              disableRipple={true}
              type="button"
            >
              <img
                src={cinqCareLogo_OnlyC}
                style={{
                  width: "70px",
                  height: "70px",
                  float: "left",
                }}
              />
            </IconButton>
          )}
        </DrawerHeader>
        {isLoading ? (
          <></>
        ) : (
          <List disablePadding={true} spacing={0} sx={{ padding: 0 }}>
            {pillars
              .filter((x) => x.key !== null)
              .map((pillar, index) => (
                <SevenPillarSidebarMenuItem
                  pillar={pillar}
                  key={pillar.id}
                  disablePadding
                  miniDrawerOpen={open}
                  sx={{ display: "block" }}
                  //handleDrawerClose={handleDrawerClose}
                />
              ))}
          </List>
        )}
      </Drawer>
      <Box
        component="main"
        className="content"
        sx={{
          flexGrow: 1,
          p: 1.5,
          background: "#FAFAFA 0% 0% no-repeat padding-box",
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
