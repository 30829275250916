import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PagesPath } from "../../../PagesPath";
import { useDispatch } from "react-redux";
import { getSearchValue } from "../../../features/familyMembersProfile/familyMembersProfileSearchSlice";
import { Styles, COLOR } from "../../../Styles";

const SearchBar = () => {
  const [noAccess, setNoAccess] = useState(false);
  useEffect(() => {
    var noAccess = localStorage.getItem("noAccess");
    var isLearningUser = parseInt(localStorage.getItem("learningUser"));
    if (parseInt(noAccess) === 1 || isLearningUser === 1) {
      setNoAccess(true);
    }
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  function goToMemberProfile() {
    var idUrl = PagesPath.FAMILY_MEMBER_PROFILE_URL;

    dispatch(getSearchValue(searchValue));
    navigate(idUrl, { searchValue: searchValue });
  }
  return (
    <Stack
      direction="row"
      sx={{
        width: "450px",
        height: "35px",
        background: "#EEE",
        // border: "4px",
        borderRadius: "4px",
      }}
      style={{ display: noAccess ? "none" : "" }}
    >
      <InputBase
        sx={{
          ml: 1,
          flex: 1,
          textAlign: "left",
          // font: "normal normal normal 14px/17px Inter",
          letterSpacing: "0px",
          color: "#6D6D6D",
          fontWeight: "500",
          fontFamily: Styles.FONT_FAMILY_POPPINS,
        }}
        placeholder="Type Here"
        inputProps={{ "aria-label": "Type Here" }}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") goToMemberProfile();
        }}
      />
      <Stack
        direction="row"
        spacing="1px"
        sx={{
          // display: "block",
          alignItems: "center",
          // width: "518px",
          // height: "35px",
          backgroundColor: COLOR.ORANGE,
          borderBottomRightRadius: "4px",
          borderTopRightRadius: "4px",
          // background: "#FFFFFF 0% 0% no-repeat padding-box",
          // border: "1px solid #DEDEDF",
          // borderRadius: "4px",
          opacity: 1,

          // padding: 0,
        }}
      >
        <Link to={PagesPath.FAMILY_MEMBER_PROFILE_URL}>
          <IconButton
            disableFocusRipple={true}
            disableRipple={true}
            type="button"
            sx={{
              // p: "10px",
              // height: "19px",
              marginRight: 1,
              //background: COLOR.ORANGE,
            }}
            aria-label="search"
            onClick={goToMemberProfile}
          >
            <Stack
              direction="row"
              spacing="2px"
              sx={{
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SearchIcon
                sx={{
                  color: "#fff",
                  background: COLOR.ORANGE,
                  width: "0.7em",
                  height: "0.7em",
                }}
              />
              <Typography
                sx={{
                  color: Styles.FONT_COLOR,
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                  fontSize: "14px",
                  fontStyle: Styles.FONT_STYLE_NORMAL,
                  fontWeight: "500",
                  lineHeight: "normal",
                  background: COLOR.ORANGE,
                }}
              >
                Search Family Member
              </Typography>
            </Stack>
          </IconButton>
        </Link>
      </Stack>
    </Stack>
  );
};

export default SearchBar;
