import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const CohortRegistryManagementHeader = () => {
  const title = "Cohort Registry Management";
  const sevenPillarTitle = "High Risk Family Member Care";
  return (
    <>
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle} />
    </>
  );
};

export default CohortRegistryManagementHeader;
