import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import AppRoute from "./AppRoute";

const ApplicationRoute = () => {
  const router = createBrowserRouter(createRoutesFromElements(AppRoute()));  
  return <RouterProvider router={router} />;
};

export default ApplicationRoute;
