import React, { useEffect, useState, useCallback } from "react";
import { PagesPath } from "../../PagesPath";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import SpendSummary from "../vbcPerfomance/SpendSummary";
import AppBar from "@mui/material/AppBar";
import SpendByMemberClaims from "../vbcPerfomance/SpendByMemberClaims";
import ClaimsReduction from "../vbcPerfomance/ClaimsReduction";
import VBCPerfomanceHeader from "../vbcPerfomance/VBCPerfomanceHeader";
import { useNavigate } from "react-router-dom";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import { checkUserScorecardAccess } from "../../component/common/userAuthorization/checkUserAccess";

const VBCPerfomance = () => {
  const VBCPerformanceID = 6;
  const [value, setValue] = React.useState("1");
  const [accessObj]=useState(checkUserScorecardAccess(VBCPerformanceID));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <VBCPerfomanceHeader></VBCPerfomanceHeader>
      {!accessObj.hasAccess ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <TabContext value={value}>
          <div>
            <Box>
              <TabList
                value={value}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#dc4f34",
                  },
                }}
                onChange={handleChange}
              >
                <Tab
                  label="Spend Summary"
                  value="1"
                  className={
                    value === "1"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                    lineheight: "17px",
                  }}
                />
                <Tab
                  label="Spend By Member Claims"
                  value="2"
                  className={
                    value === "2"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                    lineheight: "17px",
                  }}
                />
                <Tab
                  label="Claims Reduction"
                  value="3"
                  className={
                    value === "3"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                    lineheight: "17px",
                  }}
                />
              </TabList>
            </Box>
          </div>
          <TabPanel sx={{ marginLeft: 0.5 }} value="1">
            <SpendSummary />
          </TabPanel>
          <TabPanel sx={{ marginLeft: 0.5 }} value="2">
            <SpendByMemberClaims />
          </TabPanel>
          <TabPanel sx={{ marginLeft: 0.5 }} value="3">
            <ClaimsReduction />
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );
};

export default VBCPerfomance;
