
import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { COLOR, Styles } from "../../../Styles";
import { checkUserScorecardAccess } from "../../common/userAuthorization/checkUserAccess";
import NoAccessPage from "../../common/noAccess/NoAccessPage";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import PowerBiCustomDashboard from "../../common/powerbi/PowerBiCustomDashboard";
import PowerBiCustomReport from "../../common/powerbi/PowerBiCustomReport";

const ScorecardComponent = ({ keyName,isTab, tabParentScorecardId }) => {
    let pathKeyName = localStorage.getItem("pathKeyName");
    const [accessObj,setAccessObj]=useState({});
    const [scorecardObj, setScorecardObj] = useState([]);
    const [dashboardId, setDashboardId] = useState("");
    const [reportId, setReportId] = useState("");
    const [groupId, setGroupId] = useState("");
    const [scoreCardFileName, setScoreCardFileName] = useState("");
    const [scoreCardId, setScoreCardId] = useState("");
    const [scoreCardType, setScoreCardType] = useState("");
    const [scoreCardURL, setScoreCardURL] = useState("");

    const openExcelSheet = (url) => {
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.target = "_blank";
        aTag.click();
        aTag.remove();
    }

    useEffect(() => {
        console.log("isTab",isTab);
        let isTabYesNo= isTab == undefined ? "No" : isTab;
        let name="";
        if(isTab == 'Yes') 
            name= keyName;
        else
            name = pathKeyName;
        axiosRequests
            .get(
                `/UserAccess/GetScorecardDetails?keyName=${name}&isTab=${isTabYesNo}`
            )
            .then((resp) => {
                if (resp || resp.length > 0) {
                    let obj = [];
                    if(resp && resp.scoreCardId != undefined){
                        setGroupId(resp.groupId);
                        setDashboardId(resp.dashboardId != null ? resp.dashboardId : "");
                        setReportId(resp.reportId != null ? resp.reportId : "");
                        setScoreCardFileName(resp.scoreCardFileName);
                        setScoreCardId(resp.scoreCardId);
                        setScoreCardType(resp.scoreCardName);
                        setScoreCardURL(resp.scoreCardURL);
                        setScorecardObj(resp);
                        if (isTabYesNo == "No")
                            setAccessObj(checkUserScorecardAccess(resp.scoreCardId));
                        else
                            setAccessObj(checkUserScorecardAccess(tabParentScorecardId));
                    }
                }
            });
    }, []);

    return (
        <Box >
            {accessObj?.hasAccess != undefined && !accessObj.hasAccess ? (
                <NoAccessPage></NoAccessPage>
            ) : (
                <>
                    <Box sx={{ width: "100%", paddingTop: 5,paddingLeft:4 }}>
                        {
                            (scoreCardType != "" && scoreCardType == 'Excel') ? (
                                <>
                                    <Button
                                        onClick={() => openExcelSheet(scoreCardURL)}
                                        variant="outlined"
                                        sx={{
                                            textTransform: "none",
                                            color: "#111827",
                                            border: "1px solid",
                                            borderColor: COLOR.ORANGE,
                                            textAlign: "center",
                                            leadingTrim: "both",
                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                            fontSize: "12px",
                                            fontStyle: Styles.FONT_STYLE_NORMAL,
                                            fontWeight: "500",
                                            lineHeight: "22px",
                                            "&.MuiButton-root:hover": {
                                                borderColor: COLOR.ORANGE,
                                                border: "1px solid #F37021",
                                            },
                                        }}
                                    >{scoreCardFileName}
                                    </Button>
                                </>
                            ) : (
                                <section id="bi-report">
                                    {
                                        (scoreCardType == "Dashboards") ?
                                            <PowerBiCustomDashboard groupId={groupId} dashboardId={dashboardId} />
                                            :
                                            (scoreCardType == "Reports") ?
                                                <PowerBiCustomReport groupId={groupId} reportId={reportId} />
                                                : <span />
                                    }
                                </section>
                            )
                        }
                    </Box>
                </>
            )}
        </Box>
    )
}

export default ScorecardComponent;