import React, { useEffect, useState, useCallback } from "react";
import { PagesPath } from "../../PagesPath";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import MemberRosterRestatement from "../membershipAndGrowthTrends/MemberRosterRestatement";
import GrowthByProvider from "../membershipAndGrowthTrends/GrowthByProvider";
import AlignmentRosterDetail from "../membershipAndGrowthTrends/AlignmentRosterDetail";
import MemberMonthDetail from "../membershipAndGrowthTrends/MemberMonthDetail";
import MembershipAndGrowthTrendsHeader from "./MembershipAndGrowthTrendsHeader";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import MembershipAndGrowthTrendsTab from "../membershipAndGrowthTrends/MembershipAndGrowthTrendsTab";
import { checkUserScorecardAccess } from "../../component/common/userAuthorization/checkUserAccess";
import ClaimsReductionDetail from "../membershipAndGrowthTrends/ClaimsReductionDetail";
import RestatementReportDetail from "../membershipAndGrowthTrends/RestatementReportDetail";
import SpendSummary from "../membershipAndGrowthTrends/SpendSummary";
import SpendByMember from "../membershipAndGrowthTrends/SpendByMember";
import UtilizationSummary from "./UtilizationSummary";
import UtilizationByMember from "./UtilizationByMember";

const MembershipAndGrowthTrends = () => {
  const MembershipAndGrowthTrendsID = 5;
  const [value, setValue] = React.useState("0");
  const [accessObj] = useState(checkUserScorecardAccess(MembershipAndGrowthTrendsID));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box >
      <MembershipAndGrowthTrendsHeader></MembershipAndGrowthTrendsHeader>
      {!accessObj.hasAccess ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <TabContext value={value}>
          <div>
            <Box>
              <TabList
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#dc4f34",
                    // top:"46px",
                    display: "none"
                  }
                }}
                className={"tab-container"}
                onChange={handleChange}
              >
                <Tab
                  label="Membership And Growth Trends"
                  value="0"
                  className={
                    value === "0"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                  }}
                />
                {/* <Tab
                label="Member Roster Restatement"
                value="1"
                className={
                  value === "1"
                    ? "custom-tab-class Mui-selected"
                    : "custom-tab-class"
                }
                style={{
                  color: "#111827",
                  opacity: "72%",
                  textTransform: "math-auto",
                  fontfamily: "poppins",
                  fontsize: "16px",
                  fontweight: "400",
                }}
              />*/}

                <Tab
                  label="Alignment Roster Detail"
                  value="1"
                  className={
                    value === "1"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                  }}
                />
                <Tab
                  label="Growth By Provider"
                  value="2"
                  className={
                    value === "2"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                  }}
                />

                <Tab
                  label="Member Month Detail"
                  value="3"
                  className={
                    value === "3"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                  }}
                />
                <Tab
                  label="Claims Reduction Detail"
                  value="4"
                  className={
                    value === "4"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                  }}
                />
                <Tab
                  label="Restatement Report"
                  value="5"
                  className={
                    value === "5"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                  }}
                />
                <Tab
                  label="Spend Summary"
                  value="6"
                  className={
                    value === "6"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                  }}
                />
                <Tab
                  label="Spend By Member"
                  value="7"
                  className={
                    value === "7"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                  }}
                />

                <Tab
                  label="Utilization Summary"
                  value="8"
                  className={
                    value === "8"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                  }}
                />
                 <Tab
                  label="Utilization By Member"
                  value="9"
                  className={
                    value === "9"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  style={{
                    color: "#111827",
                    opacity: "72%",
                    textTransform: "math-auto",
                    fontfamily: "poppins",
                    fontsize: "16px",
                    fontweight: "400",
                  }}
                />
              </TabList>
            </Box>
          </div>
          <TabPanel value="0">
            <MembershipAndGrowthTrendsTab />
          </TabPanel>
          {/* 
         */}
          <TabPanel value="1">
            <AlignmentRosterDetail />
          </TabPanel>
          <TabPanel value="2">
            <GrowthByProvider />
          </TabPanel>
          <TabPanel value="3">
            <MemberMonthDetail />
          </TabPanel>
          <TabPanel value="4">
            <ClaimsReductionDetail />
          </TabPanel>
          <TabPanel value="5">
            <RestatementReportDetail />
          </TabPanel>
          <TabPanel value="6">
            <SpendSummary />
          </TabPanel>
          <TabPanel value="7">
            <SpendByMember />
          </TabPanel>
          <TabPanel value="8">
            <UtilizationSummary />
          </TabPanel>
          <TabPanel value="9">
            <UtilizationByMember />
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );
};

export default MembershipAndGrowthTrends;
