import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const CensusToolMarketHospitalAndPracticeERHeader = () => {
  const title = "Daily Census Tool for ER";
  const sevenPillarTitle = "Non- Emergent ER";
  return (
    <>
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle} />
    </>
  );
};

export default CensusToolMarketHospitalAndPracticeERHeader;
