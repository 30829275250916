import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import cinqCareLogo from "../../../assets/cinqCareLogo.png";

const MiniDrawerHeader = ({ handleDrawerClose }) => {
  const navigate = useNavigate();
  return (
    <div style={{ width: "100%", display: "inline", alignItems: "center" }}>
      <div style={{ float: "left" }}>
        <IconButton
          onClick={() => navigate("/")}
          disableFocusRipple={true}
          disableRipple={true}
          type="button"
        >
          <img
            src={cinqCareLogo}
            alt=""
            style={{
              width: "114px",
              float: "left",
              paddingTop: "8px",
            }}
          />
        </IconButton>
      </div>
      <div style={{ float: "right", marginRight: "-20px" }}>
        <IconButton
          onClick={handleDrawerClose}
          disableFocusRipple={true}
          disableRipple={true}
        >
          <ChevronLeftIcon fontSize="large" sx={{ color: "#333333" }} />
        </IconButton>
      </div>
    </div>
  );
};

export default MiniDrawerHeader;
