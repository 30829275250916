import React, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    Link,
    Modal,
    Stack,
    Typography,
    Button,
    TextField,
    FormControl, FormHelperText, Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import agent from "../../../app/api/Agent";
import Loader from "../../../component/common/loader/Loader";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/store";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import CustomPaginationSlot from "../../../component/common/datagrid/CustomPaginationSlot";
import Searchbox from "../../../component/common/searchbox/Searchbox";
import { PagesPath } from "../../../PagesPath";
import { alpha, styled } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
    getAdmissionTrendsDetail, getCohortFilterDetails, getModalFilterDetails
} from "../../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { addDays, addMonths, addWeeks, format, subDays } from "date-fns";
import { COLOR, Styles } from "../../../Styles";
import MemberDetailsModal from "../CreateCohortRegistryManagement/Popup/MemberDetailsModal";
import EventsDetailsModal from "../CreateCohortRegistryManagement/Popup/EventsDetailsModal";
import "../CreateCohortRegistryManagement/CreateCohortManagementcss.css";
import IPEventsDetailsModal from "../CreateCohortRegistryManagement/Popup/IPEventsDetailsModal";
import SChip from "../../../component/common/schip/SChip";
const AuditingPreviewModal = ({ isOpen, onClose, rowData, isDisabledMode }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [memberCostDetails, setMemberCostDetails] = useState({});
    const [memberEventsList, setMemberEventsList] = useState([]);
    const { admissionTrendsDetails } = useSelector(
        (state) => state.filterDetails
    );
    const { modalFilterDetails } = useSelector(
        (state) => state.filterDetails
    );
    const [sortModel, setSortModel] = useState([{ field: 'memberName', sort: 'asc' }])
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // const selectedRows = useSelector(
    //     (state) => state.filterDetails.selectedRows
    // );

    const { cohortDetails, cohortFilterDetails } = useSelector(
        (state) => state.filterDetails
    );

    const [selectedList, setSelectedList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [page, setPage] = useState(1);
    const [searchMemberName, setSearchMemberName] = useState("");
    const [searchMember, setSearchMember] = useState("");

    const [pageCount, setPageCount] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const [popupConfirmationModalOpen, SetPopupConfirmationModalOpen] = useState(false);
    const [popupConfirmationModalData, setPopupConfirmationModalData] = useState({});

    const [totalEvents, setTotalEvents] = useState(0);
    const [totalIPEvents, setTotalIPEvents] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const [payerDtl, setPayerDtl] = useState("");
    const [prodDtl, setProdDtl] = useState("");

    const [podName, setPodName] = useState("");
    const [cohortName, setCohortName] = useState("");
    const [description, setDescription] = useState("");
    const [cohortOwner, setCohortOwner] = useState("");
    const [customStartDate, setCustomStartDate] = useState("");
    const [customEndDate, setCustomEndDate] = useState("");

    const [isImportCohort, setIsImportCohort] = useState(false);
    const [checkedOrange, setCheckedOrange] = React.useState(false);
    const [ipaFlag, setIpaFlag] = useState("");
    const [eventType, setEventType] = useState("");
    const [riskCategory, setRiskCategory] = useState("");
    const [filterStartDate, setFilterStartDate] = useState("");
    const [filterEndDate, setFilterEndDate] = useState("");
    const [customPeriodType, setCustomPeriodType] = useState(1);
    const [eventYear, setEventYear] = useState("");
    const cohortId = useSelector(
        (state) => state.filterDetails.cohortId
    );
    const [isCohortMembersLoading, setIsCohortMembersLoading] = useState(false);

    // useEffect(() => {

    //     if (cohortId != undefined && cohortId != "" && cohortId != 0 && cohortId != null) {

    //         getCohortFilterDetailsAPI().then((resp) => {
    //             if (resp[0] && resp[0]?.cohortId != undefined) {
    //                 console.log("resp[0]",resp[0]);
    //             }
    //         });
    //     }
    // }, [cohortId])

    const getCohortFilterDetailsAPI = () => {
        return axiosRequests.get(`/Cohort/GetCohortFilterDatas?CohortId=${cohortId}`);
    };

    useEffect(() => {
        let obj = [...selectedRows]
        let filtered;
        if (searchMember) {
            filtered = obj.filter(
                (item) =>
                    item.memberName.toLowerCase().includes(searchMember.toLowerCase())
            );
            setSelectedList(filtered);
        } else {
            setSelectedList(selectedRows);
        }
    }, [searchMember])

    useEffect(() => {
        console.log("cohortDetails", cohortDetails);
        if (cohortDetails != undefined
            && cohortDetails.cohortName != undefined
        ) {
            setIsCohortMembersLoading(true);
            setPodName(cohortDetails.podName);
            setCohortName(cohortDetails.cohortName);
            setDescription(cohortDetails.description);
            setCohortOwner(cohortDetails.cohortOwner);
            setCustomStartDate(new Date((format(cohortDetails?.startDate, "MM/dd/yyyy"))));
            // // let date_today = new Date();
            // let fill_date = new Date(cohortDetails.startDate);
            // setCustomStartDate(fill_date);

            setIpaFlag(cohortDetails?.ipaFlag);

            setEventType(cohortDetails?.eventType);

            setRiskCategory(cohortDetails?.riskCategory);

            // setFilterStartDate(resp[0]?.fromDate);
            // setFilterEndDate(resp[0]?.toDate);

            setFilterStartDate(format(cohortDetails?.fromDate, "yyyy-MM-dd"));
            setFilterEndDate(format(cohortDetails?.toDate, "yyyy-MM-dd"));

            setCustomPeriodType(cohortDetails?.dateRange);
            // startDate = cohortDetails?.fromDate;
            // endDate = cohortDetails?.toDate;

            let cusPeriodType = cohortDetails?.dateRange;
            let admissionTrsValue = "";
            if (cusPeriodType == "1") {
                admissionTrsValue = "2024"
                // setEventStartDate(format("2024-01-01", "yyyy-MM-dd"));
                // setEventEndDate(format("2024-12-31", "yyyy-MM-dd"));
            }
            else if (cusPeriodType == "2") {
                admissionTrsValue = "2023"
                // setEventStartDate(format("2023-01-01", "yyyy-MM-dd"));
                // setEventEndDate(format("2023-12-31", "yyyy-MM-dd"));
            }
            else if (cusPeriodType == "3") {
                admissionTrsValue = "0 - 3 Months"
                // setEventStartDate(format("2024-01-01", "yyyy-MM-dd"));
                // setEventEndDate(format("2024-03-31", "yyyy-MM-dd"));
            }
            else if (cusPeriodType == "4") {
                admissionTrsValue = "3 - 6 Months"
                // setEventStartDate(format("2024-03-01", "yyyy-MM-dd"));
                // setEventEndDate(format("2024-06-30", "yyyy-MM-dd"));
            }
            else if (cusPeriodType == "5") {
                admissionTrsValue = "0 - 6 Months"
                // setEventStartDate(format("2024-01-01", "yyyy-MM-dd"));
                // setEventEndDate(format("2024-06-30", "yyyy-MM-dd"));
            }
            else if (cusPeriodType == "6") {
                admissionTrsValue = "6 - 9 Months"
                // setEventStartDate(format("2024-06-01", "yyyy-MM-dd"));
                // setEventEndDate(format("2024-09-30", "yyyy-MM-dd"));
            }
            else if (cusPeriodType == "7") {
                admissionTrsValue = "0 - 9 Months"
                // setEventStartDate(format("2024-01-01", "yyyy-MM-dd"));
                // setEventEndDate(format("2024-09-30", "yyyy-MM-dd"));
            }
            setEventYear(admissionTrsValue);

            let formData = {
                CohortDetailId: cohortDetails?.id ? cohortDetails?.id : 0,
                FromDate: cohortDetails?.fromDate,
                ToDate: cohortDetails?.toDate,
                RiskCategory: cohortDetails?.riskCategory,
                IPAFlag: cohortDetails?.ipaFlag,
                EventType: cohortDetails?.eventType
            }

            agent.SDOHAssessmentTrackingAgent.GetCohortMemberListView(formData
            )
                .then((response) => {
                    if (response && response.length > 0) {
                        setSelectedRows(response);
                        setSelectedList(response);
                        let rowCount = response.length;
                        let numberOfPages = Math.ceil((rowCount / 10));
                        setTotalRows(rowCount);
                        setPageCount(numberOfPages);

                        let tlEvents = 0;
                        let tlIPEvents = 0;
                        let tlCost = 0;
                        response.map((detail) => {
                            tlEvents += detail?.totalEvents;
                            tlIPEvents += detail?.totalIPEvents;
                            tlCost += detail?.totalCost;
                        })
                        setTotalEvents(tlEvents);
                        setTotalIPEvents(tlIPEvents);
                        setTotalCost(tlCost);
                    }
                    else {
                        setSelectedList([]);
                        setTotalRows(0);
                        setPageCount(0);
                    }

                    setIsCohortMembersLoading(false);
                })
                .catch((err) => {
                    console.log("exc-getStatusDetailsData", err);
                    setIsCohortMembersLoading(false);
                });

            setPayerDtl(cohortDetails.payers);
            setProdDtl(cohortDetails.products);
        }
    }, [cohortDetails])

    // useEffect(() => {
    //     if (cohortFilterDetails && cohortFilterDetails?.payer != undefined) {
    //         var payIDS;
    //         var payIDS = cohortFilterDetails?.payer.map((item) => item.payerName);
    //         var payNames = payIDS.join(",");
    //         setPayerDtl(payNames);

    //         // var prodIDS;
    //         // var prodIDS = cohortFilterDetails?.product.map((item) => item.productName);
    //         // var prodNames = prodIDS.join(",");
    //         // setProdDtl(prodNames);
    //     }

    // }, [cohortFilterDetails])

    // useEffect(()=>{
    // },[memberCostDetails])
    // useEffect(() => {
    //     setCustomStartDate(admissionTrendsDetails?.startDate);
    //     setCustomEndDate(admissionTrendsDetails?.endDate);
    // }, [admissionTrendsDetails])

    useEffect(() => {
        // if (isOpen === true) getEventDetailsData();
        if (isOpen === true) {

        }
    }, [isOpen])

    const handleReject = (e) => {

        const formData = {
            cohortId: cohortId,
            AuditStatus: "Rejected",
        };
        axiosRequests.put("/Cohort/UpdateCohortAuditStatusDetails", formData).then((resp) => {
            if (resp === 1) {
                onClose();
                window.location.reload();
            }
        });
    }

    const handleApprove = (e) => {

        const formData = {
            cohortId: cohortId,
            AuditStatus: "Approved",
        };
        axiosRequests.put("/Cohort/UpdateCohortAuditStatusDetails", formData).then((resp) => {
            if (resp === 1) {
                onClose();
                window.location.reload();
            }
        });

    }

    const openMemberDetailsModal = (memberDtl) => {
        setPopupConfirmationModalData(memberDtl);
        dispatch(getAdmissionTrendsDetail({
            startDate: format(filterStartDate, "yyyy-MM-dd"),
            endDate: format(filterEndDate, "yyyy-MM-dd")
        }));
        dispatch(getModalFilterDetails({
            ipaFlags: ipaFlag,
            riskCats: riskCategory,
            evtTy: eventType
        }));
        SetPopupConfirmationModalOpen(true);
    }

    const closePopupModal = () => {
        SetPopupConfirmationModalOpen(false);
    }

    const [viewEventsPopupModalOpen, SetViewEventsPopupModalOpen] = useState(false);
    const viewEventsDetails = (memberDtl) => {
        dispatch(getAdmissionTrendsDetail({
            startDate: format(filterStartDate, "yyyy-MM-dd"),
            endDate: format(filterEndDate, "yyyy-MM-dd")
        }));
        dispatch(getModalFilterDetails({
            ipaFlags: ipaFlag,
            riskCats: riskCategory,
            evtTy: eventType
        }));
        setPopupConfirmationModalData(memberDtl);
        SetViewEventsPopupModalOpen(true);
    }

    const [ipPopupConfirmationModalOpen, SetIpPopupConfirmationModalOpen] = useState(false);
    const [ipPopupConfirmationModalData, setIpPopupConfirmationModalData] = useState({});

    const [viewIpEventsPopupModalOpen, SetViewIpEventsPopupModalOpen] = useState(false);

    const viewIPEventsDetails = (memberDtl) => {
        dispatch(getAdmissionTrendsDetail({
            startDate: format(filterStartDate, "yyyy-MM-dd"),
            endDate: format(filterEndDate, "yyyy-MM-dd")
        }));
        dispatch(getModalFilterDetails({
            ipaFlags: ipaFlag,
            riskCats: riskCategory,
            evtTy: eventType
        }));
        setIpPopupConfirmationModalData(memberDtl);
        SetViewIpEventsPopupModalOpen(true);
    }

    const closeIpEventsPopupModal = () => {
        SetViewIpEventsPopupModalOpen(false);
    }

    const closeEventsPopupModal = () => {
        SetViewEventsPopupModalOpen(false);
    }

    function formatToCostPrice(number) {
        // Convert number to string
        if (number != undefined) {
            const numberStr = number.toString();

            // Match digits and decimal point using regex
            const matches = numberStr.match(/^(\d+)(?:\.(\d+))?$/);

            if (matches) {
                // Get the integer part and fractional part (if exists)
                const integerPart = (+matches[1]).toLocaleString();
                const fractionalPart = matches[2] ? matches[2].padEnd(2, '0') : '00';

                // Format the number in account balance format -- ₹
                const formattedBalance = `$ ${integerPart}`; //.${fractionalPart}
                return formattedBalance;
            } else {
                return "Invalid input";
            }
        }

    }

    const columns = [
        {
            field: "memberName",
            headerName: "Name",
            flex: 1.6,
            sortable: false,
        },
        {
            field: "insuranceId",
            headerName: "Insurance ID",
            headerClassName: "classMultilineHeader",
            flex: 1.6,
            sortable: false,
        },
        {
            field: "region",
            headerName: "Region",
            flex: 1.6,
            sortable: false,
        },
        {
            field: "payer",
            headerName: "Payer",
            flex: 1.6,
            sortable: false,
        },
        {
            field: "practiceName",
            headerName: "Practice",
            flex: 1.6,
            sortable: false,
        },
        {
            field: "memberDateOfBirth",
            headerName: "DOB",
            type: "Date",
            flex: 1.6,
            sortable: false,
            renderCell: (params) =>
                params.row.memberDateOfBirth &&
                format(new Date(params.row.memberDateOfBirth), "dd MMM yyyy"),
        },
        {
            field: "chronicCondition",
            headerName: "Chronic Condition",
            headerClassName: "classMultilineHeader",
            type: "number",
            sortable: false,
            flex: 1.4,
        },
        {
            field: "totalEvents",
            headerName: "Total Events",
            headerClassName: "classMultilineHeader",
            type: "number",
            sortable: false,
            flex: 1.2,
            renderCell: (params) => {
                return (
                    //   <div style={{display:"flex", alignItems:"center"}}>
                    <Link
                        style={{
                            textDecoration: "none",
                            color: "inherit",
                        }}
                    >
                        <div
                            onClick={() =>
                                viewEventsDetails(params.row)
                            }
                        >
                            <u>{params.row.totalEvents}</u>
                        </div>
                    </Link>
                    //   </div>
                );
            },
        },
        {
            field: "totalIPEvents",
            headerName: "Total IP Events",
            headerClassName: "classMultilineHeader",
            type: "number",
            flex: 1.2,
            renderCell: (params) => {
                if (params.row.totalIPEvents != 0)
                    return (
                        //   <div style={{display:"flex", alignItems:"center"}}>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                        >
                            <div
                                onClick={() =>
                                    viewIPEventsDetails(params.row)
                                }
                            >
                                <u>{params.row.totalIPEvents}</u>
                            </div>
                        </Link>
                        //   </div>
                    );

            },
        },
        {
            field: "totalCost",
            type: "number",
            headerName: "Total Cost",
            headerClassName: "classMultilineHeader",
            flex: 1.8,
            renderCell: (params) =>
                params.row.totalCost &&
                formatToCostPrice(params.row.totalCost),
        },

        {
            field: "action",
            headerName: "Action",
            flex: 1.2,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div style={{ display: "flex", alignItems: "center", marginTop: "7px", marginLeft: "10px" }}>
                        <Tooltip title="Show Member Details">
                            <div
                                // onClick={() => {
                                // handleNewSDOHAssessmentClick(
                                //     params.row.ourId,
                                //     params.row.sdohSubmittedDate,
                                //     params.row.memberRiskScore,
                                //     params.row.practiceName,
                                //     params.row.providerName
                                // );
                                // setIsNewAssessment(false);
                                // }}
                                onClick={() => openMemberDetailsModal(params.row)}
                            >
                                <VisibilityOutlinedIcon />
                            </div>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    function getRowId(row) {
        return row.ourId + "_" + row.memberDateOfBirth;
    }

    if (!rowData) return;

    const OutlinedButton = styled(Button)(() => ({
        borderRadius: "4px",
        padding: "6px",
        justifyContent: "center",
        color: COLOR.BLACK,
        borderColor: COLOR.BLACK,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "capitalize",
    }));

    const ContainedButton = styled(Button)(() => ({
        variant: "contained",
        borderRadius: "4px",
        padding: "6px",
        background: COLOR.PRIMARY_ORANGE,
        justifyContent: "center",
        color: COLOR.WHITE,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "none",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
        ":hover": {
            background: COLOR.SECONDARY_ORANGE,
        },
    }));

    const DefaultTypography = styled(Typography)({
        color: "#000",
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    });

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            slotProps={{ backdrop: { onClick: null } }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%,-40%)",
                    width: "70rem",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    paddingTop: 4,
                    paddingLeft: 4,
                    paddingRight: 4,
                    // p: 4,
                    pt: 1,
                    // height: "75vh",
                    // overflow: "auto"
                }}
            >
                <h3
                    style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
                >
                    Cohort Details
                    <span>
                        <CloseIcon onClick={onClose} style={{ float: "right" }} />
                    </span>
                </h3>
                <hr
                    style={{
                        width: "auto",
                        opacity: "0.3",
                        marginTop: "-11px",
                        marginBottom: "5px",
                    }}
                />



                <Box sx={{
                    paddingTop: 2, width: "100%",
                    display: "flex",
                    height: "76vh", overflowY: "auto"
                }}>
                    <Stack direction="column" spacing={2} sx={{
                        width: "100%",
                        paddingRight: "25px",
                    }}>
                        {/* <Grid container sx={{
                                        paddingTop: 2,
                                        // minHeight: "100%",
                                        // marginLeft: "1%",
                                        // boxShadow: "0px 3px 46px 0px rgba(0, 0, 0, 0.12)",
                                        border: "1px solid rgba(0,0,0,.12)",
                                        backgroundColor: "white",
                                    }} > */}
                        {
                            isCohortMembersLoading ? (
                                <Loader isLoading={isCohortMembersLoading} />
                            ) : (
                                <>
                                    <Grid item xs={12} >
                                        <Stack direction="column" spacing={1} sx={{
                                            // paddingLeft: "25px",
                                            paddingRight: "25px",
                                        }}
                                        >
                                            <Grid container >
                                                <Grid item xs={7} >
                                                    <Stack direction="column" spacing={1} sx={{ paddingRight: "25px" }}
                                                    >
                                                        <TextField
                                                            style={{
                                                                width: "auto !important",
                                                            }}
                                                            className="disabledAccordian"
                                                            id="outlined-multiline-static"
                                                            label="POD Name"
                                                            // value={cohortDetails?.podName}
                                                            value={podName}
                                                            // onChange={(e) => handlePodNameChange(e)}
                                                            multiline
                                                            // className="denyComments"
                                                            color="orangeTheme"
                                                            rows={1}
                                                            variant="outlined"
                                                        />
                                                        <TextField
                                                            style={{
                                                                width: "auto !important",
                                                            }}

                                                            id="outlined-multiline-static"
                                                            label="Cohort Name"
                                                            value={cohortName}
                                                            multiline
                                                            // className="denyComments"
                                                            className="disabledAccordian"
                                                            color="orangeTheme"
                                                            rows={1}
                                                            variant="outlined"
                                                        />
                                                        <TextField
                                                            style={{
                                                                width: "auto !important",
                                                            }}
                                                            id="outlined-multiline-static"
                                                            label="Description"
                                                            value={description}
                                                            className="disabledAccordian"
                                                            multiline
                                                            // className="denyComments"
                                                            color="orangeTheme"
                                                            rows={1}
                                                            variant="outlined"
                                                        />

                                                        <TextField
                                                            style={{
                                                                width: "auto !important",
                                                            }}
                                                            id="outlined-multiline-static"
                                                            label="Cohort Owner"
                                                            value={cohortOwner}
                                                            multiline
                                                            // className="denyComments"
                                                            className="disabledAccordian"
                                                            color="orangeTheme"
                                                            rows={1}
                                                            variant="outlined"
                                                        />

                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <FormControl
                                                                // className="practiceNameClass"
                                                                className="disabledAccordian"
                                                                color="orangeTheme"
                                                                sx={{
                                                                    maxWidth: "180px",
                                                                    minWidth: "160px",
                                                                    backgroundColor: "#fff",
                                                                    borderRadius: "6px",
                                                                }}
                                                                size="small"
                                                            >
                                                                <DatePicker
                                                                    label="Start Date"
                                                                    value={customStartDate}
                                                                    color="orangeTheme"
                                                                    slotProps={{
                                                                        textField: {
                                                                            className: "dateFieldClass",
                                                                            color: "orangeTheme",
                                                                        },
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </LocalizationProvider>
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                        </Stack>
                                    </Grid>

                                    {/* </Grid> */}

                                    <Grid container >
                                        <Grid item xs={12} >
                                            <Stack direction="column" spacing={1} sx={{
                                                border: "1px solid #FFDFC3",
                                                backgroundColor: "white",
                                                padding: "15px",
                                                // width: "95%",
                                            }}>
                                                <h3
                                                    style={{
                                                        fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px",
                                                        color: "#DC4F34"
                                                    }}
                                                >
                                                    Cohort Summary
                                                </h3>

                                                <Typography style={{
                                                    fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                                    // color: "#DC4F34"
                                                }}>
                                                    Total Events : &nbsp;
                                                    {totalEvents}
                                                    {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                                </Typography>

                                                <Typography style={{
                                                    fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                                    // color: "#DC4F34"
                                                }}>
                                                    Total IP Days : &nbsp;
                                                    {totalIPEvents}
                                                    {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                                </Typography>

                                                <Typography style={{
                                                    fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                                }}>
                                                    Total Cost : &nbsp;
                                                    {formatToCostPrice(totalCost)}
                                                    {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                                </Typography>
                                            </Stack>
                                        </Grid>

                                    </Grid>

                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Stack direction="column" spacing={1} sx={{
                                                // border: "1px solid #FFDFC3",
                                                // backgroundColor: "white",
                                                padding: "15px",
                                                width: "95%",
                                            }}>
                                                <h3
                                                    style={{
                                                        fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px",
                                                    }}
                                                >
                                                    Filters
                                                </h3>

                                                <Typography style={{
                                                    fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                                }}>
                                                    Event Year : &nbsp;
                                                    {eventYear},

                                                    {/* {totalEvents} */}
                                                    {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                                </Typography>

                                                <Typography style={{
                                                    fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                                }}>
                                                    Payer : &nbsp;
                                                    {payerDtl}
                                                    {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                                </Typography>

                                                <Typography style={{
                                                    fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                                }}>
                                                    Product : &nbsp;
                                                    {prodDtl}
                                                    {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                                </Typography>

                                                <h3
                                                    style={{
                                                        fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px",
                                                    }}
                                                >
                                                    Date Range
                                                </h3>
                                                <Stack direction="row" spacing={1} sx={{
                                                    // border: "1px solid #FFDFC3",
                                                    // backgroundColor: "white",
                                                    width: "95%",
                                                }}>
                                                    <Typography style={{
                                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                                    }}>
                                                        From Date : &nbsp;
                                                        {filterStartDate}
                                                    </Typography>

                                                    <Typography style={{
                                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                                    }}>
                                                        To Date : &nbsp;
                                                        {filterEndDate}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Stack direction="column" spacing={2}>
                                                <div style={{ display: "grid" }}>
                                                    <>
                                                        <DataGrid
                                                            //   hideFooter={hideFooter}
                                                            sx={{
                                                                marginTop: 2,
                                                                "& .MuiDataGrid-columnHeader": {
                                                                    backgroundColor: "#F2F2F2",
                                                                    color: "#11182799",
                                                                },
                                                                "& .MuiDataGrid-overlayWrapper": {
                                                                    height: "34px !important",
                                                                },
                                                                "& .MuiDataGrid-virtualScroller": {
                                                                    overflow: "visible !important"
                                                                },
                                                                "& .MuiDataGrid-selectedRowCount": {
                                                                    display: "none"
                                                                },
                                                                "& .MuiDataGrid-footerContainer": {
                                                                    display: "none"
                                                                }
                                                            }}
                                                            rows={selectedList}
                                                            className="cohortGrid"
                                                            columns={columns}
                                                            // loading={isStatusLoading}
                                                            initialState={{
                                                                pagination: {
                                                                    setPage: setPage,
                                                                    paginationModel: { count: pageCount, page: page, totalRows: totalRows },
                                                                    colorTheme: 'orangeTheme',
                                                                    className: 'pageNumber'
                                                                }
                                                            }}
                                                            pagination
                                                            // apiRef={apiRef}
                                                            sortModel={sortModel}
                                                            pageSizeOptions={[10]}
                                                            // onCellClick={handleCellClick}

                                                            getRowId={getRowId}
                                                            sortingOrder={['asc', 'desc']}
                                                            sortingMode="server"
                                                              disableColumnMenu={true}
                                                            // onSortModelChange={(e) => handleSortModelChange(e)}
                                                            //   onPageChange={onPageChange}
                                                            slots={{
                                                                pagination: CustomPaginationSlot,
                                                                toolbar: DataGridHeader,
                                                            }}
                                                            slotProps={{
                                                                toolbar: {
                                                                    headerText: "Member Details",
                                                                    fontSize: "16px",
                                                                    marginTop: "auto",
                                                                    marginBottom: "auto",
                                                                    height: "56px",
                                                                    children: (
                                                                        <>
                                                                            <SChip label={selectedList.length}></SChip>
                                                                            <div
                                                                                style={{
                                                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                                    fontSize: "16px",
                                                                                    marginTop: "auto",
                                                                                    marginBottom: "auto",
                                                                                    marginLeft: "auto",
                                                                                }}
                                                                            >
                                                                                <Searchbox
                                                                                    defaultValue={searchMemberName}
                                                                                    handleChange={(e) => {
                                                                                        setSearchMemberName(e.target.value);
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setPage(1);
                                                                                        setSearchMember(searchMemberName);
                                                                                    }}
                                                                                    label="Search Member"
                                                                                ></Searchbox>
                                                                            </div>
                                                                        </>
                                                                    ),
                                                                },
                                                                pagination: {
                                                                    setPage: setPage,
                                                                    paginationModel: { count: pageCount, page: page, totalRows: totalRows },
                                                                    colorTheme: 'orangeTheme',
                                                                    className: 'pageNumber'
                                                                }
                                                            }}
                                                            isRowSelectable={false}
                                                        />
                                                    </>
                                                </div>

                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                    </Stack>
                    <MemberDetailsModal
                        isOpen={popupConfirmationModalOpen}
                        onClose={closePopupModal}
                        // handleAddToCohort={() => {
                        //     handleAddToCohort(popupConfirmationModalData.id)
                        // }}
                        rowData={popupConfirmationModalData}
                        Preview={0}
                    ></MemberDetailsModal>

                    <EventsDetailsModal
                        isOpen={viewEventsPopupModalOpen}
                        onClose={closeEventsPopupModal}
                        rowData={popupConfirmationModalData}
                    ></EventsDetailsModal>

                    <IPEventsDetailsModal
                        isOpen={viewIpEventsPopupModalOpen}
                        onClose={closeIpEventsPopupModal}
                        rowData={ipPopupConfirmationModalData}
                    ></IPEventsDetailsModal>
                </Box>

                <Grid container spacing={1} sx={{ paddingTop: "15px" }}>
                    <Grid item xs={12} sx={{ margintTop: "25px", display: "flex", justifyContent: "flex-end" }} >
                        {/* <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={onClose}>Cancel</Button>
                                                <Button style={{ color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="contained" color="orangeTheme" disableElevation onClick={handleNext}>Next</Button> */}
                        {/* <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={handleBack}>Back</Button> */}

                        <Stack direction="row" spacing={2} sx={{
                            justifyContent: "end", paddingTop: "7px",
                            paddingBottom: "7px", paddingRight: "10px"
                        }}>
                            <OutlinedButton
                                onClick={() => {
                                    onClose()
                                }}
                                variant="text"
                                size="small"
                            >
                                <DefaultTypography>Cancel</DefaultTypography>
                            </OutlinedButton>

                            <Button style={{
                                variant: "outlined",
                                borderColor: COLOR.ORANGE,
                                borderRadius: "4px",
                                padding: "6px",
                                justifyContent: "center",
                                border: "1px solid #DC4F34",
                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                fontStyle: "normal",
                                textTransform: "none",
                                boxShadow:
                                    "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                                ":hover": {
                                },
                            }} onClick={handleReject}><DefaultTypography style={{ color: COLOR.ORANGE, }}>Reject</DefaultTypography></Button>

                            {/* <Button style={{
                                                    color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                }}
                                                    variant="contained" color="orangeTheme" disableElevation onClick={handleSave}>
                                                    <DefaultTypography style={{ color: COLOR.ORANGE, }}></DefaultTypography></Button> */}

                            <ContainedButton
                                onClick={handleApprove}
                                variant="contained"
                                size="small"
                                color="orangeTheme"

                            >
                                <DefaultTypography style={{ color: "#fff" }}>
                                    Approve
                                </DefaultTypography>

                            </ContainedButton>
                        </Stack>
                    </Grid>
                </Grid>

            </Box>
        </Modal>
    );
};

export default AuditingPreviewModal;

