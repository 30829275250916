import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/Agent";

const initialState = {
  isLoading: false,
  memberDetails: {},
  errorMessage: "",
};

export const getMemberDetailsByOurId = createAsyncThunk(
  "getMemberDetailsByOurId",
  async (ourId) => {
    const response =
      await agent.DerosteringTrackingAgent.getMemberDetailsByOurId(ourId);
    return response;
  }
);

const memberDetailsSlice = createSlice({
  name: "getMemberDetailsByOurId",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMemberDetailsByOurId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMemberDetailsByOurId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.memberDetails = action.payload;
      })
      .addCase(getMemberDetailsByOurId.rejected, (state, action) => {
        state.isLoading = false;
        state.memberDetails = {};
        state.errorMessage = action.error.message;
      });
  },
});

export default memberDetailsSlice.reducer;
