import { Box, Stack, Grid, Typography, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { COLOR, Styles } from "../../../Styles";
import CreateCohortRegistryHeader from "../CreateCohortRegistryManagement/CreateCohortRegistryHeader";
import back_arrow from "../../../assets/back_arrow.svg";
import { PagesPath } from "../../../PagesPath";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import Loader from "../../../component/common/loader/Loader";
import CustomPaginationSlot from "../../../component/common/datagrid/CustomPaginationSlot";
import Searchbox from "../../../component/common/searchbox/Searchbox";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import agent from "../../../app/api/Agent";
import { useAppDispatch } from "../../../app/store";
import { addDays, addMonths, addWeeks, format, subDays } from "date-fns";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SChip from "../../../component/common/schip/SChip";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AuditDocumentsOutlined from "../../../assets/AuditDocuments.svg";
import DeletePopupModal from "../Models/DeletePopupModal";
import ViewCohortDetails from "./ViewCohortDetails";
import {
    getCohortId, getAdmissionTrendsDetail, getModalFilterDetails,
    getCohortDetails
} from "../../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
import AuditingPreviewModal from "./AuditingPreviewModal";

const ViewCohortRegistry = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isCohortLoading, setIsCohortLoading] = useState(false);
    const [cohortList, setCohortList] = useState([]);
    const [sortModel, setSortModel] = useState([{ field: 'lastUpdatedOn', sort: 'desc' }])
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [isNoDataLoad, setIsNoDataLoad] = useState(false);
    const [searchMemberName, setSearchMemberName] = useState("");
    const [searchMember, setSearchMember] = useState("");
    const [totalRows, setTotalRows] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            viewCohortList(1, "ID", "desc", "");
            setIsNoDataLoad(true);
        }, 500)
    }, [])

    const viewCohortList = (pageVal, sortColumn, sortOrder, searchVal) => {
        setIsCohortLoading(true);
        let formData = {
            sortColumn: sortColumn != '' ? sortColumn : 'totalCost',//,
            sortOrder: sortOrder != '' ? sortOrder : 'desc',//sortOrder,
            pageNumber: pageVal != undefined ? pageVal : 1,
            rowsPerPage: 10,//rowsPerPage,
            PatientName: searchVal,//search
            EmailId: localStorage.getItem("emailAddress")///"saravanakumar.k@cinq.care"
        }

        agent.SDOHAssessmentTrackingAgent.getCohortList(formData
        )
            .then((response) => {

                setIsCohortLoading(false);

                if (response?.cohortDetails && response?.cohortDetails.length > 0) {
                    setCohortList(response?.cohortDetails);
                }
                else {
                    setCohortList([]);
                }

                if (response && response?.count != undefined) {
                    let rowCount = response?.count;

                    let numberOfPages = Math.ceil((rowCount / 10));
                    setTotalRows(rowCount);
                    setPageCount(numberOfPages);
                }

            })
            .catch((err) => {
                console.log("exc-getStatusDetailsData", err);
                setIsCohortLoading(false);
            });
    }

    const backToHome = () => {
        var idUrl = PagesPath.CohortRegistryManagement_URL;
        navigate(idUrl);
    };

    const handleSortModelChange = (sortModel) => {
        let sort = null;
        if (sortModel.length > 0) {
            const { field, sort: sortOrder } = sortModel[0];
            sort = `${field}:${sortOrder}`;
            if (sortOrder === 'asc') {
                sort = `${field}:desc`;
            }
            setSortModel(sortModel);
            setPage(1);
            viewCohortList(1, sortModel[0]?.field, sortModel[0]?.sort, "");
        }
    }

    useEffect(() => {
        // dispatch(getpageResetDetail(false));
        viewCohortList(page, sortModel[0]?.field, sortModel[0]?.sort, "");
    }, [page])

    useEffect(() => {
        setPage(1)
        // dispatch(getsearchResetDetail(false));
        viewCohortList(1, sortModel[0]?.field, sortModel[0]?.sort, searchMember);
    }, [searchMember])

    function getRowId(row) {
        return row.id;
    }
    const [popupConfirmationModalData, setPopupConfirmationModalData] = useState({});
    const [message, setMessage] = useState("");
    const [information, setInformation] = useState("");
    const [deletePopupModalOpen, SetDeletePopupModalOpen] = useState(false);
    const [auditingPreviewPopupModalOpen, SetAuditingPreviewPopupModalOpen] = useState(false);
    const [popupAuditingPreviewModalData, setPopupAuditingPreviewModalData] = useState({});
    const [memberName, setMemberName] = useState("");
    const handleOpenDeletePopup = (memberDtl) => {
        if (memberDtl?.cohortName != undefined) {
            setMessage(`Are you sure you want to delete "${memberDtl?.cohortName}" ?`);
            setInformation(`Delete Cohort`);
            setMemberName(memberDtl?.cohortName);
            setPopupConfirmationModalData(memberDtl);
            SetDeletePopupModalOpen(true);
        }
    }

    function closeAuditingPreviewPopupModal() {
        SetAuditingPreviewPopupModalOpen(false);
    }

    const handleOpenAuditingPreviewModalPopup = (memberDtl) => {
        dispatch(getCohortId(memberDtl.id));
        dispatch(getCohortDetails(memberDtl));
        setPopupAuditingPreviewModalData(memberDtl);
        SetAuditingPreviewPopupModalOpen(true);
    }
    const handleApproveCohort = (memberDtl) => {
    }
    const handleCohortEdit = (memberDtl) => {
        console.log(memberDtl);
        if(memberDtl.isImport) {
            navigate(PagesPath.CreateCohortByImport_URL, {
                state: { cohortId: memberDtl.id, 
                        startDate: memberDtl.fromDate, endDate: memberDtl.toDate },
              });
        }
        else {
        dispatch(getAdmissionTrendsDetail({
            startDate: format(memberDtl?.fromDate, "yyyy-MM-dd"),
            endDate: format(memberDtl?.toDate, "yyyy-MM-dd")
        }));
        dispatch(getCohortId(memberDtl.id));
        var editCohortUrl = PagesPath.EditCohortRegistryManagement_URL;
        navigate(editCohortUrl);
    }
    }
    const handleDeleteCall = () => {
        SetDeletePopupModalOpen(false);
        viewCohortList(1, "ID", "desc", "");
        // const formData = {
        //     id: -1,
        //     OurId: popupConfirmationModalData?.ourId,
        //     DeclineMode: "Offline",
        //     DeclineComments: "",
        //     formStatus: formStatus
        // };

        // axiosRequests.post(`/SDOH/DeclineSDOHMember`, formData).then((resp) => {
        //     setTimeout(() => {
        //         window.location.reload();
        //     }, 200)
        // })
    }

    function closePopupModal() {
        SetDeletePopupModalOpen(false);
    }

    const [showDetailsPage, setShowDetailsPage] = useState(false);
    const [cohortId, setCohortId] = useState("");
    const viewCohortDetails = (rowData) => {
        setCohortId(rowData.id);
        setPopupConfirmationModalData(rowData);
        dispatch(getModalFilterDetails({
            ipaFlags:rowData?.ipaFlag,
            riskCats:rowData?.riskCategory,
            evtTy:rowData?.eventType
        }));
        dispatch(getAdmissionTrendsDetail({
            startDate: format(rowData?.fromDate, "yyyy-MM-dd"),
            endDate: format(rowData?.toDate, "yyyy-MM-dd")
        }));
        // setMemberOurId(memberId);
        // setMemberPayerName(payerName);
        setShowDetailsPage(true);
        // setPriority(priority);
    };

    const columns = [
        {
            field: "cohortName",
            headerName: "Cohort Name",
            flex: 1.6,
            renderCell: (params) => {
                return (
                    <>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                        >
                            <div
                                onClick={() =>
                                    viewCohortDetails(params.row)
                                }
                            >
                                <u>{params.row.cohortName}</u>
                            </div>
                        </Link>
                    </>
                );
            },
        },
        {
            field: "podName",
            headerName: "POD Name",
            headerClassName: "classMultilineHeader",
            flex: 1.6,
        },
        {
            field: "payers",
            headerName: "Payer",
            flex: 1.6,
        },
        {
            field: "cohortOwner",
            headerName: "Cohort Owner",
            flex: 1.6,
        },
        {
            field: "dateRange",
            headerName: "Date Range",
            flex: 1.6,
            renderCell: (params) =>
                params.row.fromDate && params.row.toDate &&
                format(new Date(params.row.fromDate), "dd MMM yyyy") + " - " +  
                format(new Date(params.row.toDate), "dd MMM yyyy"),
        },
        {
            field: "status",
            headerName: "Status",
            headerClassName: "classMultilineHeader",
            flex: 1.4,
        },
        {
            field: "lastUpdatedOn",
            headerName: "Last Updated",
            headerClassName: "classMultilineHeader",
            flex: 1.6,
            renderCell: (params) =>
                params.row.lastUpdatedOn &&
                format(new Date(params.row.lastUpdatedOn), "dd MMM yyyy"),
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1.6,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Stack direction="row" style={{
                        display: "flex", alignItems: "center",
                        marginTop: "7px", marginLeft: "10px"
                    }} spacing={2}>

                        <Tooltip title="Approve Cohort"
                        style={{
                        display:
                        (params.row.isActionRequired === 0) ? 'none' : 'block'}}
                        >
                            <div
                                onClick={() => {
                                    handleOpenAuditingPreviewModalPopup(
                                        params.row
                                    );
                                }}
                            >
                                {/* <CheckOutlinedIcon color="blackTheme" /> */}
                                <img
                                    src={AuditDocumentsOutlined}
                                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                                />
                            </div>
                        </Tooltip>

                        <Tooltip
                            onClick={() =>
                                handleCohortEdit(
                                    params.row
                                )
                            }
                            title={"Update Cohort"}
                            style={{ cursor: "pointer" ,
                            display:
                            (params.row.isEdit === 0) ? 'none' : 'block'
                            }}
                        >
                            <EditOutlinedIcon color="blackTheme" />
                        </Tooltip>

                        <Tooltip
                            onClick={() =>
                                handleOpenDeletePopup(
                                    params.row
                                )
                            }
                            title={"Delete Cohort"}
                            style={{ cursor: "pointer",
                                display:
                                (params.row.isEdit === 0) ? 'none' : 'block' }}
                        >
                            <DeleteOutlineOutlinedIcon color="blackTheme" />
                        </Tooltip>
                    </Stack>
                );
            },
        },
    ];

    return (
        <Box >
            <CreateCohortRegistryHeader />
            {!showDetailsPage ? (
                !auditingPreviewPopupModalOpen ? (

                    <>

                        <Grid item xs={6} md={6} justifyContent="flex-end">
                            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                                <Link
                                    onClick={backToHome}
                                    key="ViewCohort"
                                    style={{
                                        textDecoration: "none",
                                        "&:hover": {
                                            color: COLOR.ORANGE,
                                        },
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        sx={{
                                            alignContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <img src={back_arrow} alt="" />
                                        <Typography
                                            sx={{
                                                textAlign: "left",
                                                letterSpacing: "1px",
                                                color: "#000",
                                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                            }}
                                        >
                                            View Cohort List
                                        </Typography>
                                    </Stack>
                                </Link>
                            </Stack>

                        </Grid>
                        {
                            isCohortLoading ? (
                                <Loader isLoading={isCohortLoading} />
                            ) : (
                                <>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Stack direction="column" spacing={2}>
                                                <div style={{ display: "grid" }}>
                                                    <>
                                                        {
                                                            (isNoDataLoad ? (
                                                                <DataGridCustom
                                                                    sortingOrder={['asc', 'desc']}
                                                                    columns={columns}
                                                                    sortingMode="server"
                                                                    rows={cohortList}
                                                                    sortModel={sortModel}
                                                                    getRowId={getRowId}
                                                                    loading={isCohortLoading}
                                                                    onSortModelChange={(e) => handleSortModelChange(e)}
                                                                    slots={{
                                                                        pagination: CustomPaginationSlot,
                                                                        toolbar: DataGridHeader,
                                                                    }}
                                                                    disableColumnMenu={true}
                                                                    slotProps={{
                                                                        toolbar: {
                                                                            headerText: "Total Cohort",
                                                                            fontSize: "16px",
                                                                            marginTop: "auto",
                                                                            marginBottom: "auto",
                                                                            height: "56px",
                                                                            children: (
                                                                                <>
                                                                                    <SChip label={totalRows}></SChip>
                                                                                    <div
                                                                                        style={{
                                                                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                                            fontSize: "16px",
                                                                                            marginTop: "auto",
                                                                                            marginBottom: "auto",
                                                                                            marginLeft: "auto",
                                                                                        }}
                                                                                    >
                                                                                        <Searchbox
                                                                                            defaultValue={searchMemberName}
                                                                                            handleChange={(e) => {
                                                                                                setSearchMemberName(e.target.value);
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                setPage(1);
                                                                                                setSearchMember(searchMemberName);
                                                                                            }}
                                                                                            label="Search Cohort Name"
                                                                                        ></Searchbox>
                                                                                    </div>
                                                                                </>
                                                                            ),
                                                                        },
                                                                        pagination: {
                                                                            setPage: setPage,
                                                                            paginationModel: { count: pageCount, page: page, totalRows: totalRows },
                                                                            colorTheme: 'orangeTheme',
                                                                            className: 'pageNumber'
                                                                        }
                                                                    }}
                                                                />

                                                            ) : ""
                                                            )
                                                        }
                                                    </>
                                                </div>

                                            </Stack>

                                            <DeletePopupModal
                                                isOpen={deletePopupModalOpen}
                                                onClose={closePopupModal}
                                                message={message}
                                                rowData={popupConfirmationModalData}
                                                memberName={memberName}
                                                information={information}
                                                handleDelete={handleDeleteCall}
                                            ></DeletePopupModal>



                                        </Grid>
                                    </Grid>

                                </>
                            )
                        }
                    </>

                ) : (
                    <AuditingPreviewModal
                        isOpen={auditingPreviewPopupModalOpen}
                        onClose={closeAuditingPreviewPopupModal}
                        rowData={popupAuditingPreviewModalData}
                    ></AuditingPreviewModal>
                )
            ) : (
                <ViewCohortDetails
                    backAction={() => setShowDetailsPage(false)}
                    cohortId={cohortId}
                    rowData={popupConfirmationModalData}
                // submittedDate={sdohSubmittedDate}
                // memberRiskScore={memberRiskScore}
                // practiceName={practiceName}
                // providerName={providerName}
                />
            )
            }
        </Box>


    );
};

export default ViewCohortRegistry;
