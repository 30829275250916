import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import dailyCensusImage from "../../assets/DailyCensus_LandingScreenDailyCensus.svg";
import FDCByDateAndTime from "../../assets/FDCByDateAndTime_LandingScreenDailyCensus.svg";
import FDCByDay from "../../assets/FDCByDay_LandingScreenDailyCensus.svg";
import FDCByMember from "../../assets/FDCByMember_LandingScreenDailyCensus.svg";
import FDCByPostpartum from "../../assets/FDCByPostPatrum_LandingScreenDailyCensus.svg";
import FDCByPractice from "../../assets/FDCByPractice_LandingScreenDailyCensus.svg";
import FDCbyWeekTrend from "../../assets/FDCbyWeekTrend.svg";
import ImageBlockForToolsAndScorecardsLandingPage from "../../component/common/imageBlock/ImageBlockForToolsAndScorecardsLandingPage";
import { DAILY_CENSUS_ER_URLS } from "../../webConfig";
import CensusToolMarketHospitalAndPracticeERHeader from "./CensusToolMarketHospitalAndPracticeERHeader";
import { useNavigate } from "react-router-dom";
import { PagesPath } from "../../PagesPath";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import { checkUserToolAccess } from "../../component/common/userAuthorization/checkUserAccess";

const VerticalLine = () => {
  return (
    <div
      style={{
        borderLeft: "1px solid rgba(86, 86, 86, 0.5)",
        height: "20px",
      }}
    />
  );
};

const StackCustom = ({ children }) => {
  const DailyCensusToolER = 30;
  const [accessObj]=useState(checkUserToolAccess(DailyCensusToolER));
  return (
    <div>
      {!accessObj.hasAccess ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <Stack
          direction="row"
          spacing={2.5}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "stretch",
          }}
        >
          {children}
        </Stack>
      )}
    </div>
  );
};

const CensusToolMarketHospitalAndPracticeER = () => {
  return (
    <Box
      sx={{
        width: "100%",
        //p: { xs: 2, sm: 2 },
      }}
    >
      <CensusToolMarketHospitalAndPracticeERHeader />
      <Stack marginTop="31px" spacing={3}>
        <StackCustom>
          <ImageBlockForToolsAndScorecardsLandingPage
            imageWidth="37px"
            imageHeight="43px"
            img={dailyCensusImage}
            text={"Daily \n Census"}
            description="Master Admissions view across all relevant patient records"
            url={DAILY_CENSUS_ER_URLS.DailyCensus}
          />
          <VerticalLine />
          <ImageBlockForToolsAndScorecardsLandingPage
            img={FDCByDateAndTime}
            text={"Trend Analysis by \n Day & Time"}
            description="Admissions Data count view by Day and the hour"
            imageWidth="50px"
            imageHeight="36px"
            url={DAILY_CENSUS_ER_URLS.FDCByDayAndTime}
          />
          <VerticalLine />
          <ImageBlockForToolsAndScorecardsLandingPage
            img={FDCByDay}
            text={"Trend Analysis \n by Day"}
            description="Admissions Data View by Day & Event"
            imageWidth="45px"
            imageHeight="40px"
            url={DAILY_CENSUS_ER_URLS.FDCByDay}
          />
          <VerticalLine />
          <ImageBlockForToolsAndScorecardsLandingPage
            img={FDCbyWeekTrend}
            text={"FDC  \n Week Trend"}
            description="Admissions view by Week"
            imageWidth="45px"
            imageHeight="40px"
            url={DAILY_CENSUS_ER_URLS.FDCbyWeekTrend}
          />
        </StackCustom>
        <StackCustom>
          <ImageBlockForToolsAndScorecardsLandingPage
            imageWidth="46px"
            imageHeight="38px"
            img={FDCByMember}
            text="Frequent Flyers"
            description="Admissions data view by Member"
            url={DAILY_CENSUS_ER_URLS.FDCByMember}
          />
          <VerticalLine />
          <ImageBlockForToolsAndScorecardsLandingPage
            img={FDCByPostpartum}
            text={"FDC  \n Post-Partum"}
            description="Patient level data view for Pregnancy related Admissions"
            imageWidth="46px"
            imageHeight="42px"
            url={DAILY_CENSUS_ER_URLS.FDCByPostpartum}
          />
          <VerticalLine />
          <ImageBlockForToolsAndScorecardsLandingPage
            img={FDCByPractice}
            text="Trend Analysis by Practice"
            description="Admissions data view for a practice by admissions event type"
            imageWidth="45px"
            imageHeight="45px"
            url={DAILY_CENSUS_ER_URLS.FDCByPractice}
          />
        </StackCustom>
      </Stack>
    </Box>
  );
};

export default CensusToolMarketHospitalAndPracticeER;
