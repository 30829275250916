import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchValue: "",
};

const familyMembersProfileSearchSlice = createSlice({
  name: "familyMembersProfileSearch",
  initialState,
  reducers: {
    getSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
  },
});

export default familyMembersProfileSearchSlice.reducer;
export const { getSearchValue } = familyMembersProfileSearchSlice.actions;
