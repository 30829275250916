import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/Agent";
const initialState = {
  isLoading: false,
  market: [],
  subMarket:[],
  practice:[],
  practiceLazy:[],
  useConfigFilterList:[],
  errorMessage: "",
};

export const getMarketsList = createAsyncThunk(
  "market",
  async ({}) => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.getMarkets();
    return response;
  }
);

export const getSubMarketsList = createAsyncThunk(
  "subMarket",
  async ({marketId}) => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.GetSubMarketDetails({marketId});
    return response;
  }
);

export const GetUserConfigFilterList = createAsyncThunk(
  "userConfigFilterList",
  async ({}) => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.GetUserConfigFilterList();
    return response;
  }
);


const sdohMarketsListSlice = createSlice({
  name: "marketsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMarketsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMarketsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.market = action.payload;
        state.errorMessage="";
      })
      .addCase(getMarketsList.rejected, (state, action) => {
        state.isLoading = false;
        state.market = [];
        state.errorMessage = action.error.message;
      })
      .addCase(getSubMarketsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubMarketsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subMarket = action.payload;
        state.errorMessage="";
      })
      .addCase(getSubMarketsList.rejected, (state, action) => {
        state.isLoading = false;
        state.subMarket = [];
        state.errorMessage = action.error.message;
      })
      .addCase(getPracticeList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPracticeList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.practice = action.payload;
        state.errorMessage="";
      })
      .addCase(getPracticeList.rejected, (state, action) => {
        state.isLoading = false;
        state.practice = [];
        state.errorMessage = action.error.message;
      })

      .addCase(getPracticeLazyList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPracticeLazyList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.practiceLazy = action.payload;
        state.errorMessage="";
      })
      .addCase(getPracticeLazyList.rejected, (state, action) => {
        state.isLoading = false;
        state.practiceLazy = [];
        state.errorMessage = action.error.message;
      })


      .addCase(GetUserConfigFilterList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetUserConfigFilterList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.useConfigFilterList = action.payload;
        state.errorMessage="";
      })
      .addCase(GetUserConfigFilterList.rejected, (state, action) => {
        state.isLoading = false;
        state.useConfigFilterList = [];
        state.errorMessage = action.error.message;
      })
  },
});

export const getPracticeList = createAsyncThunk(
  "practice",
  async ({marketId,subMarketId}) => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.GetPracticeDetails({marketId,subMarketId});
    return response;
  }
);

export const getPracticeLazyList = createAsyncThunk(
  "practiceList",
  async ({marketId,subMarketId,sortColumn, sortOrder, page,search}) => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.GetPracticeLazyDetails(marketId,subMarketId,sortColumn, sortOrder, page,search);
    return response;
  }
);

const sdohPracticeListSlice = createSlice({
  name: "practiceList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPracticeList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPracticeList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.practice = action.payload;
        state.errorMessage="";
      })
      .addCase(getPracticeList.rejected, (state, action) => {
        state.isLoading = false;
        state.practice = [];
        state.errorMessage = action.error.message;
      })
  },
});



export default sdohMarketsListSlice.reducer;
