import React, { useState } from "react";
import PageHeader from "../../component/common/header/PageHeader";
const FPTimeLineExcelLinkPageHeader = () => {
    const title = "Follow up Timeliness";
    const sevenPillarTitle = "Avoidable Inpatient Stays";
    return (
        <>
            <PageHeader title={title} sevenPillarTitle={sevenPillarTitle}>
            </PageHeader>
        </>
    );
};

export default FPTimeLineExcelLinkPageHeader;
