import React from "react";
import { Route } from "react-router-dom";
import { PagesPath } from "../../PagesPath";
import SDOHMemberDetails from "../../features/sdohAssessmentDashboard/SDOHMemberDetails";
import RoleMappingForm from "../../features/userAdminConsole/Role Mapping/RoleMappingForm";
import ViewRoleMapping from "../../features/userAdminConsole/Role Mapping/ViewRoleMapping";
import AddUserRoleForm from "../../features/userAdminConsole/User Roles/AddUserRoleForm";
import AddUserForm from "../../features/userAdminConsole/Users/AddUserForm";
import ClinicalProfileExcelLinkPage from "../../pages/ClinicalProfileExcelLinkPage/ClinicalProfileExcelLinkPage";
import ERAlertClosureFromTextingPage from "../../pages/ERAlertClosureFromTextingPage/ERAlertCLosureFromTextingPage";
import FPTimeLineExcelLinkPage from "../../pages/FPTimeLineExcelLinkPage/FPTimeLineExcelLinkPage";
import HighRiskCareExcelLinkPage from "../../pages/HighRiskCareExcelLinkPage/HighRiskCareExcelLinkPage";
import IPAlertToolFromTextingPage from "../../pages/IPAlertToolFromTextingPage/IPAlertToolFromTextingPage";
import InpatientTrendsPage from "../../pages/InpatientTrends/inpatientTrends";
import BannerComponentPage from "../../pages/bannerComponent/BannerComponentPage";
import CensusToolMarketHospitalAndPracticeERPage from "../../pages/censusToolMarketHospitalAndPracticeERPage/CensusToolMarketHospitalAndPracticeERPage";
import CensusToolMarketHospitalAndPracticePage from "../../pages/censusToolMarketHospitalAndPracticePage/CensusToolMarketHospitalAndPracticePage";
import CohortRegistryManagementPage from "../../pages/cohortRegistryManagementPage/CohortRegistryManagementPage";
import DerosteringTrackingPage from "../../pages/derosteringTrackingPage/DerosteringTrackingPage";
import EducationConfigurationPage from "../../pages/educationConfigurationPage/EducationConfigurationPage";
import AddProgramForm from "../../features/educationConfiguration/ProgramsConfiguration/AddProgramForm";
import AddEventForm from "../../features/educationConfiguration/EventsConfiguration/AddEventForm";
import EventPage from "../../pages/eventsPage/EventsPage";
import FamilyMembersProfilePage from "../../pages/familyMembersProfilePage/FamilyMembersProfilePage";
import HomePage from "../../pages/homePage/HomePage";
import MedicalAdherenceToolPage from "../../pages/medicalAdherenceTool/MedicalAdherenceToolPage";
import MembershipAndGrowthTrendsPage from "../../pages/membershipAndGrowthTrendsPage/MembershipAndGrowthTrendsPage";
import ProgramPage from "../../pages/programPage/ProgramPage";
import RecertificationPage from "../../pages/recertification/RecertificationPage";
import RecertificationDevPage from "../../pages/recertification/RecertificationDevPage";
import ReportPackagePage from "../../pages/ReportPackage/ReportPackagePage";
//import ACOReachReport from "../../pages/ReportPackage/ACOReachReport";
import SDOHAssessmentDashboardPage from "../../pages/sdohAssessmentDashboardPage/SDOHAssessmentDashboardPage";
import SDOHNewAssementFormPage from "../../pages/sdohNewAssessmentFormPage/SDOHNewAssessmentFormPage";
import TransitionOfCareResultsPage from "../../pages/transitionOfCareResultsPage/transitionOfCareResultsPage";
import UserAdminConsolePage from "../../pages/userAdminConsolePage/UserAdminConsolePage";
import VBCPerfomancePage from "../../pages/vbcPerfomancePage/VBCPerfomancePage";
import VoluntaryAlignmentPage from "../../pages/voluntaryAlignment/VoluntaryAlignmentPage";
import VoluntaryAlignmentTrackerPage from "../../pages/voluntaryAlignmentTrackerPage/VoluntaryAlignmentTrackerPage";
import Layout from "./Layout";
import SevenPillarAdminLayout from "./SevenPillarAdminLayout";
import SevenPillarEducationLayout from "./SevenPillarEducationLayout";
import SevenPillarLayout from "./SevenPillarLayout";
import ActiveGapClosuresListPage from "../../pages/activeGapClosuresList/activeGapClosuresListPage";
import CreateCohortRegistryManagement from "../../features/cohortRegistryManagement/CreateCohortRegistryManagement/CreateCohortRegistryManagement";
import EditCohortRegistryManagement from "../../features/cohortRegistryManagement/EditCohortRegistryManagement/EditCohortRegistryManagement";
import ViewCohortRegistry from "../../features/cohortRegistryManagement/ViewCohortRegistry/ViewCohortRegistry";
import HealthHomeEducationConfigPage from "../../pages/HealthHomeEducationConfigPage/HealthHomeEducationConfigPage";
import AddEditTrainingProgram from "../../features/HealthHomeEducationConfiguration/TrainingPrograms/AddEditTrainingProgram";
import SevenPillarHealthHomeEducationLayout from "./SevenPillarHealthHomeEducationLayout";
import HealthHomeTrainingProgramsPage from "../../pages/HealthHomeTrainingProgramsPage/HealthHomeTrainingProgramsPage";
import HealthHomeTrainingDocumentsPage from "../../pages/HealthHomeTrainingDocumentsPage/HealthHomeTrainingDocumentsPage";
import AddEditTrainingDocument from "../../features/HealthHomeEducationConfiguration/TrainingDocuments/AddEditTrainingDocument";
import CreateCohortByImport from "../../features/cohortRegistryManagement/CohortByImport/CreateCohortByImport";
const AppRoute = () => {
  return (
    <>
      <Route path={PagesPath.LAYOUT_URL} element={<Layout />}>
        <Route path={PagesPath.HOME_URL} element={<HomePage />} />
        <Route
          path={PagesPath.FAMILY_MEMBER_PROFILE_URL}
          element={<FamilyMembersProfilePage />}
        />
      </Route>
      <Route path="/sevenpillar" element={<SevenPillarLayout />}>
        <Route
          path={PagesPath.VoluntaryAlignmentTracker_URL}
          element={<VoluntaryAlignmentTrackerPage />}
        />
        <Route
          path={PagesPath.DeRosteringTracking_URL}
          element={<DerosteringTrackingPage />}
        />
        <Route
          path={PagesPath.CensusToolMarketHospitalPratice_URL}
          element={<CensusToolMarketHospitalAndPracticePage />}
        />
        <Route
          path={PagesPath.MembershipAndGrowthTrends_URL}
          element={<MembershipAndGrowthTrendsPage />}
        />
        <Route
          path={PagesPath.VBCPerformance_URL}
          element={<VBCPerfomancePage />}
        />
        <Route
          path={PagesPath.VoluntaryAlignment_URL}
          element={<VoluntaryAlignmentPage />}
        />
        <Route
          path={PagesPath.CensusToolMarketHospitalPraticeER_URL}
          element={<CensusToolMarketHospitalAndPracticeERPage />}
        />
        <Route
          path={PagesPath.ERAlertToolFromTexting_URL}
          element={<ERAlertClosureFromTextingPage />}
        />
        <Route
          path={PagesPath.IPAlertToolFromTexting_URL}
          element={<IPAlertToolFromTextingPage />}
        />
        <Route
          path={PagesPath.CohortRegistryManagement_URL}
          element={<CohortRegistryManagementPage />}
        />
        <Route
          path={PagesPath.CreateCohortRegistryManagement_URL}
          element={<CreateCohortRegistryManagement />}
        />
        <Route
          path={PagesPath.EditCohortRegistryManagement_URL}
          element={<EditCohortRegistryManagement />}
        />
        
        <Route
          path={PagesPath.ViewCohortRegistry_URL}
          element={<ViewCohortRegistry />}
        />
        <Route
          path={PagesPath.CreateCohortByImport_URL}
          element={<CreateCohortByImport />}
        />
        <Route
          path={PagesPath.HighRiskCareExcel_URL}
          element={<HighRiskCareExcelLinkPage />}
        />
        <Route
          path={PagesPath.ClinicalProfileExcel_URL}
          element={<ClinicalProfileExcelLinkPage />}
        />

        <Route
          path={PagesPath.TOCTrends_URL}
          element={<TransitionOfCareResultsPage />}
        />
        <Route
          path={PagesPath.FPTimeliness_URL}
          element={<FPTimeLineExcelLinkPage />}
        />
        <Route
          path={PagesPath.InpatientTrends_URL}
          element={<InpatientTrendsPage />}
        />

        <Route
          path={PagesPath.Recertification_URL}
          element={<RecertificationPage />}
        />
        <Route
          path={PagesPath.RecertificationDev_URL}
          element={<RecertificationDevPage />}
        />
        <Route
          path={PagesPath.ReportPackagePage_URL}
          element={<ReportPackagePage />}
        />
        {/* <Route
          path={PagesPath.ACOReachReport_URL}
          element={<ReportPackagePage />}
        /> */}
        <Route
          path={PagesPath.SDOHAssessmentDashboard_URL}
          element={<SDOHAssessmentDashboardPage />}
        />
        <Route
          path={PagesPath.SDOHNewAssessmentForm_URL}
          element={<SDOHNewAssementFormPage />}
        />
        <Route
          path={PagesPath.SDOHMemberDetails_URL}
          element={<SDOHMemberDetails />}
        />
        <Route
          path={PagesPath.MedicationAdherenceActionLists_URL}
          element={<MedicalAdherenceToolPage />}
        />
        <Route
          path={PagesPath.ActiveGapClosuresList_URL}
          element={<ActiveGapClosuresListPage />}
        />
      </Route>
      <Route element={<SevenPillarAdminLayout />}>
        <Route
          path={PagesPath.BannerComponentURL}
          element={<BannerComponentPage />}
        />
        <Route
          path={PagesPath.UserAdminConsoleURL}
          element={<UserAdminConsolePage />}
        />
        <Route
          path={PagesPath.EducationConfiguration}
          element={<EducationConfigurationPage />}
        />
        <Route
          path={PagesPath.HealthHomeEducationConfiguration}
          element={<HealthHomeEducationConfigPage />}
        />
      </Route>
      <Route
        path="/sevenpillar/UserAdminConsole"
        element={<SevenPillarAdminLayout />}
      >
        <Route path={PagesPath.AddUserURL} element={<AddUserForm />} />
        <Route path={PagesPath.AddUserRolesURL} element={<AddUserRoleForm />} />

        <Route
          path={PagesPath.AddRoleMappingURL}
          element={<RoleMappingForm />}
        />
        <Route
          path={PagesPath.ViewRoleMappingURL}
          element={<ViewRoleMapping />}
        />
      </Route>
      <Route
        path="/sevenpillar/EducationConfiguration"
        element={<SevenPillarAdminLayout />}
      >
        <Route path={PagesPath.AddProgram} element={<AddProgramForm />} />
        <Route path={PagesPath.AddEvent} element={<AddEventForm />} />
      </Route>
      <Route
        path="/sevenpillar/HealthHomeEducationConfig"
        element={<SevenPillarAdminLayout />}
      >
        <Route
          path={PagesPath.AddTrainingProgram}
          element={<AddEditTrainingProgram />}
        />
        <Route
          path={PagesPath.AddTrainingDocument}
          element={<AddEditTrainingDocument />}
        />
      </Route>
      <Route element={<SevenPillarEducationLayout />}>
        <Route path={PagesPath.Programs_URL} element={<ProgramPage />} />
        <Route path={PagesPath.Events_URL} element={<EventPage />} />
      </Route>
      <Route element={<SevenPillarHealthHomeEducationLayout />}>
        <Route
          path={PagesPath.HealthHomeTrainingPrograms_URL}
          element={<HealthHomeTrainingProgramsPage />}
        />
        <Route
          path={PagesPath.HealthHomeTrainingDocuments_URL}
          element={<HealthHomeTrainingDocumentsPage />}
        />
      </Route>
    </>
  );
};

export default AppRoute;
