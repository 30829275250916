import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import RecertificationPageHeader from "./RecertificationPageHeader";
import ScorecardComponent from "../../component/common/scorecard/scorecard";
const RecertificationPage = () => {
    const [recertificationID,setRecertificationID]=useState(9);
    return (
        <Box >
            <RecertificationPageHeader />
            <ScorecardComponent ScoreId={recertificationID} />
        </Box>
    );
}

export default RecertificationPage;