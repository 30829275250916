import { Close, OutdoorGrillTwoTone } from "@mui/icons-material";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { Styles } from "../../Styles";
import { useAppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import { getMemberDetailsByOurId } from "./memberDetailsProfileSlice";
import { format } from "date-fns";
import { parseISO } from "date-fns";
import Loader from "../../component/common/loader/Loader";

const TypographyLabel = ({ children }) => {
  return (
    <Typography
      sx={{
        color: "#000",
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
      }}
    >
      {children}
    </Typography>
  );
};

const TypographyDetail = ({ children }) => {
  return (
    <Typography
      sx={{
        color: "#000",
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "300",
        lineHeight: "normal",
      }}
    >
      {children}
    </Typography>
  );
};

const DerosteringMemberDetails = ({ isOpen, onClose, rowData }) => {
  const dispatch = useAppDispatch();

  const { isLoading, memberDetails, errorMessage } = useSelector(
    (state) => state.memberDetails
  );

  const getMemberDetailsByOurIdData = useCallback(async () => {
    await dispatch(getMemberDetailsByOurId(rowData ? rowData.ourID : 0));
  }, [rowData, dispatch]);

  useEffect(() => {
    if (isOpen === true) getMemberDetailsByOurIdData();
  }, [isOpen, getMemberDetailsByOurIdData]);

  if (!rowData) return null;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{
        backdrop: { onClick: null },
        // root: { height: "auto", overflowY: "visible" },
      }}
      sx={{ height: "auto", overflowY: "auto" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "678px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
          // overflowY: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Member Details
          <span>
            <Close onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "622px",
            opacity: "0.3",
            marginTop: "-8px",
            // marginBottom: "-4px",
          }}
        />
        {isLoading ? (
          <Box sx={{ minHeight: "480px" }}>
            <Loader isLoading={isLoading} />
          </Box>
        ) : (
          <Stack spacing={2} sx={{ marginTop: "20px" }}>
            <Stack direction="row" spacing={2}>
              <TypographyLabel>Insurance ID :</TypographyLabel>
              <TypographyDetail>
                {memberDetails.insuranceId == ""
                  ? "-"
                  : memberDetails.insuranceId}
              </TypographyDetail>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TypographyLabel>Member Name :</TypographyLabel>
              <TypographyDetail>
                {memberDetails.patientName == ""
                  ? "-"
                  : memberDetails.patientName}
              </TypographyDetail>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TypographyLabel>DOB :</TypographyLabel>
              <TypographyDetail>
                {memberDetails.memberDob
                  ? format(new Date(memberDetails.memberDob), "dd MMM yyyy")
                  : "-"}
              </TypographyDetail>
            </Stack>
            <Stack direction="row" alignItems="baseline" spacing={2}>
              <Stack direction="row" spacing={2} sx={{ minWidth: "300px" }}>
                <TypographyLabel>PCP Name :</TypographyLabel>
                <TypographyDetail>
                  {memberDetails.providerName == ""
                    ? "-"
                    : memberDetails.providerName}
                </TypographyDetail>
              </Stack>
              <Stack direction="row" spacing={2}>
                <TypographyLabel>Practice Name :</TypographyLabel>
                <TypographyDetail>
                  {memberDetails.practiceName == ""
                    ? "-"
                    : memberDetails.practiceName}
                </TypographyDetail>
              </Stack>
            </Stack>
            <Typography
              sx={{
                color: "#000",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              Contact Information
            </Typography>
            <hr
              style={{
                width: "622px",
                opacity: "0.3",
                marginTop: "4px",
              }}
            />
            <Stack direction="row" spacing={2}>
              <TypographyLabel>Member Address :</TypographyLabel>
              <TypographyDetail>
                {memberDetails.membersAddress == ""
                  ? "-"
                  : memberDetails.membersAddress}
              </TypographyDetail>
            </Stack>
            <Stack direction="row" alignItems="baseline" spacing={2}>
              <Stack direction="row" spacing={2} sx={{ minWidth: "300px" }}>
                <TypographyLabel>Member Phone :</TypographyLabel>
                <TypographyDetail>
                  {memberDetails.membersPhone == ""
                    ? "-"
                    : memberDetails.membersPhone}
                </TypographyDetail>
              </Stack>
              <Stack direction="row" spacing={2}>
                <TypographyLabel>Member Email :</TypographyLabel>
                <TypographyDetail>
                  {memberDetails.membersEmail == ""
                    ? "-"
                    : memberDetails.membersEmail}
                </TypographyDetail>
              </Stack>
            </Stack>
            <Typography
              sx={{
                color: "#000",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              Insurance Information
            </Typography>
            <hr
              style={{
                width: "622px",
                opacity: "0.3",
                marginTop: "4px",
              }}
            />
            <Stack direction="row" spacing={2}>
              <TypographyLabel>Payer Name :</TypographyLabel>
              <TypographyDetail>
                {memberDetails.payorName == "" ? "-" : memberDetails.payorName}
              </TypographyDetail>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TypographyLabel>Product Contract Type :</TypographyLabel>
              <TypographyDetail>
                {memberDetails.payorContractType == ""
                  ? "-"
                  : memberDetails.payorContractType}
              </TypographyDetail>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TypographyLabel>Region :</TypographyLabel>
              <TypographyDetail>
                {memberDetails.region == "" ? "-" : memberDetails.region}
              </TypographyDetail>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TypographyLabel>Assignment Status :</TypographyLabel>
              <TypographyDetail>
                {memberDetails.assignmentStatus == ""
                  ? "-"
                  : memberDetails.assignmentStatus}
              </TypographyDetail>
            </Stack>
            <hr
              style={{
                width: "622px",
                opacity: "0.3",
                marginTop: "12px",
                marginBottom: "-4px",
              }}
            />
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              padding="0"
              sx={{ justifyContent: "flex-end", alignItems: "center" }}
            >
              <Button
                style={{
                  textTransform: "none",
                  borderColor: "black",
                  color: "black",
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                }}
                variant="outlined"
                onClick={onClose}
              >
                Close
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default DerosteringMemberDetails;
