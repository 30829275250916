import { axiosRequests } from "../AxiosRequest";

function getFamilyMemberProfile(name,sortColumn, sortOrder,page=1) {
  if (name === undefined) name = "";
  if(sortColumn === undefined) sortColumn = "";
  if(sortOrder === undefined) sortOrder = "";
  return axiosRequests.get(`/Members?name=${name}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&pageNumber=${page}`);
  // return axiosRequests.get("/Members");
}

export const FamilyMemberProfileAgent = {
  getFamilyMemberProfile: getFamilyMemberProfile,
};
