import React, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    Link, Button,
    Stack, Accordion, AccordionSummary, AccordionDetails,
    Tooltip, Typography
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/store";
import { useSelector } from "react-redux";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import CustomPaginationSlot from "../../../component/common/datagrid/CustomPaginationSlot";
import Searchbox from "../../../component/common/searchbox/Searchbox";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { addDays, addMonths, addWeeks, format, subDays } from "date-fns";
import { COLOR, Styles } from "../../../Styles";
import cinq_1 from "../../../assets/cinq_1.png";
import MemberDetailsModal from "../CreateCohortRegistryManagement/Popup/MemberDetailsModal";
import EventsDetailsModal from "../CreateCohortRegistryManagement/Popup/EventsDetailsModal";
import "../CreateCohortRegistryManagement/CreateCohortManagementcss.css";
import IPEventsDetailsModal from "../CreateCohortRegistryManagement/Popup/IPEventsDetailsModal";
import SChip from "../../../component/common/schip/SChip";
import agent from "../../../app/api/Agent";
import Loader from "../../../component/common/loader/Loader";
import back_arrow from "../../../assets/back_arrow.svg";
import ViewCohortMemberDetails from "../ViewCohortRegistry/ViewCohortMemberDetails";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import {
    getCohortFilterDetails, getAdmissionTrendsDetail, getMemberDetails, getModalFilterDetails
} from "../../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
import PdfGenerator from "./PDF Export/PDFGenerator";
import MemberFilterDetailsModal from "../CreateCohortRegistryManagement/Popup/MemberFilterDetailsModal";
const ViewCohortMemberListDetails = ({ backAction, cohortId, rowData }) => {
    const [sortModel, setSortModel] = useState([{ field: 'memberName', sort: 'asc' }])
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [ourId, setOurId] = useState("")
    const [isCohortMembersListing, setIsCohortMembersListing] = useState(true);
    const [membersList, setMembersList] = useState([]);
    const [page, setPage] = useState(1);
    const [searchMemberName, setSearchMemberName] = useState("");
    const [searchMember, setSearchMember] = useState("");

    const [pageCount, setPageCount] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const [popupConfirmationModalOpen, SetPopupConfirmationModalOpen] = useState(false);
    const [popupConfirmationModalData, setPopupConfirmationModalData] = useState({});
    const [popupFilterDetailsModalOpen, setPopupFilterDetailsModalOpen] = useState(false);
    const [popupFilterDetailsModalData, setPopupFilterDetailsModalData] = useState({});
    const [totalEvents, setTotalEvents] = useState(0);
    const [totalIPEvents, setTotalIPEvents] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const [isCohortMembersLoading, setIsCohortMembersLoading] = useState(false);
    const [showMemberDetailsPage, setShowMemberDetailsPage] = useState(false);
    const [memberDetail, setMemberDetail] = useState({});
    const [memberHealthDetail, setMemberHealthDetail] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    // const memberDetail = useSelector(
    //     (state) => state.filterDetails.memberDetail
    // );
    const [showPDFGenerator, setShowPDFGenerator] = useState(false);
    const [memberName, setMemberName] = useState("");

    const { admissionTrendsDetails } = useSelector(
        (state) => state.filterDetails
    );

    const { modalFilterDetails } = useSelector(
        (state) => state.filterDetails
    );

    const handlePDFDownload = () => {
        setShowPDFGenerator(true);
    };

    const onPDFClose = () => {
        setShowPDFGenerator(false);
    }

    useEffect(() => {
        if (memberDetail && memberDetail?.memberName != undefined) {
            setMemberName(memberDetail?.memberName);
        }
    }, [memberDetail])

    useEffect(() => {
        setIsCohortMembersLoading(true);
        if (ourId != "") {
            setMemberDetail({});
            setMemberHealthDetail({});
            agent.SDOHAssessmentTrackingAgent.GetCohortMembersDetails(ourId
            )
                .then((response) => {
                    if (response[0] && response[0].ourId != undefined) {
                        setMemberDetail(response[0]);
                    }

                    setIsCohortMembersLoading(false);
                })
                .catch((err) => {
                    console.log("exc-getStatusDetailsData", err);
                    setIsCohortMembersLoading(false);
                });

            agent.SDOHAssessmentTrackingAgent.GetClinicalHeathDetails(ourId
            )
                .then((response) => {
                    if (response[0] && response[0].ourId != undefined) {
                        setMemberHealthDetail(response[0]);
                    }

                    setIsCohortMembersLoading(false);
                })
                .catch((err) => {
                    console.log("exc-getStatusDetailsData", err);
                    setIsCohortMembersLoading(false);
                });
        }

    }, [ourId])

    useEffect(() => {
        let obj = [...selectedRows]
        let filtered;
        if (searchMember) {
            filtered = obj.filter(
                (item) =>
                    item.memberName.toLowerCase().includes(searchMember.toLowerCase())
            );
            setMembersList(filtered);
        } else {
            setMembersList(selectedRows);
        }
    }, [searchMember])

    useEffect(() => {
        setIsCohortMembersLoading(true);
        let formData = {
            CohortDetailId: cohortId ? cohortId : 0,
            FromDate: admissionTrendsDetails?.startDate,
            ToDate: admissionTrendsDetails?.endDate,
            RiskCategory : modalFilterDetails?.riskCats,
            IPAFlag :modalFilterDetails?.ipaFlags,
            EventType :modalFilterDetails?.evtTy
        }
        agent.SDOHAssessmentTrackingAgent.GetCohortMemberListView(formData
        )
            .then((response) => {
                if (response && response.length > 0) {
                    setSelectedRows(response);
                    setMembersList(response);
                    let rowCount = response.length;
                    let numberOfPages = Math.ceil((rowCount / 10));
                    setTotalRows(rowCount);
                    setPageCount(numberOfPages);
                }
                else {
                    setMembersList([]);
                    setTotalRows(0);
                    setPageCount(0);
                }

                setIsCohortMembersLoading(false);
            })
            .catch((err) => {
                console.log("exc-getStatusDetailsData", err);
                setIsCohortMembersLoading(false);
            });
    }, [cohortId])

    const openMemberDetailsModal = (memberDtl) => {
        dispatch(getCohortFilterDetails({
            eventStartDate: format(rowData?.fromDate, "yyyy-MM-dd"),
            eventEndDate: format(rowData?.toDate, "yyyy-MM-dd")
        }))

        dispatch(getModalFilterDetails({
            ipaFlags:rowData?.ipaFlag,
            riskCats:rowData?.riskCategory,
            evtTy:rowData?.eventType
        }));
        setPopupConfirmationModalData(memberDtl);
        SetPopupConfirmationModalOpen(true);
    }

    const closePopupModal = () => {
        SetPopupConfirmationModalOpen(false);
    }

    const ViewFilterDetailsPopup = () => {
        setPopupFilterDetailsModalData({ cohortId: cohortId });
        setPopupFilterDetailsModalOpen(true);
    }

    const closeFilterPopupModal = () => {
        setPopupFilterDetailsModalOpen(false);
    }
    const [viewEventsPopupModalOpen, SetViewEventsPopupModalOpen] = useState(false);

    const viewEventsDetails = (memberDtl) => {
        dispatch(getAdmissionTrendsDetail({
            startDate: format(rowData?.fromDate, "yyyy-MM-dd"),
            endDate: format(rowData?.toDate, "yyyy-MM-dd")
        }));
        dispatch(getModalFilterDetails({
            ipaFlags:rowData?.ipaFlag,
            riskCats:rowData?.riskCategory,
            evtTy:rowData?.eventType
        }));
        setPopupConfirmationModalData(memberDtl);
        SetViewEventsPopupModalOpen(true);
    }

    const [ipPopupConfirmationModalOpen, SetIpPopupConfirmationModalOpen] = useState(false);
    const [ipPopupConfirmationModalData, setIpPopupConfirmationModalData] = useState({});

    const [viewIpEventsPopupModalOpen, SetViewIpEventsPopupModalOpen] = useState(false);

    const viewIPEventsDetails = (memberDtl) => {
        dispatch(getAdmissionTrendsDetail({
            startDate: format(rowData?.fromDate, "yyyy-MM-dd"),
            endDate: format(rowData?.toDate, "yyyy-MM-dd")
        }));
        dispatch(getModalFilterDetails({
            ipaFlags:rowData?.ipaFlag,
            riskCats:rowData?.riskCategory,
            evtTy:rowData?.eventType
        }));
        setIpPopupConfirmationModalData(memberDtl);
        SetViewIpEventsPopupModalOpen(true);
    }
    const viewMemberDetails = (memberDtl) => {
        dispatch(getMemberDetails(memberDtl));

        setIsCohortMembersListing(false);
        setOurId(memberDtl?.ourId);
        setShowMemberDetailsPage(true);

    }

    const closeIpEventsPopupModal = () => {
        SetViewIpEventsPopupModalOpen(false);
    }

    const closeEventsPopupModal = () => {
        SetViewEventsPopupModalOpen(false);
    }

    function formatToCostPrice(number) {
        // Convert number to string
        if (number != undefined) {
            const numberStr = number.toString();

            // Match digits and decimal point using regex
            const matches = numberStr.match(/^(\d+)(?:\.(\d+))?$/);

            if (matches) {
                // Get the integer part and fractional part (if exists)
                const integerPart = (+matches[1]).toLocaleString();
                const fractionalPart = matches[2] ? matches[2].padEnd(2, '0') : '00';

                // Format the number in account balance format -- ₹
                const formattedBalance = `$ ${integerPart}`;//.${fractionalPart}
                return formattedBalance;
            } else {
                return "Invalid input";
            }
        }

    }

    const columns = [
        {
            field: "memberName",
            headerName: "Name",
            flex: 1.6,
            // sortable: false,
            renderCell: (params) => {
                return (
                    //   <div style={{display:"flex", alignItems:"center"}}>
                    <Link
                        style={{
                            textDecoration: "none",
                            color: "inherit",
                            cursor: "pointer"
                        }}
                    >
                        <div
                            onClick={() =>
                                viewMemberDetails(params.row)
                            }
                        >
                            <u>{params.row.memberName}</u>
                        </div>
                    </Link>
                    //   </div>
                );
            },
        },
        {
            field: "insuranceId",
            headerName: "Insurance ID",
            headerClassName: "classMultilineHeader",
            flex: 1.6,
            // sortable: false,
        },
        {
            field: "region",
            headerName: "Region",
            flex: 1.6,
            // sortable: false,
        },
        {
            field: "payer",
            headerName: "Payer",
            flex: 1.6,
            // sortable: false,
        },
        {
            field: "practiceName",
            headerName: "Practice",
            flex: 1.6,
            // sortable: false,
        },
        {
            field: "memberDateOfBirth",
            headerName: "DOB",
            type: "Date",
            flex: 1.6,
            // sortable: false,
            renderCell: (params) =>
                params.row.memberDateOfBirth &&
                format(new Date(params.row.memberDateOfBirth), "dd MMM yyyy"),
        },
        {
            field: "chronicCondition",
            headerName: "Chronic Condition",
            headerClassName: "classMultilineHeader",
            type: "number",
            // sortable: false,
            flex: 1.4,
        },
        {
            field: "totalEvents",
            headerName: "Total Events",
            headerClassName: "classMultilineHeader",
            type: "number",
            // sortable: false,
            flex: 1.2,
            renderCell: (params) => {
                return (
                    //   <div style={{display:"flex", alignItems:"center"}}>
                    <Link
                        style={{
                            textDecoration: "none",
                            color: "inherit",
                            cursor: "pointer"
                        }}
                    >
                        <div
                            onClick={() =>
                                viewEventsDetails(params.row)
                            }
                        >
                            <u>{params.row.totalEvents}</u>
                        </div>
                    </Link>
                    //   </div>
                );
            },
        },
        {
            field: "totalIPEvents",
            headerName: "Total IP Events",
            headerClassName: "classMultilineHeader",
            type: "number",
            flex: 1.2,
            renderCell: (params) => {
                if (params.row.totalIPEvents != 0)
                    return (
                        //   <div style={{display:"flex", alignItems:"center"}}>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                                cursor: "pointer"
                            }}
                        >
                            <div
                                onClick={() =>
                                    viewIPEventsDetails(params.row)
                                }
                            >
                                <u>{params.row.totalIPEvents}</u>
                            </div>
                        </Link>
                        //   </div>
                    );

            },
        },
        {
            field: "totalCost",
            type: "number",
            headerName: "Total Cost",
            headerClassName: "classMultilineHeader",
            flex: 1.8,
            renderCell: (params) =>
                params.row.totalCost &&
                formatToCostPrice(params.row.totalCost),
        },

        {
            field: "action",
            headerName: "Action",
            flex: 1.2,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div style={{
                        display: "flex", alignItems: "center",
                        marginTop: "7px", marginLeft: "10px", cursor: "pointer"
                    }}>
                        <Tooltip title="Show Member Details">
                            <div
                                // onClick={() => {
                                // handleNewSDOHAssessmentClick(
                                //     params.row.ourId,
                                //     params.row.sdohSubmittedDate,
                                //     params.row.memberRiskScore,
                                //     params.row.practiceName,
                                //     params.row.providerName
                                // );
                                // setIsNewAssessment(false);
                                // }}
                                onClick={() => openMemberDetailsModal(params.row)}
                            >
                                <VisibilityOutlinedIcon />
                            </div>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    function getRowId(row) {
        return row.ourId + "_" + row.memberDateOfBirth;
    }

    const handleAddToCohort = () => {

    }

    return (
        <Box sx={{
            paddingTop: 2, width: "100%",
            display: "flex", justifyContent: "center"
        }}>
            {
                isCohortMembersListing ? (

                    <>

                        <Grid container>
                            <Grid item xs={6} md={6} justifyContent="flex-end">
                                <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                                    <Link
                                        onClick={backAction} //() => openDashboardPage()
                                        style={{
                                            textDecoration: "none",
                                            "&:hover": {
                                                color: COLOR.ORANGE,
                                            },
                                        }}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                                alignContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <img src={back_arrow} alt="" />
                                            <Typography
                                                sx={{
                                                    textAlign: "left",
                                                    letterSpacing: "1px",
                                                    color: "#000",
                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                }}
                                            >
                                                {rowData?.cohortName}

                                            </Typography>
                                        </Stack>
                                    </Link>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={6} justifyContent="flex-end">
                                <Stack direction="row" spacing={2} sx={{ justifyContent: "end" }}>
                                    <Button
                                        // disabled={!accessObj || !accessObj.isEditAccess}
                                        sx={{
                                            textTransform: "none",
                                            color: "#DC4F34",
                                            border: "1px solid",
                                            borderColor: COLOR.ORANGE,
                                            textAlign: "center",
                                            leadingTrim: "both",
                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                            fontSize: "12px",
                                            fontStyle: Styles.FONT_STYLE_NORMAL,
                                            fontWeight: "500",
                                            lineHeight: "22px",
                                            "&.MuiButton-root:hover": {
                                                borderColor: COLOR.ORANGE,
                                                border: "1px solid #F37021",
                                                //   borderRadius: "4px",
                                            },
                                        }}
                                        variant="outlined"
                                        color="orangeTheme"
                                        onClick={() => handlePDFDownload()}
                                    >
                                        {/* <DownloadIcon style={{ paddingTop: "5px", marginTop: "-4px" }} /> */}
                                        {/* <img src={Download_Icon} alt="" />&nbsp; */}
                                        Export
                                    </Button>
                                    <Button
                                        // disabled={!accessObj || !accessObj.isEditAccess}
                                        sx={{
                                            textTransform: "none",
                                            color: "#DC4F34",
                                            border: "1px solid",
                                            borderColor: COLOR.ORANGE,
                                            textAlign: "center",
                                            leadingTrim: "both",
                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                            fontSize: "12px",
                                            fontStyle: Styles.FONT_STYLE_NORMAL,
                                            fontWeight: "500",
                                            lineHeight: "22px",
                                            "&.MuiButton-root:hover": {
                                                borderColor: COLOR.ORANGE,
                                                border: "1px solid #F37021",
                                                //   borderRadius: "4px",
                                            },
                                        }}
                                        variant="outlined"
                                        color="orangeTheme"
                                        onClick={() => ViewFilterDetailsPopup()}
                                    >
                                        {/* <DownloadIcon style={{ paddingTop: "5px", marginTop: "-4px" }} /> */}
                                        {/* <img src={Download_Icon} alt="" />&nbsp; */}
                                        View Details
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid container sx={{
                                paddingTop: 2,
                                minHeight: "100%",
                                // marginLeft: "1%",
                                // boxShadow: "0px 3px 46px 0px rgba(0, 0, 0, 0.12)",
                                // border: "1px solid rgba(0,0,0,.12)",
                                // backgroundColor: "white",
                                // backgroundColor: "#E2E2E24D"
                            }} >
                                <Grid item xs={12} >
                                    {/* sx={{ paddingLeft: "25px", paddingRight: "25px" }} */}
                                    <Stack direction="column" spacing={1}>
                                        <Grid container>
                                            {
                                                isCohortMembersLoading ? (
                                                    <Loader isLoading={isCohortMembersLoading} />
                                                ) : (

                                                    <Grid item xs={12}>
                                                        <Stack direction="column" spacing={2}>
                                                            <div style={{ display: "grid" }}>
                                                                {/* <>
                                                                    <DataGrid
                                                                        //   hideFooter={hideFooter}
                                                                        sx={{
                                                                            marginTop: 2,
                                                                            "& .MuiDataGrid-columnHeader": {
                                                                                backgroundColor: "#F2F2F2",
                                                                                color: "#11182799",
                                                                            },
                                                                            "& .MuiDataGrid-overlayWrapper": {
                                                                                height: "34px !important",
                                                                            },
                                                                            "& .MuiDataGrid-virtualScroller": {
                                                                                overflow: "visible !important"
                                                                            },
                                                                            "& .MuiDataGrid-selectedRowCount": {
                                                                                display: "none"
                                                                            },
                                                                            "& .MuiDataGrid-footerContainer": {
                                                                                display: "none"
                                                                            }
                                                                        }}
                                                                        rows={membersList}
                                                                        className="cohortGrid"
                                                                        columns={columns}
                                                                        initialState={{
                                                                            pagination: {
                                                                                setPage: setPage,
                                                                                paginationModel: { count: pageCount, page: page, totalRows: totalRows },
                                                                                colorTheme: 'orangeTheme',
                                                                                className: 'pageNumber'
                                                                            }
                                                                        }}
                                                                        pagination
                                                                        // apiRef={apiRef}
                                                                        sortModel={sortModel}
                                                                        pageSizeOptions={[10]}
                                                                        // onCellClick={handleCellClick}

                                                                        getRowId={getRowId}
                                                                        sortingOrder={['asc', 'desc']}
                                                                        sortingMode="server"
                                                                        disableColumnMenu={true}
                                                                        // onSortModelChange={(e) => handleSortModelChange(e)}
                                                                        //   onPageChange={onPageChange}
                                                                        slots={{
                                                                            pagination: CustomPaginationSlot,
                                                                            toolbar: DataGridHeader,
                                                                        }}
                                                                        slotProps={{
                                                                            toolbar: {
                                                                                headerText: "Member Details",
                                                                                fontSize: "16px",
                                                                                marginTop: "auto",
                                                                                marginBottom: "auto",
                                                                                height: "56px",
                                                                                children: (
                                                                                    <>
                                                                                        <SChip label={membersList.length}></SChip>
                                                                                        <div
                                                                                            style={{
                                                                                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                                                fontSize: "16px",
                                                                                                marginTop: "auto",
                                                                                                marginBottom: "auto",
                                                                                                marginLeft: "auto",
                                                                                            }}
                                                                                        >
                                                                                            <Searchbox
                                                                                                defaultValue={searchMemberName}
                                                                                                handleChange={(e) => {
                                                                                                    setSearchMemberName(e.target.value);
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    setPage(1);
                                                                                                    setSearchMember(searchMemberName);
                                                                                                }}
                                                                                                label="Search Member"
                                                                                            ></Searchbox>
                                                                                        </div>
                                                                                    </>
                                                                                ),
                                                                            },
                                                                            pagination: {
                                                                                setPage: setPage,
                                                                                paginationModel: { count: pageCount, page: page, totalRows: totalRows },
                                                                                colorTheme: 'orangeTheme',
                                                                                className: 'pageNumber'
                                                                            }
                                                                        }}
                                                                    // isRowSelectable={false}
                                                                    />
                                                                </> */}<DataGridCustom
                                                                    columns={columns}
                                                                    rows={membersList}
                                                                    getRowId={getRowId}
                                                                    loading={isCohortMembersLoading}
                                                                    slots={{
                                                                        toolbar: DataGridHeader,
                                                                    }}
                                                                    slotProps={{
                                                                        toolbar: {
                                                                            headerText: "Member Details",
                                                                            fontSize: "16px",
                                                                            marginTop: "auto",
                                                                            marginBottom: "auto",
                                                                            height: "56px",
                                                                            children: (
                                                                                <>
                                                                                    <SChip label={membersList.length}></SChip>
                                                                                    <div
                                                                                        style={{
                                                                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                                            fontSize: "16px",
                                                                                            marginTop: "auto",
                                                                                            marginBottom: "auto",
                                                                                            marginLeft: "auto",
                                                                                        }}
                                                                                    >
                                                                                        <Searchbox
                                                                                            defaultValue={searchMemberName}
                                                                                            handleChange={(e) => {
                                                                                                setSearchMemberName(e.target.value);
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                setSearchMember(searchMemberName);
                                                                                            }}
                                                                                            label="Search Member"
                                                                                        ></Searchbox>
                                                                                    </div>
                                                                                </>
                                                                            ),
                                                                        },

                                                                    }}
                                                                />
                                                            </div>
                                                        </Stack>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>

                            {showPDFGenerator && <PdfGenerator style={{ display: 'none' }} logoSrc={cinq_1} titleText={'Hello from Title'} cohortId={cohortId} memberDetail={membersList} downloadCount={0} onclose={onPDFClose}></PdfGenerator>}

                        </Grid>

                        <MemberDetailsModal
                            isOpen={popupConfirmationModalOpen}
                            onClose={closePopupModal}
                            handleAddToCohort={() => {
                                handleAddToCohort(popupConfirmationModalData.id)
                            }}
                            rowData={popupConfirmationModalData}
                            Preview={1}
                        ></MemberDetailsModal>

                        <MemberFilterDetailsModal
                            isOpen={popupFilterDetailsModalOpen}
                            onClose={closeFilterPopupModal}
                            rowData={popupFilterDetailsModalData}
                            cohortId={cohortId}
                        ></MemberFilterDetailsModal>

                        <EventsDetailsModal
                            isOpen={viewEventsPopupModalOpen}
                            onClose={closeEventsPopupModal}
                            rowData={popupConfirmationModalData}
                        ></EventsDetailsModal>

                        <IPEventsDetailsModal
                            isOpen={viewIpEventsPopupModalOpen}
                            onClose={closeIpEventsPopupModal}
                            rowData={ipPopupConfirmationModalData}
                        ></IPEventsDetailsModal>
                    </>
                ) : (
                    <>

                        <Grid container>
                            <Grid item xs={6} md={6} justifyContent="flex-end">
                                <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                                    <Link
                                        onClick={() => setIsCohortMembersListing(true)} //() => openDashboardPage()
                                        style={{
                                            textDecoration: "none",
                                            "&:hover": {
                                                color: COLOR.ORANGE,
                                            },
                                        }}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                                alignContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <img src={back_arrow} alt="" />
                                            <Typography
                                                sx={{
                                                    textAlign: "left",
                                                    letterSpacing: "1px",
                                                    color: "#000",
                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                }}
                                            >
                                                {rowData?.cohortName} : Member Details
                                                {/* {(memberName != "" ? `: ${memberName}` : "")} */}
                                            </Typography>
                                        </Stack>
                                    </Link>
                                </Stack>
                            </Grid>

                            <Grid container sx={{
                                paddingTop: 2,
                                minHeight: "100%",
                                // marginLeft: "1%",
                                // boxShadow: "0px 3px 46px 0px rgba(0, 0, 0, 0.12)",
                                // border: "1px solid rgba(0,0,0,.12)",
                                // backgroundColor: "white",
                                // backgroundColor: "#E2E2E24D"

                            }} >
                                <Grid item xs={12} >
                                    {/* sx={{ paddingLeft: "25px", paddingRight: "25px" }} */}
                                    <Stack direction="column" spacing={1}
                                    >
                                        <Grid container>
                                            {
                                                isCohortMembersLoading ? (
                                                    <Loader isLoading={isCohortMembersLoading} />
                                                ) : (
                                                    <Grid item xs={12} >
                                                        <div style={{ padding: 10 }}>
                                                            <Accordion defaultExpanded >
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1-content"
                                                                    id="panel1-header"
                                                                    sx={{
                                                                        borderBottom: '1px solid rgba(0, 0, 0, .125)',
                                                                        minHeight: '45px !important',
                                                                        backgroundColor: "#FFF9F6",
                                                                        '& .Mui-expanded': {
                                                                            margin: '0 !important',


                                                                        },
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            textAlign: "left",
                                                                            letterSpacing: "1px",
                                                                            color: "#111827B8",
                                                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                            fontWeight: "500",
                                                                            lineHeight: "17px",
                                                                            fontSize: "14px",
                                                                            paddingRight: 2

                                                                        }}
                                                                    >
                                                                        Member Details
                                                                    </Typography>
                                                                </AccordionSummary>

                                                                <AccordionDetails >
                                                                    <Stack spacing={2} sx={{ marginTop: "20px" }}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Name : </b>
                                                                                    {memberDetail.memberName}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>DOB : </b>
                                                                                    {memberDetail?.dob
                                                                                        ? format(new Date(memberDetail?.dob), "dd MMM yyyy")
                                                                                        : ""}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Address : </b>
                                                                                    {memberDetail.address}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Provider Name : </b>
                                                                                    {memberDetail.pcpName}
                                                                                </Typography>
                                                                            </Grid>

                                                                        </Grid>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Gender : </b>
                                                                                    {memberDetail.gender == "F" ? "Female" : "Male"}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Phone : </b>
                                                                                    {memberDetail.phone}
                                                                                </Typography>
                                                                            </Grid>

                                                                        </Grid>
                                                                        <Grid container spacing={2}>
                                                                            {/* <Grid item xs={7}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Practice Name :</b>
                                                                                    {memberDetail.practiceName}
                                                                                </Typography>
                                                                            </Grid> */}
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Race : </b>
                                                                                    {memberDetail.race}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Email : </b>
                                                                                    {memberDetail.email}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container spacing={2}>
                                                                            {/* <Grid item xs={3}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Language : </b>
                                                                                    {memberDetail.language}
                                                                                </Typography>
                                                                            </Grid> */}
                                                                            <Grid item xs={12}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Ethnicity : </b>
                                                                                    {memberDetail.ethinicity}
                                                                                </Typography>
                                                                            </Grid>


                                                                        </Grid>

                                                                    </Stack>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>

                                                        <div style={{ padding: 10 }}>
                                                            <Accordion defaultExpanded >
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1-content"
                                                                    id="panel1-header"
                                                                    sx={{
                                                                        borderBottom: '1px solid rgba(0, 0, 0, .125)',
                                                                        minHeight: '45px !important',
                                                                        backgroundColor: "#FFF9F6",
                                                                        '& .Mui-expanded': {
                                                                            margin: '0 !important',
                                                                            backgroundColor: "#FFF9F6"
                                                                        },
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            textAlign: "left",
                                                                            letterSpacing: "1px",
                                                                            color: "#111827B8",
                                                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                            fontWeight: "500",
                                                                            lineHeight: "17px",
                                                                            fontSize: "14px",
                                                                            paddingRight: 2

                                                                        }}
                                                                    >
                                                                        Health Details
                                                                    </Typography>
                                                                </AccordionSummary>

                                                                <AccordionDetails >
                                                                    <Stack spacing={2} sx={{ marginTop: "20px" }}>

                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={3.5}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Number of Chronic Conditions : </b>
                                                                                    {memberHealthDetail?.noOfChronicalCondition}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>CMS ESRD Status : </b>
                                                                                    {memberHealthDetail?.cmsesrdStatus}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={4.5}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>CMS Normalised Concurrent Score : </b>
                                                                                    {memberHealthDetail?.riskscoreNormalised}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={12}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Dominant Conditions : </b>
                                                                                    {memberHealthDetail?.dominantConditions}
                                                                                </Typography>
                                                                            </Grid>

                                                                        </Grid>


                                                                    </Stack>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>

                                                        <div style={{ padding: 10 }}>
                                                            <Accordion defaultExpanded>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1-content"
                                                                    id="panel1-header"
                                                                    sx={{
                                                                        borderBottom: '1px solid rgba(0, 0, 0, .125)',
                                                                        minHeight: '45px !important',
                                                                        backgroundColor: "#FFF9F6",
                                                                        '& .Mui-expanded': {
                                                                            margin: '0 !important',
                                                                        },
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            textAlign: "left",
                                                                            letterSpacing: "1px",
                                                                            color: "#111827B8",
                                                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                            fontWeight: "500",
                                                                            lineHeight: "17px",
                                                                            fontSize: "14px",
                                                                            paddingRight: 2

                                                                        }}
                                                                    >
                                                                        Cost And Visit Details
                                                                    </Typography>
                                                                </AccordionSummary>

                                                                <AccordionDetails >
                                                                    <Stack spacing={2} sx={{ marginTop: "20px" }}>

                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>IP Re-admit Probability : </b>
                                                                                    {memberHealthDetail?.ipRe_Admit_Prob}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>ER Visits : </b>
                                                                                    {memberHealthDetail?.erVisits}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Probability Of Admit : </b>
                                                                                    {memberHealthDetail.probofAdmit}
                                                                                </Typography>
                                                                            </Grid>


                                                                        </Grid>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>PPS : </b>
                                                                                    {memberHealthDetail?.pps}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Office Visits : </b>
                                                                                    {memberHealthDetail?.officeVisits}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Supportive Care Need Count : </b>
                                                                                    {memberHealthDetail?.supportiveCareNeedCount}
                                                                                </Typography>
                                                                            </Grid>

                                                                        </Grid>

                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Total Cost YTD : </b>
                                                                                    {memberHealthDetail?.Total_Cost_YTD}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Last Service Date : </b>
                                                                                    {memberHealthDetail?.Last_Service_Date}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Supportive Care Needs : </b>
                                                                                    {memberHealthDetail?.Supportive_Care_Need}
                                                                                </Typography>
                                                                            </Grid>

                                                                        </Grid>
                                                                        <Grid container spacing={2}>

                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Number of Alters : </b>
                                                                                    {memberHealthDetail?.noOfAlerts}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Last AWV Date : </b>
                                                                                    {memberHealthDetail?.lastAWVDate
                                                                                        ? format(new Date(memberHealthDetail?.lastAWVDate), "dd MMM yyyy")
                                                                                        : ""}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container spacing={2}>

                                                                            <Grid item xs={12}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>Admissions : </b>
                                                                                    {memberHealthDetail?.admissions}
                                                                                </Typography>
                                                                            </Grid>


                                                                        </Grid>
                                                                        <Grid container spacing={2}>

                                                                            <Grid item xs={12}>
                                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                                    <b>ALOS : </b>
                                                                                    {memberHealthDetail?.alos}
                                                                                </Typography>
                                                                            </Grid>


                                                                        </Grid>
                                                                    </Stack>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </Box>
    );
};


export default ViewCohortMemberListDetails;

