import { powerBiAxiosRequests } from "../PowerBiAxiosRequest";

function getDashboardByGroupIdAndDashboardId(groupId, dashboardId) {
  return powerBiAxiosRequests.getPowerBi(
    `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/dashboards/${dashboardId}`
  );
}

function getReportByGroupIdAndReportId(groupId, reportId) {
  return powerBiAxiosRequests.getPowerBi(
    `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}`
  );
}

export const PowerBiAgent = {
  getDashboardByGroupIdAndDashboardId: getDashboardByGroupIdAndDashboardId,
  getReportByGroupIdAndReportId: getReportByGroupIdAndReportId,
};
