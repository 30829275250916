import * as React from "react";
// import "./style.css";
import "../honeyAssetSvg/style.css";
import { Styles } from "../../../Styles";

const HoneyAssetSvg = (props) => {
  const { svgTextPath } = props || "";

  return (
    <div id="honey-svg-asset">
      <svg
        width="140px"
        height="140px"
        viewBox="0 0 131 144"
        xmlns="http://www.w3.org/2000/svg"
        className="floatTextIcon"
      >
        <g filter="url(#filter0_d_4774_7805)">
          <path
            d="M60.5245 10.2766C62.9872 8.86631 66.0128 8.86631 68.4755 10.2766L113.736 36.1954C116.225 37.6207 117.761 40.2694 117.761 43.1376V94.8624C117.761 97.7306 116.225 100.379 113.736 101.805L68.4755 127.723C66.0128 129.134 62.9872 129.134 60.5245 127.723L15.2639 101.805C12.7749 100.379 11.2394 97.7306 11.2394 94.8624V43.1376C11.2394 40.2694 12.7749 37.6207 15.2639 36.1954L60.5245 10.2766Z"
            fill="white"
          />
          <path
            d="M60.7729 10.7105C63.0818 9.38835 65.9182 9.38835 68.2271 10.7105L113.488 36.6293C115.821 37.9655 117.261 40.4487 117.261 43.1376V94.8624C117.261 97.5513 115.821 100.034 113.488 101.371L68.2271 127.289C65.9182 128.612 63.0818 128.612 60.7729 127.289L15.5124 101.371C13.1789 100.034 11.7394 97.5513 11.7394 94.8624V43.1376C11.7394 40.4487 13.1789 37.9655 15.5124 36.6293L60.7729 10.7105Z"
            stroke="#F37021"
          />
        </g>

        <foreignObject x="22" y="12" width="65%" height="63%" fill="#ffffff">
          {/* <div style={{ width: "140px", height: "140px" }}> */}
          <p
            className="svgTextPath"
            style={{
              color: "#FFF",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "16px",
              alignItems: "center",
              verticalAlign: "center",
              fontSize: "13px",
              whiteSpace: "pre-line",
            }}
          >
            {svgTextPath}
          </p>
          {/* </div> */}
        </foreignObject>

        {/* <text
          id="GT"
          fontFamily="BrandonText-BoldItalic, Brandon Text"
          fontSize="14"
          fontStyle="italic"
          fontWeight="bold"
          letterSpacing="0.7"
          fill="#F0F1F2"
        >
          <tspan x="6.843" y="21">
            {props.name}
          </tspan>
        </text>
        <defs>
          <filter
            id="filter0_d_4774_7805"
            x="0.239502"
            y="0.21875"
            width="130.521"
            height="143.562"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="3" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0955152 0 0 0 0 0.0955152 0 0 0 0 0.0955152 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_4774_7805"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_4774_7805"
              result="shape"
            />
          </filter>
        </defs> */}
      </svg>
    </div>
  );
};

export default HoneyAssetSvg;
