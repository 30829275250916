import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { msalConfig } from "./authConfig";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { axiosRequests } from "./app/api/AxiosRequest";
import { LoginAgent } from "./app/api/login/LoginAgent";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback(async (event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount(event.payload.account);
    localStorage.setItem("accessToken", event.payload.idToken);
    localStorage.setItem("emailAddress", event.payload.account.username);
    LoginAgent.auditSignIn();
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App msalInstance={msalInstance} />
  </React.StrictMode>
);

//export default msalInstance;
