import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useMemo, useReducer } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { COLOR, Styles } from "../../../Styles";
import ImageSvg from "../imageSvg/ImageSvg";
import SevenPillarSidebarSubMenuItem from "../sevenPillarSidebarSubMenuItem/SevenPillarSidebarSubMenuItem";
import { GetDefaultPagePath, PagesPath } from "../../../PagesPath";
import { memo } from "react";
// import { AvoidableInpatientStays } from "../../../assets";

const types = {
  TOGGLE_ALL_CHILD: "TOGGLE_ALL_CHILD",
  TOGGLE_TOOLS: "TOGGLE_TOOLS",
  TOGGLE_SCORECARDS: "TOGGLE_SCORECARDS",
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.TOGGLE_ALL_CHILD:
      return {
        ...state,
        open: action.value,
        toolsOpen: action.value,
        scorecardsOpen: action.value,
      };
    case types.TOGGLE_TOOLS:
      return { ...state, toolsOpen: action.value };
    case types.TOGGLE_SCORECARDS:
      return { ...state, scorecardsOpen: action.value };
  }
};

const initialState = {
  open: false,
  toolsOpen: false,
  scorecardsOpen: false,
};

const SevenPillarSidebarMenuItem = ({
  pillar,
  open,
  miniDrawerOpen,
  // handleDrawerClose,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const active = location.pathname.includes(pillar.key);

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    open: open,
    // toolsOpen: open,
    // scorecardsOpen: open,
  });

  useEffect(() => {
    if (miniDrawerOpen === false)
      dispatch({ type: types.TOGGLE_ALL_CHILD, value: false });
    else if (active) dispatch({ type: types.TOGGLE_ALL_CHILD, value: true });
  }, [miniDrawerOpen]);

  const handleClick = () => {
    if (miniDrawerOpen)
      dispatch({ type: types.TOGGLE_ALL_CHILD, value: !state.open });
    else {
      navigate(GetDefaultPagePath(pillar.key));
    }
  };

  const handleToolsClick = () => {
    dispatch({ type: types.TOGGLE_TOOLS, value: !state.toolsOpen });
  };

  const handleScoreCardClick = () => {
    dispatch({ type: types.TOGGLE_SCORECARDS, value: !state.scorecardsOpen });
  };

  return (
    <ListItem
      key={pillar.id}
      disablePadding={true}
      sx={{
        display: "block",
        background: "#FDFAF8",
        padding: miniDrawerOpen ? "0px" : "5px",
      }}
    >
      <ListItemButton
        onClick={handleClick}
        disableRipple={true}
        disableTouchRipple={true}
        sx={{
          width: "inherit",
          justifyContent: state.open ? "initial" : "center",
          background: active ? "rgba(243, 111, 33, 0.12)" : "transparent",
          minHeight: "30px",
          padding: miniDrawerOpen ? "0px" : "3px 3px 3px 5px ",
          borderBottom:
            miniDrawerOpen === false && active
              ? `4px solid  ${COLOR.ORANGE}`
              : "",
        }}
      >
        <div
          style={{
            width: "4px",
            height: "40px",
            flexShrink: 0,
            background: COLOR.ORANGE,
            display: miniDrawerOpen && active ? "inherit" : "none",
          }}
        />

        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 0.5,
            justifyContent: "center",
          }}
        >
          <ImageSvg
            title={miniDrawerOpen ? "" : pillar.name}
            src={require(`../../../assets/${pillar.key}.svg`)}
            activeSrc={require(`../../../assets/${pillar.key}Orange.svg`)}
            size={miniDrawerOpen ? "small" : "large"}
            active={active}
          />
        </ListItemIcon>

        <ListItemText
          primaryTypographyProps={{
            opacity: state.open ? 1 : 0,
            color: active ? COLOR.ORANGE : "#333",
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            fontSize: "12px",
            fontStyle: Styles.FONT_STYLE_NORMAL,
            fontWeight: "500",
            lineHeight: "18px",
          }}
          primary={pillar.name}
          sx={{
            opacity: miniDrawerOpen ? 1 : 0,
          }}
        />
        {miniDrawerOpen ? (
          state.open ? (
            <ExpandLess
              sx={{
                color: active ? COLOR.ORANGE : "inherit ",
              }}
            />
          ) : (
            <ExpandMore
              sx={{
                color: active ? COLOR.ORANGE : "inherit ",
              }}
            />
          )
        ) : (
          <></>
        )}
      </ListItemButton>
      <List
        disablePadding
        sx={{
          display: state.open ? "inherit" : "none",
        }}
      >
        <SevenPillarSidebarSubMenuItem
          isTools={false}
          parentMenuOpen={state.open}
          open={state.scorecardsOpen}
          title="Scorecards"
          listItems={pillar.scorecards}
          onCollapseClick={handleScoreCardClick}
          pathKey={pillar.key}
          //handleDrawerClose={handleDrawerClose}
        />
      </List>
      <List
        disablePadding
        sx={{
          display: state.open ? "inherit" : "none",
        }}
      >
        <SevenPillarSidebarSubMenuItem
          isTools={true}
          parentMenuOpen={state.open}
          open={state.toolsOpen}
          title="Tools"
          listItems={pillar.tools}
          onCollapseClick={handleToolsClick}
          pathKey={pillar.key}
          //handleDrawerClose={handleDrawerClose}
        />
      </List>
    </ListItem>
  );
};

export default memo(SevenPillarSidebarMenuItem);
