import React from "react";
import DataGridHeader from "../../../../component/common/datagrid/DataGridHeader";
import { Styles } from "../../../../Styles";
import { Chip, Stack, styled, Typography } from "@mui/material";
import "./PDFExportCss.css";
import { format } from "date-fns";
import DataGridCustom from "../../../../component/common/datagrid/DataGridCustom";

const MultilineText = styled(Typography)({
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
});


function formatToCostPrice(number) {
  // Convert number to string
  if (number != undefined) {
      const numberStr = number.toString();

      // Match digits and decimal point using regex
      const matches = numberStr.match(/^(\d+)(?:\.(\d+))?$/);

      if (matches) {
          // Get the integer part and fractional part (if exists)
          const integerPart = (+matches[1]).toLocaleString();
          const fractionalPart = matches[2] ? matches[2].padEnd(2, '0') : '00';

          // Format the number in account balance format -- ₹
          const formattedBalance = `$ ${integerPart}`;//.${fractionalPart}
          return formattedBalance;
      } else {
          return "Invalid input";
      }
  }

}

const CohortMembersListPDF = ({ memberDetail }) => {
  const columns = [
    {
      field: "memberName",
      headerName: "Name",
      width: 130,
      renderCell: (params) => {
        return <MultilineText>{params.row.memberName}</MultilineText>;
      },
    },
    {
      field: "insuranceId",
      headerName: "Insurance ID",
      headerClassName: "classMultilineHeader",
      width: 150,
      renderCell: (params) => {
        return <MultilineText>{params.row.insuranceId}</MultilineText>;
      },
    },
    {
      field: "region",
      headerName: "Region",
      width: 100,
      renderCell: (params) => {
        return <MultilineText>{params.row.region}</MultilineText>;
      },
    },
    {
      field: "payer",
      headerName: "Payer",
      width: 110,
      renderCell: (params) => {
        return <MultilineText>{params.row.payer}</MultilineText>;
      },
    },
    {
      field: "practiceName",
      headerClassName: "classMultilineHeader",
      headerName: "Practice Name",
      width: 110,
      renderCell: (params) => {
        return <MultilineText>{params.row.practiceName}</MultilineText>;
      },
    },
    {
      field: "memberDateOfBirth",
      headerName: "DOB",
      width: 95,
      renderCell: (params) => {
        if (params.row.memberDateOfBirth) {
          return <span>{format(params.row.memberDateOfBirth, "MM/dd/yyyy")}</span>;
        }
      },
    },
    {
      field: "chronicCondition",
      type: "number",
      headerClassName: "classMultilineHeader",
      headerName: "Chronic Condition",
      width: 90,
      renderCell: (params) => {
        return <MultilineText>{params.row.chronicCondition}</MultilineText>;
      },
    },

    {
      field: "totalEvents",
      type: "number",
      headerClassName: "classMultilineHeader",
      headerName: "Total Events",
      width: 70,
      renderCell: (params) => {
        return <MultilineText>{params.row.totalEvents}</MultilineText>;
      },
    },

    {
      field: "totalIPEvents",
      type: "number",
      headerClassName: "classMultilineHeader",
      headerName: "Total IP Events",
      width: 70,
      renderCell: (params) => {
        return <MultilineText>{params.row.totalIPEvents}</MultilineText>;
      },
    },
    {
      field: "totalCost",
      type: "number",
      headerName: "Total Cost",
      headerClassName: "classMultilineHeader",
      width: 120,
      renderCell: (params) => {
        return <MultilineText>{formatToCostPrice(params.row.totalCost)}</MultilineText>;
      },
    },

  ];

  function getRowId(row) {
    return row.ourId + "_" + row.memberDateOfBirth;
  }

  return (
    // 
    <div style={{ marginTop: "16px", display: "grid", className:"page-break"}} >
      {
        <DataGridCustom
          columns={columns}
          rows={memberDetail}
          pageSize={100}
          hideFooter
          getRowId={getRowId}
          loading={false}
          sortingOrder={["asc", "desc"]}
          slots={{
            toolbar: DataGridHeader,
          }}
          slotProps={{
            toolbar: {
              headerText: "",
              fontSize: "",
              marginTop: "",
              marginBottom: "",
              height: "56px",
              children: (
                // <Stack direction="row" spacing={2}>
                <div
                  style={{
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "16px",
                    width: "100%",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "5px",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        fontSize: "16px",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Member Details
                    </Typography>
                    <Chip
                      color="orangeTheme"
                      variant="contained"
                      style={{ color: "#fff" }}
                      label={memberDetail?.length}
                    ></Chip>
                  </Stack>
                </div>

                // </Stack>
              ),
            },
          }}
        />
      }
    </div>
  );
};

export default CohortMembersListPDF;
