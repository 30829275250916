import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const VBCPerfomanceHeader = () => {
    const title = "VBC Performance";
    const sevenPillarTitle = "Practice VBC Revenue & Growth";
  return (
    <>
   
      <PageHeader title={title}sevenPillarTitle={sevenPillarTitle} />
      
    </>
  );
};

export default VBCPerfomanceHeader;
