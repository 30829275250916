import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { COLOR, Styles } from "../../Styles";
import dailyCensusImage from "../../assets/DailyCensus_LandingScreenDailyCensus.svg";
// import highRiskCohortImage from "../../assets/High_Risk_Cohort.svg";
import highRiskCohortImage from "../../assets/Create_Cohort_Group.svg";
import { PagesPath } from "../../PagesPath";
import ImageBlockForToolsAndScorecardsLandingPage from "../../component/common/imageBlock/ImageBlockForToolsAndScorecardsLandingPage";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import { checkUserToolAccess } from "../../component/common/userAuthorization/checkUserAccess";
import CohortRegistryManagementHeader from "./CohortRegistryManagementHeader";

const VerticalLine = () => {
  return (
    <div
      style={{
        borderLeft: "1px solid rgba(86, 86, 86, 0.5)",
        height: "20px",
      }}
    />
  );
};

const StackCustom = ({ children }) => {
  return (
    <Stack
      direction="row"
      spacing={2.5}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "stretch",
      }}
    >
      {children}
    </Stack>
  );
};

const CohortRegistryManagement = () => {
  const navigate = useNavigate();
  const CohortRegistry = 39;

  const [accessObj] = useState(checkUserToolAccess(CohortRegistry));

  const handleClick = (type) => {
    if (type == 1) {
      var createCohortUrl = PagesPath.CreateCohortRegistryManagement_URL;
      navigate(createCohortUrl);
    }
    else if (type === 3) {
      var createCohortImportUrl = PagesPath.CreateCohortByImport_URL;
      navigate(createCohortImportUrl);
    }
    else{
      var viewCohortUrl = PagesPath.ViewCohortRegistry_URL;
      navigate(viewCohortUrl);
    }
  }
  return (
    <Box >
      <CohortRegistryManagementHeader></CohortRegistryManagementHeader>
      {!accessObj.hasAccess ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <Stack marginTop="31px" spacing={3}>
          <StackCustom>
            <Link
              style={{
                underline: "hover",
                color: "inherit",
                textDecoration: "none",
              }}
              onClick={() => handleClick(1)}
              key="DailyCensus"
            >

              <Stack
                width="27vh"
                height="32vh"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #E95110",
                  backgroundColor: "#FFF",
                  p: 2,
                }}
              >
                <div
                  style={{
                    marginTop: "2px",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={highRiskCohortImage} alt="" width="100%" height="100%" />
                </div>
                <Typography
                  sx={{
                    color: COLOR.FONT_ORANGE,
                    textAlign: "center",
                    leading: "both",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    whiteSpace: "pre-line",
                    lineHeight: "20px" /* 137.5% */,
                  }}
                >
                  + Create Cohort By Member Registry
                </Typography>
              </Stack>
            </Link>
            <VerticalLine />
            <Link
              style={{
                underline: "hover",
                color: "inherit",
                textDecoration: "none",
              }}
              onClick={() => handleClick(3)}
              key="CohortByImport"
            >

              <Stack
                width="27vh"
                height="32vh"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #E95110",
                  backgroundColor: "#FFF",
                  p: 2,
                }}
              >
                <div
                  style={{
                    marginTop: "2px",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={highRiskCohortImage} alt="" width="100%" height="100%" />
                </div>
                <Typography
                  sx={{
                    color: COLOR.FONT_ORANGE,
                    textAlign: "center",
                    leading: "both",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    whiteSpace: "pre-line",
                    lineHeight: "20px" /* 137.5% */,
                  }}
                >
                  + Create Cohort By Import
                </Typography>
              </Stack>
            </Link>
            <VerticalLine />
            <Link
              style={{
                underline: "hover",
                color: "inherit",
                textDecoration: "none",
              }}
              onClick={() => handleClick(2)}
              key="ViewCohort"
            >

              <Stack
                width="27vh"
                height="32vh"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #E95110",
                  backgroundColor: "#FFF",
                  p: 2,
                }}
              >
                <div
                  style={{
                    marginTop: "2px",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={dailyCensusImage} alt="" width="100%" height="100%" />
                </div>
                <Typography
                  sx={{
                    color: COLOR.FONT_ORANGE,
                    textAlign: "center",
                    leading: "both",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    whiteSpace: "pre-line",
                    lineHeight: "20px" /* 137.5% */,
                  }}
                >
                  View Cohort List
                </Typography>
              </Stack>
            </Link>
          </StackCustom>
        </Stack>
      )}
    </Box>
  );
};

export default CohortRegistryManagement;