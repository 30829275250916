import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/Agent";

const initialState = {
  isLoading: false,
  familyMemberList: [],
  familyMemberListCount: 0,
  errorMessage: "",
};

export const getFamilyMemberProfileList = createAsyncThunk(
  "familyMemberList",
  async ({ searchValue, sortColumn, sortOrder, page }) => {
    const response =
      await agent.FamilyMemberProfileAgent.getFamilyMemberProfile(
        searchValue,
        sortColumn,
        sortOrder,
        page
      );
    return response;
  }
);

const familyMembersProfileSlice = createSlice({
  name: "familyMembersProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFamilyMemberProfileList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFamilyMemberProfileList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.familyMemberList = action.payload.familyMembers;
        state.familyMemberListCount = action.payload.count;
      })
      .addCase(getFamilyMemberProfileList.rejected, (state, action) => {
        state.isLoading = false;
        state.familyMemberList = [];
        state.familyMemberListCount = 0;
        state.errorMessage = action.error.message;
      });
  },
});

export default familyMembersProfileSlice.reducer;
