import React from "react";
import PageHeader from "../../../component/common/header/PageHeader";

const CreateCohortRegistryHeader = () => {
    const sevenPillarTitle = "High Risk Family Member Care";
    return (
        <>
            <PageHeader sevenPillarTitle={sevenPillarTitle} />
        </>
    );
}

export default CreateCohortRegistryHeader;