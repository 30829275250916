import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import FPTimeLineExcelLinkPageHeader from "./FPTimeLineExcelLinkPageHeader";
import ScorecardComponent from "../../component/common/scorecard/scorecard";
const FPTimeLineExcelLinkPage = () => {
    const [fpTimeLineID,setFpTimeLineID]=useState(28);
    return (
        <Box >
            <FPTimeLineExcelLinkPageHeader />
            <ScorecardComponent ScoreId={fpTimeLineID} />
        </Box>
    );
}

export default FPTimeLineExcelLinkPage;