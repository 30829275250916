import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Navigate, Outlet } from "react-router-dom";
import Loader from "./loader/Loader";
import MiniDrawer from "./miniDrawer/MiniDrawer";
import NoAccessPage from "./noAccess/NoAccessPage";
import { PagesPath } from "../../PagesPath";

const SevenPillarLayout = () => {
  const isLearningUser = parseInt(localStorage.getItem("learningUser")) === 1;
  const isHealthHomeUser =
    parseInt(localStorage.getItem("healthHomeUser")) === 1;

  const [noAccess, setNoAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    var noAccess = localStorage.getItem("noAccess");
    if (parseInt(noAccess) === 1) {
      setNoAccess(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading}></Loader>
      ) : isLearningUser ? (
        <Navigate to={PagesPath.Programs_URL} />
      ) : isHealthHomeUser ? (
        <Navigate to={PagesPath.HealthHomeTrainingPrograms_URL} />
      ) : (
        <MiniDrawer noAccess={noAccess}>
          {noAccess ? <NoAccessPage></NoAccessPage> : <Outlet></Outlet>}
        </MiniDrawer>
      )}
    </>
  );
};

export default SevenPillarLayout;
