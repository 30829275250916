import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import InpatientTrendsPageHeader from "./InpatientTrendsPageHeader";
import ScorecardComponent from "../../component/common/scorecard/scorecard";
const InpatientTrendsPage = () => {
    const [iPTrendsID,setIPTrendsID]=useState(24);
    return (
        <Box >
            <InpatientTrendsPageHeader></InpatientTrendsPageHeader>
            <ScorecardComponent ScoreId={iPTrendsID} />
        </Box>
    );
}

export default InpatientTrendsPage;