import React, { useEffect } from "react";
import PdfTemplateMemberDetails from "./PdfTemplateMemberDetails";
 import html2pdf from 'html2pdf.js';

const PdfGenerator = ({logoSrc, titleText, formData, signatureImage}) => {
    var downloadCount = 0; 
    const handleDownload = () => {
        if(downloadCount === 0){
            const element = document.getElementById('pdf-container');
        
        // html2pdf().from(element).save(`${formData.memberId}.pdf`);

        html2pdf(element, {
            // margin: 1,
            filename: `${formData.memberId}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            pageBreak: { mode: 'css', after:'.break-page'},
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
        });
        }
    };
    useEffect(()=>{
        handleDownload();
        downloadCount++;
    },[]);

    return (
        <div style={{display:'none'}}>
            <PdfTemplateMemberDetails style={{display: 'none'}} logoSrc={logoSrc} titleText={titleText} formData={formData}  />
            <div id='pdf-container'>
                <PdfTemplateMemberDetails logoSrc={logoSrc} titleText={titleText} formData={formData} ></PdfTemplateMemberDetails>
            </div>
            {/* <button onClick={handleDownload}>Download Pdf</button> */}
        </div>
    )
}

export default PdfGenerator;