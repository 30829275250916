import { Box, Chip } from "@mui/material";
import { Button } from "@mui/material";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/store";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Loader from "../../component/common/loader/Loader";
import { getVoluntaryAlignmentMembersList } from "./voluntaryAlignmentListSlice";
import VoluntaryAlignmentHeader from "./voluntaryAlignmentTrackerHeader";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { COLOR, Styles } from "../../Styles";
import CustomPaginationSlot from "../../component/common/datagrid/CustomPaginationSlot";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import { checkUserToolAccess } from "../../component/common/userAuthorization/checkUserAccess";
import UpdateFormModal from "./UpdateFormModal";

const VoluntaryAlignmentTrackerID = 1;

const VoluntaryAlignmentTracker = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [initialLoad, setInitialLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);

  // const [pageSize, setPageS]
  const [sortModel, setSortModel] = useState([
    { field: "enrollmentStatus", sort: "asc" },
  ]);

  const [accessObj] = useState(
    checkUserToolAccess(VoluntaryAlignmentTrackerID)
  );
  const columns = [
    {
      field: "mbinumber",
      headerName: "MBI Number",
      flex: 1,
    },
    {
      field: "memberName",
      headerName: "Patient Name",
      flex: 1,
    },
    {
      field: "practiceName",
      headerName: "Facility Name",
      flex: 1,
      cellClassName: "facilityNameField",
    },
    {
      field: "providerName",
      headerName: "Provider Name",
      flex: 1,
    },
    {
      field: "scheduledDate",
      headerName: "Scheduled Date",
      type: "Date",
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{ fontFamily: Styles.FONT_FAMILY_POPPINS }}>
            {params.row.scheduledDate &&
              format(new Date(params.row.scheduledDate), "dd MMM yyyy")}
          </span>
        );
      },
    },
    {
      field: "enrollmentStatus",
      headerName: "Submission Status",
      flex: 1,
      width: "100px",
      sortable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.row.enrollmentStatus === "YES") {
          return (
            <Chip
              style={{ fontFamily: Styles.FONT_FAMILY_POPPINS }}
              label="Submitted"
              variant="outlined"
            />
          );
        } else return "-";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.enrollmentStatus === "YES") return;
        return (
          <Button
            disabled={!accessObj.isEditAccess}
            sx={{
              textTransform: "none",
              color: "#111827",
              border: "1px solid",
              // borderRadius: "4px",
              borderColor: COLOR.ORANGE,
              textAlign: "center",
              leadingTrim: "both",

              fontFamily: Styles.FONT_FAMILY_POPPINS,
              fontSize: "12px",
              fontStyle: Styles.FONT_STYLE_NORMAL,
              fontWeight: "500",
              lineHeight: "22px",
              "&.MuiButton-root:hover": {
                borderColor: COLOR.ORANGE,
                border: "1px solid #F37021",
                //   borderRadius: "4px",
              },
            }}
            variant="outlined"
            color="actionButton"
            onClick={() => openModal(params.row)}
          >
            Create Form
          </Button>
        );
      },
    },
  ];

  const {
    isLoading,
    voluntaryAlignmentMembersList,
    voluntaryAlignmentMembersListCount,
    errorMessage,
  } = useSelector((state) => state.voluntaryAlignmentMembersList);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowForModal, setSelectedRowForModal] = useState(null);

  const getVoluntaryAlignmentListData = useCallback(
    async (sortColumn, sortOrder, page) => {
      if (!sortColumn || !sortOrder) {
        sortModel[0].field = "enrollmentStatus";
        sortModel[0].sort = "asc";
        setSortModel(sortModel);
      }
      const response = await dispatch(
        getVoluntaryAlignmentMembersList({
          search,
          sortColumn,
          sortOrder,
          page,
        })
      );
    },
    [dispatch, search]
  );

  // UseEffect: Read OR Write ELSE Not AUthorized
  function getRowId(row) {
    return row.Id + "_" + row.mbinumber; //+ "_" + row.profileId;
  }

  const openModal = (row) => {
    setSelectedRowForModal(row);
    setIsModalOpen(true);
  };

  function closeModal() {
    // setSelectedRow(row);
    setIsModalOpen(false);
  }

  useEffect(() => {
    getVoluntaryAlignmentListData();
  }, [getVoluntaryAlignmentListData, search]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const updateSearch = (search) => {
    setSearch(search);
  };

  const handlePageChange = (page) => {
    getVoluntaryAlignmentListData(sortModel[0].field, sortModel[0].sort, page);
  };

  useEffect(() => {
    if (!initialLoad) {
      handlePageChange(page);
    } else {
      setInitialLoad(false);
    }
  }, [page]);

  const handleSortModelChange = (sortModel) => {
    let sort = null;
    if (sortModel.length > 0) {
      const { field, sort: sortOrder } = sortModel[0];
      sort = `${field}:${sortOrder}`;
      if (sortOrder === "asc") {
        sort = `${field}:desc`;
      }
    }
    setSortModel(sortModel);
    setPage(1);
    getVoluntaryAlignmentListData(sortModel[0].field, sortModel[0].sort, 1);
  };

  return (
    <Box>
      <VoluntaryAlignmentHeader
        accessObj={accessObj}
        updateSearch={updateSearch}
        isEditAccess={accessObj.isEditAccess}
      />
      {!accessObj.hasAccess ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <div>
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <Grid item>
              <DataGridCustom
                columns={columns}
                rows={voluntaryAlignmentMembersList}
                getRowId={getRowId}
                loading={isLoading}
                sortingOrder={["asc", "desc"]}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={(e) => handleSortModelChange(e)}
                slots={{
                  pagination: CustomPaginationSlot,
                }}
                slotProps={{
                  pagination: {
                    setPage: setPage,
                    paginationModel: {
                      count: Math.ceil(voluntaryAlignmentMembersListCount / 10),
                      page: page,
                      totalRows: voluntaryAlignmentMembersListCount,
                    },
                    colorTheme: "orangeTheme",
                    className: "pageNumber",
                  },
                }}
              />
            </Grid>
          )}
        </div>
      )}
      <UpdateFormModal
        isOpen={isModalOpen}
        onClose={closeModal}
        rowData={selectedRowForModal}
      ></UpdateFormModal>
    </Box>
  );
};

// export default openModal;
export default VoluntaryAlignmentTracker;
