import React from "react";
import LandingComponent from "../../component/common/landing/LandingComponent";

const HomePage = () => {
  return (
    <>
      <LandingComponent />
    </>
  );
};

export default HomePage;
