import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, Button, Modal, Stack, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { COLOR, Styles } from "../../../../Styles";
import agent from "../../../../app/api/Agent";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { alpha, styled } from '@mui/material/styles';
import Loader from "../../../../component/common/loader/Loader";
const IPEventsDetailsModal = ({ isOpen, onClose, rowData, startDateValue, endDateValue }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [memberIPEventsList, setMemberIPEventsList] = useState([]);
    const { admissionTrendsDetails } = useSelector(
        (state) => state.filterDetails
    );
    const { modalFilterDetails } = useSelector(
        (state) => state.filterDetails
    );
    useEffect(() => {
        // if (isOpen === true) getEventDetailsData();
        setMemberIPEventsList([]);
        if (isOpen === true) {
            setIsLoading(true);
            let formData = {
                ourId: rowData ? rowData.ourId : 0,
                payer: rowData ? rowData.payer : '',
                Submarket: rowData ? rowData.region : '',
                Practice: rowData ? rowData.practiceName : '',
                StartDate: admissionTrendsDetails?.startDate,
                EndDate: admissionTrendsDetails?.endDate,
                RiskCategories : modalFilterDetails?.riskCats,
                IpaFlags :modalFilterDetails?.ipaFlags,
                EventTypes : modalFilterDetails?.evtTy
            }
            agent.SDOHAssessmentTrackingAgent.GetCOHORTMemberIPEventsList(formData
            )
                .then((response) => {
                    if (response != undefined &&
                        response?.length > 0
                    ) {
                        setMemberIPEventsList(response);
                    }
                    else {
                        setMemberIPEventsList([]);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log("exc-GetCOHORTMemberIPEventsList", err);
                });
        }
    }, [isOpen])
    // if (!rowData) return;
    const OutlinedButton = styled(Button)(() => ({
        borderRadius: "4px",
        padding: "6px",
        justifyContent: "center",
        color: COLOR.BLACK,
        borderColor: COLOR.BLACK,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "capitalize",
    }));

    const ContainedButton = styled(Button)(() => ({
        variant: "contained",
        borderRadius: "4px",
        padding: "6px",
        background: COLOR.PRIMARY_ORANGE,
        justifyContent: "center",
        color: COLOR.WHITE,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "none",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
        ":hover": {
            background: COLOR.SECONDARY_ORANGE,
        },
    }));

    const DefaultTypography = styled(Typography)({
        color: "#000",
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    });

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            slotProps={{ backdrop: { onClick: null } }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%,-40%)",
                    width: "778px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    pt: 1,
                    height: "600px",
                    overflow:"auto"
                }}
            >
                <h3
                    style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
                >
                    IP Event Details
                    <span>
                        <CloseIcon onClick={onClose} style={{ float: "right" }} />
                    </span>
                </h3>
                <hr
                    style={{
                        width: "auto",
                        opacity: "0.3",
                        marginTop: "-11px",
                        marginBottom: "5px",
                    }}
                />
                {
                        isLoading ? (
                            <Loader isLoading={isLoading} />
                        ) : (
                            <>
                <Stack spacing={2} >
                    <Grid container >
                        <Grid item xs={6}>
                            <Typography style={{
                                fontWeight: 400, fontSize: "14px", lineHeight: "21px"
                            }}
                            >
                                {/* <b> */}
                                Insurance ID :
                                {/* </b>  */}
                                {rowData.insuranceId ? rowData.insuranceId : "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                {/* <b> */}
                                Member Name :
                                {/* </b> */}
                                {rowData.memberName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Typography style={{
                            fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                            color: "#DC4F34"
                        }}>
                            <b>Total Events :</b> {rowData.totalIPEvents ? rowData.totalIPEvents : "-"}
                        </Typography>
                    </Grid>
                    <>
                        {
                            (memberIPEventsList ? memberIPEventsList?.map(detail =>
                                <>
                                    <hr
                                        style={{
                                            width: "auto",
                                            opacity: "0.3",
                                            marginTop: "4px",
                                        }}
                                    />
                                    <Grid container key={detail.eventType} >
                                        <Typography style={{
                                            fontWeight: 500, fontSize: "16px", lineHeight: "21px",
                                            color: "#111827B8"
                                        }}>
                                            <b>{detail.eventType} :</b> {detail.eventCount ? detail.eventCount : "-"}
                                        </Typography>
                                    </Grid>

                                    <Stack spacing={2} sx={{
                                        border: "1px solid var(--Border, #EDEDED)",
                                    }}>
                                        <Grid container sx={{
                                            backgroundColor: "#DEDEDE", height: "50px",
                                            display: "flex", alignItems: "center",
                                            paddingLeft: "20px"
                                        }}>
                                            <Grid item xs={2.2}>
                                                <Typography style={{ fontWeight: 400, fontSize: "13px", lineHeight: "21px" }}>
                                                    <b>Event Date</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4.3}>
                                                <Typography style={{ fontWeight: 400, fontSize: "13px", lineHeight: "21px" }}>
                                                    <b>Admission Reason</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={.2}>

                                                </Grid>
                                            <Grid item xs={2.3}>
                                                <Typography style={{ fontWeight: 400, fontSize: "13px", lineHeight: "21px" }}>
                                                    <b>IPA Flag</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography style={{ fontWeight: 400, fontSize: "13px", lineHeight: "21px" }}>
                                                    <b>Facility</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <span>{detail?.eventDetails.map(detailEvents =>
                                            <Grid container sx={{
                                                display: "flex", alignItems: "center",
                                                paddingLeft: "20px",
                                                paddingBottom:"10px"
                                            }}>
                                                <Grid item xs={2.2}>
                                                    <Typography style={{ fontWeight: 400, fontSize: "13px", lineHeight: "21px" }}>
                                                        {/* <b>06/12/2024</b> */}
                                                        {/* {detailEvents.eventDate} */}
                                                        {format(new Date(detailEvents.eventDate), "dd MMM yyyy")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4.3}>
                                                    <Typography style={{ fontWeight: 400, fontSize: "13px", lineHeight: "21px" }}>
                                                        {/* <b>Admission Reason : Script</b> */}
                                                        {detailEvents.admissionReason}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={.2}>

                                                </Grid>
                                                <Grid item xs={2.3}>
                                                    <Typography style={{ fontWeight: 400, fontSize: "13px", lineHeight: "21px" }}>
                                                        {/* <b>CINQCARE</b> */}
                                                        {detailEvents.ipa}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontWeight: 400, fontSize: "13px", lineHeight: "21px" }}>
                                                        {/* <b>ECMC</b> */}
                                                        {detailEvents.facility}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}</span>
                                    </Stack>
                                </>
                            ) : <span />)

                        }
                    </>

                    {/* <hr
                                    style={{
                                        width: "auto",
                                        opacity: "0.3",
                                        marginTop: "16px",
                                    }}
                                /> */}
                </Stack>

                <Stack direction="row" spacing={2} style={{ marginTop: '8px' }}>

                    <Grid container spacing={1} >
                        <Grid item xs={12} sx={{ margintTop: "25px", display: "flex", justifyContent: "flex-end" }} >

                            <Stack direction="row" spacing={2} sx={{ justifyContent: "end", paddingTop: "7px", paddingBottom: "7px" }}>
                                <OutlinedButton
                                    onClick={onClose}
                                    variant="text"
                                    size="small"
                                >
                                    <DefaultTypography>Close</DefaultTypography>
                                </OutlinedButton>

                            </Stack>
                        </Grid>
                    </Grid>

                </Stack>
                       
</>
                        )}
            </Box>
        </Modal>
    );
};

export default IPEventsDetailsModal;


