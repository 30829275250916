import {
  FormControl,
  CircularProgress,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

const SelectOutlined = ({
  id,
  label,
  value,
  onChange,
  listData,
  isLoading,
  errorText,
  sx,
}) => {
  return (
    <FormControl
      error={!!errorText}
      required
      color="orangeTheme"
      sx={{ minWidth: "294px", maxWidth: "294px" }}
      size="small"
    >
      <InputLabel id={`${id}_label`}>{label}</InputLabel>

      <Select
        defaultValue=""
        required
        value={value}
        disabled={isLoading}
        labelId={`${id}_label`}
        id={id}
        onChange={(e) => onChange(e)}
        label={label}
        sx={sx}
      >
        {isLoading ? (
          <MenuItem value="">
            <CircularProgress size={24} />
          </MenuItem>
        ) : null}
        {listData.map((item) => (
          <MenuItem key={item.id} value={item.value} name={item.id}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
      {/* {errorText ? <FormHelperText>{errorText}</FormHelperText> : null} */}
    </FormControl>
  );
};

export default SelectOutlined;
