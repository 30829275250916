import React, { useState, useEffect } from "react";
import { PagesPath } from "../../PagesPath";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { Box, Button } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ReportPackagePageHeader from "./ReportPackagePageHeader"
import ScorecardComponent from "../../component/common/scorecard/scorecard";
import AIPReachReport from "../ReportPackage/AIPReachReport";
import { checkUserScorecardAccess } from "../../component/common/userAuthorization/checkUserAccess";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import ACOReachReport from "../ReportPackage/ACOReachReport";

const ReportPackagePage = () => {
    const ReportPackagePageID = 73;
    const [value, setValue] = React.useState("0");
    const [accessObj] = useState(checkUserScorecardAccess(ReportPackagePageID));
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box >
            <ReportPackagePageHeader></ReportPackagePageHeader>
            {!accessObj.hasAccess ? (
                <NoAccessPage></NoAccessPage>
            ) : (
                <TabContext value={value}>
                    <div>
                        <Box>
                            <TabList
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#dc4f34",
                                        // top:"46px",
                                        display: "none"
                                    }
                                }}
                                className={"tab-container"}
                                onChange={handleChange}
                            >
                                <Tab
                                    label="CINQ ACO Reach"
                                    value="0"
                                    className={
                                        value === "0"
                                            ? "custom-tab-class Mui-selected"
                                            : "custom-tab-class"
                                    }
                                    style={{
                                        color: "#111827",
                                        opacity: "72%",
                                        textTransform: "math-auto",
                                        fontfamily: "poppins",
                                        fontsize: "16px",
                                        fontweight: "400",
                                    }}
                                />
                                <Tab
                                    label="AIP ACO Reach"
                                    value="1"
                                    className={
                                        value === "1"
                                            ? "custom-tab-class Mui-selected"
                                            : "custom-tab-class"
                                    }
                                    style={{
                                        color: "#111827",
                                        opacity: "72%",
                                        textTransform: "math-auto",
                                        fontfamily: "poppins",
                                        fontsize: "16px",
                                        fontweight: "400",
                                    }}
                                />

                            </TabList>
                        </Box>
                    </div>
                    <TabPanel value="0">
                        <ACOReachReport />

                    </TabPanel>

                    <TabPanel value="1">
                        <AIPReachReport />
                    </TabPanel>

                </TabContext>
            )}
        </Box>

    );
};

export default ReportPackagePage;
