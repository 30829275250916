import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/store";
import "./style.css";
import { HONEY_SVG_TEXT } from "../../constant/homeHoneySvgTextPath";
import HoneyAssetSvg from "../../common/honeyAssetSvg/HoneyAssetSvg";
import { useSelector } from "react-redux";
import { Styles } from "../../../Styles";
import { GetDefaultPagePath, PagesPath } from "../../../PagesPath";
import Loader from "../../../component/common/loader/Loader";
import { getSevenPillarsList } from "../../../features/pillarsToolsScorecards/pillarsToolsScorecardsSlice";
import {
  getFilterDetails,
  getDisplayStatusSection,
  getDisplaySearchGridSection,
  getActiveStatus,
  getSearchTriggered,
  getDisplayBarChartSection,
  getDisplayPieChartSection,
} from "../../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
import { getStatusList } from "../../../features/sdohAssessmentDashboard/slices/SDOHMembersStatusSlice";
import { getMembersList } from "../../../features/sdohAssessmentDashboard/slices/SDOHMembersListSlice";
const SevenPillarMenu = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isSevenPillarListLoading, sevenPillars, sevenPillarErrorMsg } =
    useSelector((state) => state.pillarsToolsScorecard);

  const getSevenPillarsListData = useCallback(async () => {
    await dispatch(getSevenPillarsList());
  }, [dispatch]);

  useLayoutEffect(() => {
    if (sevenPillars === undefined || sevenPillars.length === 0)
      getSevenPillarsListData();
  }, [getSevenPillarsListData]);

  const handleClick = (pillarId) => {
    if (sevenPillars && sevenPillars.length > 0) {
      sevenPillars.map((pillar) => {
        if (pillar.pillarId === pillarId) {
          if (pillar?.defaultPath != null) {
            navigate(GetDefaultPagePath(pillar?.defaultPath));
          }
        }
      });
    }
  };

  const openSDOHDashboardPage = () => {
    const filterDetail = {};
    const activeStatus = {};
    const sdohStatusList = [];
    const sdohMemberList = [];
    var idUrl = PagesPath.SDOHAssessmentDashboard_URL;
    dispatch(getDisplayBarChartSection(false));
    dispatch(getDisplayPieChartSection(false));
    dispatch(getDisplayStatusSection(false));
    dispatch(getDisplaySearchGridSection(false));
    dispatch(getActiveStatus(activeStatus));
    dispatch(getFilterDetails(filterDetail));
    dispatch(getStatusList(sdohStatusList));
    dispatch(getMembersList(sdohMemberList));
    dispatch(getSearchTriggered(false));
    navigate(idUrl, { searchValue: "" });
  };

  const [list, setList] = React.useState([
    "Trends",
    "Same Day visits",
    "Annual Wellness",
    "Timeliness of Care",
    "Reducing No Shows",
  ]);
  return (
    <>
      {/* <div className="main"> */}
      {isSevenPillarListLoading ? (
        <Loader isLoading={isSevenPillarListLoading} />
      ) : (
        <>
          <div className="circle-wrap">
            {/* <div id="radius"> */}
            <div className="inner-circle-wrap">
              <div
                className="inner-circle-wrap-text"
                style={{ textAlign: "left" }}
              >
                <ul
                  style={{
                    color: "#FFF",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "19px",
                    alignItems: "center",
                    verticalAlign: "center",
                    fontSize: "16px",
                    whiteSpace: "pre-line",
                    marginLeft: -60,
                  }}
                >
                  {list.map((name, index) => {
                    return <li key={index}>{name}</li>;
                  })}
                </ul>
              </div>
              {/* </div> */}
            </div>
            <div
              id="honeyAssetSvgOne"
              name="AccessAndAvailability"
              className="icon-background"
              onMouseOver={() =>
                setList([
                  "Trends",
                  "Same Day visits",
                  "Annual Wellness",
                  "Timeliness of Care",
                  "Reducing No Shows",
                ])
              }
            >
              <HoneyAssetSvg svgTextPath={HONEY_SVG_TEXT.HONEY_SVG_ASSET_ONE} />
            </div>
            <Link
              // to={PagesPath.CensusToolMarketHospitalPratice_URL}
              onClick={() => handleClick(2)}
              key="DailyCensus"
            >
              <div
                id="honeyAssetSvgTwo"
                className="icon-background"
                name="AvoidableImpatientStays"
                onMouseOver={() =>
                  setList([
                    "IP Trends",
                    "Daily Inpatient Census",
                    "Tracking Frequent Flyers",
                    "Transitions of Care",
                  ])
                }
              >
                <HoneyAssetSvg
                  svgTextPath={HONEY_SVG_TEXT.HONEY_SVG_ASSET_TWO}
                />
              </div>
            </Link>
            <Link
              // to={PagesPath.CensusToolMarketHospitalPraticeER_URL}
              onClick={() => handleClick(3)}
              key="NonEmergentER"
            >
              <div
                id="honeyAssetSvgThree"
                className="icon-background"
                name="NonEmergentER"
                onMouseOver={() =>
                  setList([
                    "ER Trends",
                    "Daily ER Census",
                    "Follow-ups using \n Notification Alerts",
                    "24x7 Provider Line",
                  ])
                }
              >
                <HoneyAssetSvg
                  svgTextPath={HONEY_SVG_TEXT.HONEY_SVG_PATH_THREE}
                />
              </div>
            </Link>
            <Link
              // to={PagesPath.HighRiskCareExcel_URL} key="HighRisk">
              onClick={() => handleClick(4)}
            >
              <div
                id="honeyAssetSvgFour"
                className="icon-background"
                name="HighRiskFamilyMemberCare"
                onMouseOver={() =>
                  setList([
                    "High & Emerging Risk \n Cohort Registries",
                    " Adverse Event Tracking",
                  ])
                }
              >
                <HoneyAssetSvg
                  svgTextPath={HONEY_SVG_TEXT.HONEY_SVG_PATH_FOUR}
                />
              </div>
            </Link>
            <Link
              // to={PagesPath.CensusToolMarketHospitalPraticeER_URL}
              onClick={() => handleClick(5)}
              key="QualityCodeAndSatisfaction"
            >
              <div
                id="honeyAssetSvgFive"
                className="icon-background"
                name="QualityCodeAndSatisfaction"
                onMouseOver={() =>
                  setList([
                    "Scorecards",
                    "Gaps in Care Closures",
                    "Diagnosis Suspect Assessments",
                    "Satisfaction Surveys",
                  ])
                }
              >
                <HoneyAssetSvg
                  svgTextPath={HONEY_SVG_TEXT.HONEY_SVG_PATH_FIVE}
                />
              </div>
            </Link>

            <Link
              // to={PagesPath.SDOHAssessmentDashboard_URL}
              onClick={() => openSDOHDashboardPage()}
              key="SDOHAssessmentDashboard"
            >
              <div
                id="honeyAssetSvgSix"
                className="icon-background"
                onMouseOver={() =>
                  setList([
                    "Analytics",
                    "SDoH Assessments",
                    "CBO Network Performance",
                    "Fulfillment Tracking",
                  ])
                }
              >
                <HoneyAssetSvg
                  svgTextPath={HONEY_SVG_TEXT.HONEY_SVG_PATH_SIX}
                />
              </div>
            </Link>
            <Link
              // to={PagesPath.VoluntaryAlignmentTracker_URL}
              onClick={() => handleClick(7)}
              key="VoluntaryAlignment"
            >
              <div
                id="honeyAssetSvgSeven"
                className="icon-background"
                name="PracticeVBCARevenueAndGrowth"
                onMouseOver={() =>
                  setList([
                    "Practice Performance \n Scorecards",
                    "Billing Reviews",
                    "Growth and Retention Activities",
                  ])
                }
              >
                <HoneyAssetSvg
                  svgTextPath={HONEY_SVG_TEXT.HONEY_SVG_PATH_SEVEN}
                />
              </div>
            </Link>
          </div>
        </>
      )}
      {/* </div> */}
    </>
  );
};

export default SevenPillarMenu;
