import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import cinqCareLogo from "../../../assets/cinqCareLogo_WhiteText.png";
import Logout from "../logout/Logout";
import SearchBar from "../searchBar/SearchBar";
import UserProfile from "../userProfile/UserProfile";

const ToolbarContent = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }} />
      <SearchBar />
      <Box sx={{ flexGrow: 1 }} />
      <Divider
        sx={{
          margin: 1.5,
          marginRight: 2,
          borderColor: "#D7D7D7",
        }}
        orientation="vertical"
        flexItem
      />
      <UserProfile />
      <Divider
        sx={{
          margin: 1.5,
          color: "#D7D7D7",
          borderColor: "#D7D7D7",
        }}
        orientation="vertical"
        flexItem
      />
      <Logout />
    </>
  );
};

export default ToolbarContent;
