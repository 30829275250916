import React, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    Link,
    Stack,
    Typography,
    Button,
    TextField,
    FormControl, FormHelperText, Tooltip,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/store";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import CustomPaginationSlot from "../../../component/common/datagrid/CustomPaginationSlot";
import Searchbox from "../../../component/common/searchbox/Searchbox";
import { PagesPath } from "../../../PagesPath";
import { alpha, styled } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
    getAdmissionTrendsDetail, getModalFilterDetails
} from "../../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { addDays, addMonths, addWeeks, format, subDays } from "date-fns";
import { COLOR, Styles } from "../../../Styles";
import MemberDetailsModal from "../CreateCohortRegistryManagement/Popup/MemberDetailsModal";
import EventsDetailsModal from "../CreateCohortRegistryManagement/Popup/EventsDetailsModal";
import "../CreateCohortRegistryManagement/CreateCohortManagementcss.css";
import IPEventsDetailsModal from "../CreateCohortRegistryManagement/Popup/IPEventsDetailsModal";
import SChip from "../../../component/common/schip/SChip";

const CreateCohortPreviewPage = (props) => {
    const [sortModel, setSortModel] = useState([{ field: 'memberName', sort: 'asc' }])
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedRows = useSelector(
        (state) => state.filterDetails.selectedRows
    );

    const { cohortDetails, cohortFilterDetails } = useSelector(
        (state) => state.filterDetails
    );

    const [selectedList, setSelectedList] = useState([]);
    const [page, setPage] = useState(1);
    const [searchMemberName, setSearchMemberName] = useState("");
    const [searchMember, setSearchMember] = useState("");

    const [pageCount, setPageCount] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const [popupConfirmationModalOpen, SetPopupConfirmationModalOpen] = useState(false);
    const [popupConfirmationModalData, setPopupConfirmationModalData] = useState({});

    const [totalEvents, setTotalEvents] = useState(0);
    const [totalIPEvents, setTotalIPEvents] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const [payerDtl,setPayerDtl]=useState("");
    const [prodDtl, setProdDtl]=useState("");

    const [ipaFlag, setIpaFlag]=useState("");
    const [eventType, setEventType]=useState("");
    const [riskCategory, setRiskCategory]=useState("");
    useEffect(() => {
        if (selectedRows != undefined && selectedRows.length > 0) {
            let tlEvents = 0;
            let tlIPEvents = 0;
            let tlCost = 0;
            setSelectedList(selectedRows);
            selectedRows.map((detail) => {
                tlEvents += detail?.totalEvents;
                tlIPEvents += detail?.totalIPEvents;
                tlCost += detail?.totalCost;
            })
            setTotalEvents(tlEvents);
            setTotalIPEvents(tlIPEvents);
            setTotalCost(tlCost);
        }
    }, [selectedRows])

    useEffect(() => {
        let obj = [...selectedRows]
        let filtered;
      if (searchMember) {
        filtered = obj.filter(
          (item) =>
            item.memberName.toLowerCase().includes(searchMember.toLowerCase())
        );
        setSelectedList(filtered);
      } else {
        setSelectedList(selectedRows);
      }
    }, [searchMember])

    // useEffect(() => {
    //     console.log("cohortDetails", cohortDetails);
    // }, [cohortDetails])

    useEffect(() => {
        
        if (cohortFilterDetails && cohortFilterDetails?.payer != undefined) {
            var payIDS;
            var payIDS = cohortFilterDetails?.payer.map((item) => item.payerName);
            var payNames = payIDS.join(",");
            setPayerDtl(payNames);

            var prodIDS;
            var prodIDS = cohortFilterDetails?.product.map((item) => item.productName);
            var prodNames = prodIDS.join(",");
            setProdDtl(prodNames);

            setIpaFlag(cohortFilterDetails?.ipaFlag);

            setEventType(cohortFilterDetails?.eventType);

            setRiskCategory(cohortFilterDetails?.riskCategory);

        }
    }, [cohortFilterDetails])

    const openMemberDetailsModal = (memberDtl) => {
        setPopupConfirmationModalData(memberDtl);
        dispatch(getAdmissionTrendsDetail({
            startDate : format(cohortFilterDetails?.eventStartDate, "yyyy-MM-dd"),
            endDate : format(cohortFilterDetails?.eventEndDate, "yyyy-MM-dd")
        }));

        let ipaFlags = ipaFlag.map((item) => item.ipaFlag)
        let evtTypes = eventType.map((item) => item.eventType)
        let riskCats = riskCategory.map((item) => item.riskCategory)
        dispatch(getModalFilterDetails({
                ipaFlags:ipaFlags.join(","),
                riskCats:riskCats.join(","),
                evtTy:evtTypes.join(",")
        }));
        SetPopupConfirmationModalOpen(true);
    }

    const closePopupModal = () => {
        SetPopupConfirmationModalOpen(false);
    }

    const [viewEventsPopupModalOpen, SetViewEventsPopupModalOpen] = useState(false);

    const viewEventsDetails = (memberDtl) => {
        dispatch(getAdmissionTrendsDetail({
            startDate : format(cohortFilterDetails?.eventStartDate, "yyyy-MM-dd"),
            endDate : format(cohortFilterDetails?.eventEndDate, "yyyy-MM-dd")
        }));

        let ipaFlags = ipaFlag.map((item) => item.ipaFlag)
        let evtTypes = eventType.map((item) => item.eventType)
        let riskCats = riskCategory.map((item) => item.riskCategory)
        dispatch(getModalFilterDetails({
                ipaFlags:ipaFlags.join(","),
                riskCats:riskCats.join(","),
                evtTy:evtTypes.join(",")
        }));
        setPopupConfirmationModalData(memberDtl);
        SetViewEventsPopupModalOpen(true);
    }

    const [ipPopupConfirmationModalOpen, SetIpPopupConfirmationModalOpen] = useState(false);
    const [ipPopupConfirmationModalData, setIpPopupConfirmationModalData] = useState({});

    const [viewIpEventsPopupModalOpen, SetViewIpEventsPopupModalOpen] = useState(false);
    
    const viewIPEventsDetails = (memberDtl) => {
        
        dispatch(getAdmissionTrendsDetail({
            startDate : format(cohortFilterDetails?.eventStartDate, "yyyy-MM-dd"),
            endDate : format(cohortFilterDetails?.eventEndDate, "yyyy-MM-dd")
        }));
        setIpPopupConfirmationModalData(memberDtl);

        let ipaFlags = ipaFlag.map((item) => item.ipaFlag)
        let evtTypes = eventType.map((item) => item.eventType)
        let riskCats = riskCategory.map((item) => item.riskCategory)
        dispatch(getModalFilterDetails({
                ipaFlags:ipaFlags.join(","),
                riskCats:riskCats.join(","),
                evtTy:evtTypes.join(",")
        }));
        SetViewIpEventsPopupModalOpen(true);
    }

    const closeIpEventsPopupModal = () => {
        SetViewIpEventsPopupModalOpen(false);
    }

    const closeEventsPopupModal = () => {
        SetViewEventsPopupModalOpen(false);
    }

    const onClose = () => {
        props.handleCancelCall();
    }

    const handleBack = (e) => {
        let hasError = false;
        props.handleBackCall();
    }

    const handleSave = (e) => {
        let hasError = false;
        var
            payerIDs,
            prodIDs,
            marketIDs,
            subMarketIDs,
            practiceIDs,
            riskCategoryNames,
            ipaFlagNames,
            eventTypeNames;
        if (cohortFilterDetails && cohortFilterDetails?.payer != undefined) {

            var payIDs = cohortFilterDetails?.payer.map((item) => item.payerId);
            var payers;
            if (payIDs && payIDs.length > 0 && payIDs[0] != undefined)
                payers = payIDs;

            var prodIDs = cohortFilterDetails?.product.map((item) => item.productId);
            var prods;
            if (prodIDs && prodIDs.length > 0 && prodIDs[0] != undefined)
                prods = prodIDs;

            var mIDs = cohortFilterDetails?.market.map((item) => item.marketId);
            var markets;
            if (mIDs && mIDs.length > 0 && mIDs[0] != undefined)
                markets = mIDs;

            var smIDs = cohortFilterDetails?.subMarket.map((item) => item.id);
            var submarkets;
            if (smIDs && smIDs.length > 0 && smIDs[0] != undefined)
                submarkets = smIDs;

            var pIDs = cohortFilterDetails?.practice.map((item) => item.practiceId);
            var practs;
            if (pIDs && pIDs.length > 0 && pIDs[0] != undefined)
                practs = pIDs;

            var riskCategoryNamesArr = cohortFilterDetails?.riskCategory.map((item) => item.riskCategory);

            var ipaFlagNamesArr = cohortFilterDetails?.ipaFlag.map((item) => item.ipaFlag);

            var eventTypeNamesArr = cohortFilterDetails?.eventType.map((item) => item.eventType);

            //end of search functionality

            payerIDs = payers.join(",");
            prodIDs = prods.join(",");
            // marketIDs = markets.join(",");
            subMarketIDs = submarkets.join(",");
            practiceIDs = practs.join(",");
            riskCategoryNames = riskCategoryNamesArr.join(",");
            ipaFlagNames = ipaFlagNamesArr.join(",");
            eventTypeNames = eventTypeNamesArr.join(",");
            // const filterDtl={
            //     payerId:payerIDs,
            //     productId:prodIDs,
            //     regionId:subMarketIDs,
            //     practiceId:practiceIDs,
            //     riskCategory:riskCategoryNames,
            //     ipaFlag:ipaFlagNames,
            //     eventType:eventTypeNames,
            //     dateRange:cohortFilterDetails?.EventYear,
            //     fromDate:cohortFilterDetails?.eventStartDate,
            //     toDate:cohortFilterDetails?.eventEndDate,
            //     podName: cohortDetails?.podName,
            //     cohortName: cohortDetails?.cohortName,
            //     description: cohortDetails?.description,
            //     cohortOwner: cohortDetails?.cohortOwner,
            //     startDate: cohortDetails?.customStartDate,
            // }
            
            if (selectedList && selectedList.length>0){
                let obj=[];
                let memJSON={};
                selectedList.map((member)=>{
                    
                    memJSON=
                    {
                        id: member?.id,
                        ourId: member?.ourId,
                        firstName: member?.firstName,
                        lastName: member?.lastName,
                        memberName: member?.memberName,
                        insuranceId: member?.insuranceId,
                        name: member?.name,
                        memberDateOfBirth: format(new Date(member?.memberDateOfBirth), "yyyy-MM-dd"),
                        practiceName: member?.practiceName,
                        region: member?.region,
                        payer: member?.payer,
                        chronicCondition: member?.chronicCondition,
                        totalEvents: member?.totalEvents,
                        totalIPEvents: member?.totalIPEvents,
                        totalCost: member?.totalCost,
                        currentPODName: member?.currentPODName,
                        currentCohortName: member?.currentCohortName,
                        isSelected: member?.isSelected
                    }
                    obj.push(memJSON);
                });
                const formData = {
                    cohortId: "0",
                    payerId: payerIDs,
                    productId: prodIDs,
                    regionId: subMarketIDs,
                    practiceId: practiceIDs,
                    riskCategory: riskCategoryNames,
                    ipaFlag: ipaFlagNames,
                    eventType: eventTypeNames,
                    customDateRange : cohortFilterDetails?.customDateRange,
                    dateRange: cohortFilterDetails?.dateRange,//cohortFilterDetails?.EventYear, //2004,//
                    fromDate: format(new Date(cohortFilterDetails?.eventStartDate), "yyyy-MM-dd"),//cohortFilterDetails?.eventStartDate,// != null ? cohortFilterDetails?.eventStartDate : "",
                    toDate: format(new Date(cohortFilterDetails?.eventEndDate), "yyyy-MM-dd"),//cohortFilterDetails?.eventEndDate,// != null ? cohortFilterDetails?.eventEndDate : "",
                    podName: cohortDetails?.podName,
                    cohortName: cohortDetails?.cohortName,
                    description: cohortDetails?.description,
                    // cohortOwner: cohortDetails?.cohortOwner,
                    startDate: format(new Date(cohortDetails?.customStartDate), "yyyy-MM-dd"),//cohortDetails?.customStartDate,
                    userId: cohortDetails?.cohortOwnerId,
                    AuditStatus: "",
                    cohortMemberDetails: obj//selectedList
                };
                axiosRequests.post("/Cohort/AddCohortMemberDetails", formData).then((resp) => {
                    // console.log("resp",resp);
                    if (resp === 1) {
                        var idUrl = PagesPath.ViewCohortRegistry_URL;
                        navigate(idUrl);
                    }
                });
            }
        }
    }

    const handleAddToCohort= () =>{

    }

    function formatToCostPrice(number) {
        // Convert number to string
        if (number != undefined){
            const numberStr = number.toString();
      
            // Match digits and decimal point using regex
            const matches = numberStr.match(/^(\d+)(?:\.(\d+))?$/);
          
            if (matches) {
              // Get the integer part and fractional part (if exists)
              const integerPart = (+matches[1]).toLocaleString();
              const fractionalPart = matches[2] ? matches[2].padEnd(2, '0') : '00';
          
              // Format the number in account balance format -- ₹
              const formattedBalance = `$ ${integerPart}`;//.${fractionalPart}
              return formattedBalance;
            } else {
              return "Invalid input";
            }
        }
        
      }

    const columns = [
        {
            field: "memberName",
            headerName: "Name",
            flex: 1.6,
            sortable: false,
        },
        {
            field: "insuranceId",
            headerName: "Insurance ID",
            headerClassName: "classMultilineHeader",
            flex: 1.6,
            sortable: false,
        },
        {
            field: "region",
            headerName: "Region",
            flex: 1.6,
            sortable: false,
        },
        {
            field: "payer",
            headerName: "Payer",
            flex: 1.6,
            sortable: false,
        },
        {
            field: "practiceName",
            headerName: "Practice",
            flex: 1.6,
            sortable: false,
        },
        {
            field: "memberDateOfBirth",
            headerName: "DOB",
            type: "Date",
            flex: 1.6,
            sortable: false,
            renderCell: (params) =>
                params.row.memberDateOfBirth &&
                format(new Date(params.row.memberDateOfBirth), "dd MMM yyyy"),
        },
        {
            field: "chronicCondition",
            headerName: "Chronic Condition",
            headerClassName: "classMultilineHeader",
            type: "number",
            sortable: false,
            flex: 1.4,
        },
        {
            field: "totalEvents",
            headerName: "Total Events",
            headerClassName: "classMultilineHeader",
            type: "number",
            sortable: false,
            flex: 1.2,
            renderCell: (params) => {
                return (
                    //   <div style={{display:"flex", alignItems:"center"}}>
                    <Link
                        style={{
                            textDecoration: "none",
                            color: "inherit",
                        }}
                    >
                        <div
                            onClick={() =>
                                viewEventsDetails(params.row)
                            }
                        >
                            <u>{params.row.totalEvents}</u>
                        </div>
                    </Link>
                    //   </div>
                );
            },
        },
        {
            field: "totalIPEvents",
            headerName: "Total IP Events",
            headerClassName: "classMultilineHeader",
            type: "number",
            flex: 1.2,
            renderCell: (params) => {
                if (params.row.totalIPEvents != 0)
                return (
                    //   <div style={{display:"flex", alignItems:"center"}}>
                    <Link
                        style={{
                            textDecoration: "none",
                            color: "inherit",
                        }}
                    >
                        <div
                            onClick={() =>
                                viewIPEventsDetails(params.row)
                            }
                        >
                            <u>{params.row.totalIPEvents}</u>
                        </div>
                    </Link>
                    //   </div>
                );
            
            },
        },
        {
            field: "totalCost",
            type: "number",
            headerName: "Total Cost",
            headerClassName: "classMultilineHeader",
            flex: 1.8,
            renderCell: (params) =>
                params.row.totalCost &&
                formatToCostPrice(params.row.totalCost),
        },

        {
            field: "action",
            headerName: "Action",
            flex: 1.2,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div style={{ display: "flex", alignItems: "center", marginTop: "7px", marginLeft: "10px" }}>
                        <Tooltip title="Show Member Details">
                            <div
                                // onClick={() => {
                                // handleNewSDOHAssessmentClick(
                                //     params.row.ourId,
                                //     params.row.sdohSubmittedDate,
                                //     params.row.memberRiskScore,
                                //     params.row.practiceName,
                                //     params.row.providerName
                                // );
                                // setIsNewAssessment(false);
                                // }}
                                onClick={() => openMemberDetailsModal(params.row)}
                            >
                                <VisibilityOutlinedIcon />
                            </div>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    function getRowId(row) {
        return row.id;
    }

    const OutlinedButton = styled(Button)(() => ({
        borderRadius: "4px",
        padding: "6px",
        justifyContent: "center",
        color: COLOR.BLACK,
        borderColor: COLOR.BLACK,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "capitalize",
    }));

    const ContainedButton = styled(Button)(() => ({
        variant: "contained",
        borderRadius: "4px",
        padding: "6px",
        background: COLOR.PRIMARY_ORANGE,
        justifyContent: "center",
        color: COLOR.WHITE,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "none",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
        ":hover": {
            background: COLOR.SECONDARY_ORANGE,
        },
    }));

    const DefaultTypography = styled(Typography)({
        color: "#000",
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    });

    return (
        <Box sx={{
            paddingTop: 2, width: "100%",
            display: "flex", justifyContent: "center"
        }}>
            <Grid container sx={{
                paddingTop: 2,
                minHeight: "100%",
                // marginLeft: "1%",
                boxShadow: "0px 3px 46px 0px rgba(0, 0, 0, 0.12)",
                border: "1px solid rgba(0,0,0,.12)",
                backgroundColor: "white",
            }} >
                <Grid item xs={12} >
                    <Stack direction="column" spacing={1} sx={{ paddingLeft: "25px", paddingRight: "25px" }}
                    >
                        <Grid container >
                            <Grid item xs={7} >
                                <Stack direction="column" spacing={1} sx={{ paddingRight: "25px" }}
                                >
                                    <TextField
                                        style={{
                                            width: "auto !important",
                                        }}
                                        className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                                        id="outlined-multiline-static"
                                        label="POD Name"
                                        value={cohortDetails?.podName}
                                        // disabled={props.isDisabledMode}
                                        // onChange={(e) => handlePodNameChange(e)}
                                        multiline
                                        // className="denyComments"
                                        color="orangeTheme"
                                        rows={1}
                                        variant="outlined"
                                    />
                                    <TextField
                                        style={{
                                            width: "auto !important",
                                        }}

                                        id="outlined-multiline-static"
                                        label="Cohort Name"
                                        value={cohortDetails?.cohortName}
                                        // disabled={props.isDisabledMode}
                                        multiline
                                        // className="denyComments"
                                        className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                                        color="orangeTheme"
                                        rows={1}
                                        variant="outlined"
                                    />
                                    <TextField
                                        style={{
                                            width: "auto !important",
                                        }}
                                        id="outlined-multiline-static"
                                        label="Description"
                                        value={cohortDetails?.description}
                                        className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                                        // disabled={props.isDisabledMode}
                                        multiline
                                        // className="denyComments"
                                        color="orangeTheme"
                                        rows={1}
                                        variant="outlined"
                                    />

                                    <TextField
                                        style={{
                                            width: "auto !important",
                                        }}
                                        id="outlined-multiline-static"
                                        label="Cohort Owner"
                                        value={cohortDetails?.cohortOwner}
                                        // disabled={props.isDisabledMode}
                                        multiline
                                        // className="denyComments"
                                        className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                                        color="orangeTheme"
                                        rows={1}
                                        variant="outlined"
                                    />

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <FormControl
                                            // className="practiceNameClass"
                                            className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                                            // disabled={props.isDisabledMode}
                                            color="orangeTheme"
                                            sx={{
                                                maxWidth: "180px",
                                                minWidth: "160px",
                                                backgroundColor: "#fff",
                                                borderRadius: "6px",
                                            }}
                                            size="small"
                                        >
                                            <DatePicker
                                                label="Start Date"
                                                value={cohortDetails?.customStartDate}
                                                color="orangeTheme"
                                                slotProps={{
                                                    textField: {
                                                        className: "dateFieldClass",
                                                        color: "orangeTheme",
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                    </LocalizationProvider>

                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                <Stack direction="column" spacing={1} sx={{
                                    border: "1px solid #FFDFC3",
                                    backgroundColor: "white",
                                    padding: "15px",
                                    width: "95%",
                                }}>
                                    <h3
                                        style={{
                                            fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px",
                                            color: "#DC4F34"
                                        }}
                                    >
                                        Cohort Summary
                                    </h3>

                                    <Typography style={{
                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                        // color: "#DC4F34"
                                    }}>
                                        Total Events : &nbsp;
                                        {totalEvents}
                                        {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                    </Typography>

                                    <Typography style={{
                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                        // color: "#DC4F34"
                                    }}>
                                        Total IP Days : &nbsp;
                                        {totalIPEvents}
                                        {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                    </Typography>

                                    <Typography style={{
                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                    }}>
                                        Total Cost : &nbsp;
                                        {formatToCostPrice(totalCost)}
                                        {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                <Stack direction="column" spacing={1} sx={{
                                    // border: "1px solid #FFDFC3",
                                    // backgroundColor: "white",
                                    padding: "15px",
                                    width: "95%",
                                }}>
                                    <h3
                                        style={{
                                            fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px",
                                        }}
                                    >
                                        Filters
                                    </h3>

                                    <Typography style={{
                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                    }}>
                                        Event Year : &nbsp;
                                        {cohortFilterDetails?.EventYear},

                                        {/* {totalEvents} */}
                                        {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                    </Typography>

                                    <Typography style={{
                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                    }}>
                                        Payer : &nbsp;
                                        {payerDtl}
                                        {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                    </Typography>

                                    <Typography style={{
                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                    }}>
                                        Product : &nbsp;
                                        {prodDtl}
                                        {/* {rowData.totalEvents ? rowData.totalEvents : "-"} */}
                                    </Typography>

                                    <h3
                                        style={{
                                            fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px",
                                        }}
                                    >
                                        Date Range
                                    </h3>
                                    <Stack direction="row" spacing={1} sx={{
                                        // border: "1px solid #FFDFC3",
                                        // backgroundColor: "white",
                                        width: "95%",
                                    }}>
                                        <Typography style={{
                                            fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                        }}>
                                            From Date : &nbsp;
                                            {cohortFilterDetails?.eventStartDate}
                                        </Typography>

                                        <Typography style={{
                                            fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                        }}>
                                            To Date : &nbsp;
                                            {cohortFilterDetails?.eventEndDate}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                <Stack direction="column" spacing={2}>
                                    <div style={{ display: "grid" }}>
                                        <>
                                            <DataGrid
                                                //   hideFooter={hideFooter}
                                                sx={{
                                                    marginTop: 2,
                                                    "& .MuiDataGrid-columnHeader": {
                                                        backgroundColor: "#F2F2F2",
                                                        color: "#11182799",
                                                    },
                                                    "& .MuiDataGrid-overlayWrapper": {
                                                        height: "34px !important",
                                                    },
                                                    "& .MuiDataGrid-virtualScroller": {
                                                        overflow: "visible !important"
                                                    },
                                                    "& .MuiDataGrid-selectedRowCount":{
                                                        display: "none"
                                                    },
                                                    "& .MuiDataGrid-footerContainer":{
                                                        display: "none"
                                                    }
                                                }}
                                                rows={selectedList}
                                                className="cohortGrid"
                                                columns={columns}
                                                // loading={isStatusLoading}
                                                initialState={{
                                                    pagination: {
                                                        setPage: setPage,
                                                        paginationModel: { count: pageCount, page: page, totalRows: totalRows },
                                                        colorTheme: 'orangeTheme',
                                                        className: 'pageNumber'
                                                    }
                                                }}
                                                pagination
                                                // apiRef={apiRef}
                                                sortModel={sortModel}
                                                pageSizeOptions={[10]}
                                                // onCellClick={handleCellClick}

                                                getRowId={getRowId}
                                                sortingOrder={['asc', 'desc']}
                                                sortingMode="server"
                                                  disableColumnMenu={true}
                                                // onSortModelChange={(e) => handleSortModelChange(e)}
                                                //   onPageChange={onPageChange}
                                                slots={{
                                                    pagination: CustomPaginationSlot,
                                                    toolbar: DataGridHeader,
                                                }}
                                                slotProps={{
                                                    toolbar: {
                                                        headerText: "Member Details",
                                                        fontSize: "16px",
                                                        marginTop: "auto",
                                                        marginBottom: "auto",
                                                        height: "56px",
                                                        children: (
                                                            <>
                                                                <SChip label={selectedList.length}></SChip>
                                                                <div
                                                                    style={{
                                                                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                        fontSize: "16px",
                                                                        marginTop: "auto",
                                                                        marginBottom: "auto",
                                                                        marginLeft: "auto",
                                                                    }}
                                                                >
                                                                    <Searchbox
                                                                        defaultValue={searchMemberName}
                                                                        handleChange={(e) => {
                                                                            setSearchMemberName(e.target.value);
                                                                        }}
                                                                        onClick={() => {
                                                                            setPage(1);
                                                                            setSearchMember(searchMemberName);
                                                                        }}
                                                                        label="Search Member"
                                                                    ></Searchbox>
                                                                </div>
                                                            </>
                                                        ),
                                                    },
                                                    pagination: {
                                                        setPage: setPage,
                                                        paginationModel: { count: pageCount, page: page, totalRows: totalRows },
                                                        colorTheme: 'orangeTheme',
                                                        className: 'pageNumber'
                                                    }
                                                }}
                                                isRowSelectable={false}
                                            />
                                        </>
                                    </div>
                                    <Grid container spacing={1} >
                                        <Grid item xs={12} sx={{ margintTop: "25px", display: "flex", justifyContent: "flex-end" }} >
                                            {/* <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={onClose}>Cancel</Button>
                                                <Button style={{ color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="contained" color="orangeTheme" disableElevation onClick={handleNext}>Next</Button> */}
                                            {/* <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={handleBack}>Back</Button> */}

                                            <Stack direction="row" spacing={2} sx={{
                                                justifyContent: "end", paddingTop: "7px",
                                                paddingBottom: "7px", paddingRight: "10px"
                                            }}>
                                                <OutlinedButton
                                                    onClick={() => {
                                                        onClose()
                                                    }}
                                                    variant="text"
                                                    size="small"
                                                >
                                                    <DefaultTypography>Cancel</DefaultTypography>
                                                </OutlinedButton>

                                                <Button style={{
                                                    variant: "outlined",
                                                    borderColor: COLOR.ORANGE,
                                                    borderRadius: "4px",
                                                    padding: "6px",
                                                    justifyContent: "center",
                                                    border: "1px solid #DC4F34",
                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                    fontStyle: "normal",
                                                    textTransform: "none",
                                                    boxShadow:
                                                        "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                                                    ":hover": {
                                                    },
                                                }} onClick={handleBack}><DefaultTypography style={{ color: COLOR.ORANGE, }}>Back</DefaultTypography></Button>

                                                <Button style={{
                                                    color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                }}
                                                    variant="contained" color="orangeTheme" disableElevation onClick={handleSave}>Save</Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
            <MemberDetailsModal
                isOpen={popupConfirmationModalOpen}
                onClose={closePopupModal}
                handleAddToCohort={() => {
                    handleAddToCohort(popupConfirmationModalData.id)
                }}
                rowData={popupConfirmationModalData}
                ipaFlags = {ipaFlag}
                riskCats= {riskCategory}
                evtTy= {eventType}
                Preview={1}
            ></MemberDetailsModal>

            <EventsDetailsModal
                isOpen={viewEventsPopupModalOpen}
                onClose={closeEventsPopupModal}
                rowData={popupConfirmationModalData}
                ipaFlags = {ipaFlag}
                riskCats= {riskCategory}
                evtTy= {eventType}
                Preview={1}
            ></EventsDetailsModal>

            <IPEventsDetailsModal
                isOpen={viewIpEventsPopupModalOpen}
                onClose={closeIpEventsPopupModal}
                rowData={ipPopupConfirmationModalData}
                ipaFlags = {ipaFlag}
                riskCats= {riskCategory}
                evtTy= {eventType}
                Preview={1}
            ></IPEventsDetailsModal>
        </Box>
    );
};


export default CreateCohortPreviewPage;

