import React, {useState, useEffect} from "react";
import { Box, Grid, Button, Modal, Stack, Typography, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/store";
import { Styles } from "../../Styles";
import { axiosRequests } from "../../app/api/AxiosRequest";

import { getNewEmailAddress } from "../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
  const EmailNotificationModal = ({ isOpen, onClose, rowData, message, isSentButtonDisplay, memberName, handleEmailSend}) => {
  const dispatch = useAppDispatch();
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
  const [emailAddress, setEmailAddress] = useState("");
  const [emailError, setEmailError] = React.useState("");
  const [isSentEnable, setIsSentEnable]=useState(false);

  const { newEmailAddress } = useSelector(
    (state) => state.filterDetails
  );

  useEffect(()=>{
    // if(newEmailAddress != "")
      setEmailAddress(newEmailAddress);
  },[newEmailAddress])

  const handleEmailChange = (e) => {

    let email = e.target.value;
    setEmailAddress(email);
    setEmailError("");
    let hasError = false;
    if (email == ''){
      setIsSentEnable(false);
      // dispatch(getNewEmailAddress(""));
    }
    else{
      if (!emailRegex.test(email)) {
        setEmailError("Email is Invalid");
        hasError = true;
        setIsSentEnable(false);
      }
      else{
        setIsSentEnable(true);
        // dispatch(getNewEmailAddress(emailAddress));
      }
    }
    
  };

  const closePopup = () =>{
    dispatch(getNewEmailAddress(emailAddress));
    setEmailError("");
    setEmailAddress("");
    onClose();
  }

  const handleEmailSent=()=>{
    dispatch(getNewEmailAddress(emailAddress));
      const formData = {
        OurId: rowData.ourId,
        oldEmailAddress: '',
        EmailAddress: emailAddress,
      };

      axiosRequests.post(`/SDOH/UpdateSDOHMemberEmailAddress`, formData).then((resp) => {
          handleEmailSend();
      })
  }
    if (!rowData) return;
    return (
      <Modal
        open={isOpen}
        onClose={closePopup}
        slotProps={{ backdrop: { onClick: null } }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: "578px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 1,
            height: "auto",
          }}
        >
          <h3
            style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
          >
            Information
            <span>
              <CloseIcon onClick={closePopup} style={{ float: "right" }} />
            </span>
          </h3>
          <hr
            style={{
              width: "auto",
              opacity: "0.3",
              marginTop: "-11px",
              marginBottom: "5px",
            }}
          />
          <Stack direction="column" sx={{ marginTop: '23px' }} spacing={2}>
            <Typography style={{ fontFamily: Styles.FONT_FAMILY_POPPINS,fontWeight: 500 }}>
              {message}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={9}
              sx={{display: (isSentButtonDisplay === false ) ? "" : "none"}}>
                <TextField
                  required
                  label="Email"
                  id="outlined-size-small"
                  onChange={handleEmailChange}
                  helperText={emailError}
                  error={!!emailError}
                  value={emailAddress}
                  style={{
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    width: "100%",
                    height: "39px",
                    borderRadius: "6px",
                    backgroundColor: "#fff",
                  }}
                  color="orangeTheme"
                  size="small"
                />
              </Grid>
            </Grid>
          </Stack>
          <hr
                style={{
                  width: "auto",
                  opacity: "0.3",
                  marginTop: "16px",
                }}
              />
              <Stack direction="row" spacing={2} style={{ marginTop:'8px' }}>
                <Button
                  style={{
                    textTransform: "none",
                    borderColor: "black",
                    color: "black",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    marginLeft: "auto", 
                  }}
                  variant="outlined"
                  onClick={closePopup}
                >
                  Close
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    color: "white",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    display: (isSentButtonDisplay === true || isSentEnable === true) ? "" : "none"
                  }}
                  variant="contained"
                  color="orangeTheme"
                  disableElevation
                  onClick={handleEmailSent}
                >
                  Send
                </Button>
              </Stack>
        </Box>
      </Modal>
    );
};

export default EmailNotificationModal;
