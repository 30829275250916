import { useMsal } from "@azure/msal-react";
import { Close } from "@mui/icons-material";
import { Box, Button, Modal, Stack } from "@mui/material";
import { format } from "date-fns";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Styles } from "../../Styles";
import { DerosteringTrackingAgent } from "../../app/api/derosteringTracking/DerosteringTrackingAgent";
import { useAppDispatch } from "../../app/store";
import cinq_1 from "../../assets/cinq_1.png";
import DatePickerOutlined from "../../component/common/datepicker/DatePickerOutlined";
import SelectOutlined from "../../component/common/select/SelectOutlined";
import MultilineTextFieldOutlined from "../../component/common/textField/MultilineTextFieldOutlined";
import TextFieldOutlined from "../../component/common/textField/TextFieldOutlined";
import { getDerosteringReasons } from "./payorContractTypeDerosterReasonsSlice";

const DerosteringTrackingUpdateDeroster = ({
  isOpen,
  onClose,
  rowData,
  derosterMember = undefined,
  setDerosterMember = undefined,
}) => {
  const { accounts } = useMsal();

  const dispatch = useAppDispatch();
  const { isLoading, derosteringReasons, errorMessage } = useSelector(
    (state) => state.derosteringReasons
  );

  const getDerosteringReasonsData = useCallback(async () => {
    await dispatch(
      getDerosteringReasons({
        // payorId: rowData ? rowData.payorId : 0,
        payorId: 0,
        payorContractType: rowData ? rowData.payorContractType : "",
      })
    );
  }, [rowData, dispatch]);

  useEffect(() => {
    setDerosterReasonId("");
    setDerosterDate(new Date());
    setEffectiveDate(new Date());
    setDerosterComments("");

    setDerosterDateErrorMessage("");
    setEffectiveDateErrorMessage("");
    setDerosterReasonIdErrorMessage("");
    setDerosterCommentsErrorMessage("");
    if (isOpen === true) getDerosteringReasonsData();
  }, [isOpen, getDerosteringReasonsData]);

  const [payorId] = React.useState(rowData ? rowData.payorId : 0);
  const [payorContractType] = React.useState(
    rowData ? rowData.payorContractType : ""
  );
  const [derosterDate, setDerosterDate] = React.useState(new Date());
  const [derosterDateErrorMessage, setDerosterDateErrorMessage] =
    React.useState("");

  const [effectiveDate, setEffectiveDate] = React.useState(new Date());
  const [effectiveDateErrorMessage, setEffectiveDateErrorMessage] =
    React.useState("");

  const [derosterReasonId, setDerosterReasonId] = React.useState("");
  const [derosterReasonIdErrorMessage, setDerosterReasonIdErrorMessage] =
    React.useState("");

  const [derosterComments, setDerosterComments] = React.useState("");
  const [derosterCommentsErrorMessage, setDerosterCommentsErrorMessage] =
    React.useState("");
  if (!rowData) return null;
  // else console.log(rowData);

  const handleDatePickerError = (e) => {
    if (e) setDerosterDateErrorMessage("De-roster Date is Required");
    else setDerosterDateErrorMessage("");
  };

  const handleEffectiveDatePickerError = (e) => {
    if (e) setEffectiveDateErrorMessage("Effective Date is Required");
    else setEffectiveDateErrorMessage("");
  };

  const handleSubmitForm = (e) => {
    let hasError = false;
    if (derosterDateErrorMessage !== "") hasError = true;
    if (effectiveDateErrorMessage !== "") hasError = true;
    setDerosterReasonIdErrorMessage("");
    setDerosterCommentsErrorMessage("");

    if (!derosterReasonId) {
      setDerosterReasonIdErrorMessage("De-roster Reason is Required");
      hasError = true;
    }

    if (!derosterComments) {
      setDerosterCommentsErrorMessage("De-roster Comments is required");
      hasError = true;
    }

    if (!effectiveDate) {
      setEffectiveDateErrorMessage("Effective Date is Required");
      hasError = true;
    }

    if (hasError) return;

    const formData = {
      OurId: rowData.ourID,
      enrollmentEndDate: format(derosterDate, "yyyy-MM-dd"),
      submittedBy: accounts[0].name,
      comments: derosterComments,
      effectiverequestdate: format(effectiveDate, "yyyy-MM-dd"),
      derosteringReason: derosterReasonId,
      derosteringStatus: parseInt(1),
    };

    DerosteringTrackingAgent.updateDerosteringTracking(formData)
      .then((resp) => {
        if (resp === 1) {
          if (!setDerosterMember) {
            window.location.reload();
          } else {
            setDerosterMember(true);
            onClose();
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{
        backdrop: { onClick: null },
        // root: { height: "auto", overflowY: "visible" },
      }}
      sx={{ height: "auto", overflowY: "auto" }}
    >
      {/* <div> */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "678px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
          // overflowY: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Confirmation of Member De-rostering
          <span>
            <Close onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "622px",
            opacity: "0.3",
            marginTop: "-8px",
            // marginBottom: "-4px",
          }}
        />
        {/* <ModalClose variant="plain" sx={{ m: 1 }} /> */}
        <img
          style={{
            width: "129px",
            height: "29px",
            margin: "0 auto",
            marginTop: "15px",
            display: "block",
          }}
          src={cinq_1}
        ></img>

        <Stack spacing={3} sx={{ marginTop: "32px" }}>
          {!!rowData.derosteringStatus ? (
            <p
              style={{
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
            >
              Status: <b>“{rowData.derosteringStatus}”</b>
            </p>
          ) : (
            <></>
          )}

          <Stack direction="row" spacing={3}>
            <DatePickerOutlined
              readOnly={true}
              handleError={(error) => handleDatePickerError(error)}
              disableFuture={true}
              className="datepickeroutline"
              required
              label="Identification Date"
              value={derosterDate}
              helperText={derosterDateErrorMessage}
              onChange={(newValue) => setDerosterDate(newValue)}
              sx={{
                minWidth: "294px",
                maxWidth: "294px",
                width: "250px",
                //   height: "34px",
              }}
            />
            <DatePickerOutlined
              handleError={(error) => handleEffectiveDatePickerError(error)}
              disableFuture={false}
              className="datepickeroutline"
              required
              label="Effective Date"
              value={effectiveDate}
              helperText={effectiveDateErrorMessage}
              onChange={(newValue) => setEffectiveDate(newValue)}
              sx={{
                minWidth: "294px",
                maxWidth: "294px",
                width: "250px",
                //   height: "34px",
              }}
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <TextFieldOutlined
              handleChange=""
              label="Payer Name"
              defaultValue={rowData.payorName}
              id="outlined-size-small"
              readOnly={true}
              sx={{
                minWidth: "294px",
                maxWidth: "294px",
                width: "250px",
                //   height: "34px",
              }}
            />
            <TextFieldOutlined
              label="Payer Contract Type"
              defaultValue={rowData.payorContractType}
              readOnly={true}
              id="outlined-size-small"
              sx={{
                minWidth: "294px",
                maxWidth: "294px",
                width: "250px",
                //   height: "34px",
              }}
              handleChange=""
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <SelectOutlined
              isLoading={isLoading}
              id="reason-for-de-roster"
              label="Reason for de-rostering"
              value={derosterReasonId}
              errorText={derosterReasonIdErrorMessage}
              onChange={(newValue) => {
                console.log(newValue);
                setDerosterReasonId(newValue.target.value);
                setDerosterReasonIdErrorMessage("");
              }}
              listData={derosteringReasons.map((data) => ({
                id: data.derosteringReasonId,
                value: data.reasonDescription,
              }))}
              sx={{ width: "610px" }}
            />
          </Stack>
          <MultilineTextFieldOutlined
            label="Comments"
            rows={4}
            helperText={derosterCommentsErrorMessage}
            value={derosterComments}
            onChange={(e) => {
              setDerosterComments(e.target.value);
              setDerosterCommentsErrorMessage("");
            }}
          />
          <hr
            style={{
              width: "622px",
              opacity: "0.3",
              marginTop: "12px",
              marginBottom: "-4px",
            }}
          />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            padding="0"
            sx={{ justifyContent: "flex-end", alignItems: "center" }}
          >
            <Button
              style={{
                textTransform: "none",
                borderColor: "black",
                color: "black",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                textTransform: "none",
                color: "white",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              variant="contained"
              color="orangeTheme"
              disableElevation
              onClick={handleSubmitForm}
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </Box>
      {/* </div> */}
    </Modal>
  );
};

export default DerosteringTrackingUpdateDeroster;
