import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import VoluntaryAlignmentHeader from "./VoluntaryAlignmentHeader";
import ScorecardComponent from "../../component/common/scorecard/scorecard";
const VoluntaryAlignment = () => {
    const [voluntaryAlignmentID,setVoluntaryAlignmentID]=useState(8);
    return (
        <Box >
            <VoluntaryAlignmentHeader />
            <ScorecardComponent ScoreId={voluntaryAlignmentID} />
        </Box>
    );
}

export default VoluntaryAlignment;
