import { Box, Button, Grid } from "@mui/material";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { COLOR, Styles } from "../../Styles";
import { useAppDispatch } from "../../app/store";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Loader from "../../component/common/loader/Loader";
import DerosteringTrackingHeader from "./DerosteringTrackingHeader";
import { getDerosteringTrackingList } from "./derosteringTrackingSlice";

import { Link } from "react-router-dom";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import { checkUserToolAccess } from "../../component/common/userAuthorization/checkUserAccess";
import DerosteringMemberDetails from "./DerosteringMemberDetails";
import DerosteringTrackingUpdateDeroster from "./DerosteringTrackingUpdateDeroster";

import CustomPaginationSlot from "../../component/common/datagrid/CustomPaginationSlot";
import DerosteringAuditorModal from "./DerosteringAuditorModal";

const DerosteringTracking = () => {
  const DerosteringTrackerID = 2;
  const [accessObj] = useState(checkUserToolAccess(DerosteringTrackerID));
  const [showModal, setShowModal] = useState(false);
  const [showMemberDetailsModal, setShowMemberDetailsModal] = useState(false);
  const [selectedRowForModal, setSelectedRowForModal] = useState(null);
  const [search, setSearch] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [enableBulkUpdateButton, setEnableBulkUpdateButton] = useState(false);
  const [bulkUpdateModalOpen, setBulkUpdateModalOpen] = useState(false);
  var isAuditorFromStorage = localStorage.getItem("isAuditor");
  const isAuditor =
    isAuditorFromStorage && parseInt(isAuditorFromStorage) === 1 ? true : false;
  const [selectedRows, setSelectedRows] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(true);
  const [auditorAction, setAuditorAction] = useState(false);
  // const [pageSize, setPageS]
  const [sortModel, setSortModel] = useState([
    { field: "enrollmentEndDate", sort: "asc" },
  ]);

  const openModal = (row) => {
    setSelectedRowForModal(row);
    setShowModal(true);
  };

  function closeModal() {
    setShowModal(false);
    setBulkUpdateModalOpen(false);
  }

  const openMemberDetailsModal = (row) => {
    setSelectedRowForModal(row);
    setShowMemberDetailsModal(true);
  };

  function closeMemberDetailsModal() {
    setShowMemberDetailsModal(false);
  }

  const columns = [
    {
      field: "insuranceID",
      headerName: "Insurance Id",
      flex: 1,
    },
    {
      field: "patientName",
      headerName: "Member Name",
      flex: 1,
      renderCell: (row) => {
        return (
          <Link
            title={row.row.patientName}
            // href="#"
            style={{
              textDecoration: "underline",
              color: "#000000DE",
              fontFamily: "Poppins !important",
              fontSize: "14px !important",
            }}
            onClick={() => openMemberDetailsModal(row.row)}
          >
            {row.row.patientName}
          </Link>
        );
      },
    },
    {
      field: "memberDob",
      headerName: "Member DOB",
      type: "Date",
      flex: 1,
      renderCell: (params) =>
        params.row.memberDob &&
        format(new Date(params.row.memberDob), "dd MMM yyyy"),
    },
    {
      field: "payorName",
      headerName: "Payer Name",
      flex: 1,
    },

    {
      field: "payorContractType",
      headerName: "Payer Contract Type",
      flex: 1,
    },
    {
      field: "practiceName",
      headerName: "Practice Name",
      flex: 1,
    },

    {
      field: "enrollmentEndDate",
      headerName: "Identification Date",
      type: "Date",
      flex: 1,
      renderCell: (params) =>
        params.row.enrollmentEndDate &&
        format(new Date(params.row.enrollmentEndDate), "dd MMM yyyy"),
    },
    {
      field: "effectiveRequestdate",
      headerName: "Effective Request Date",
      type: "Date",
      flex: 1,
      renderCell: (params) =>
        params.row.effectiveRequestdate &&
        format(new Date(params.row.effectiveRequestdate), "dd MMM yyyy"),
    },
    {
      field: "derosteringStatus",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (row) => {
        if (!!row.row.enrollmentEndDate)
          if (row.row.derosteringStatusId !== 2) return;
        return (
          <Button
            disabled={!accessObj.isEditAccess}
            onClick={() => openModal(row.row)}
            variant="outlined"
            sx={{
              textTransform: "none",
              display: enableBulkUpdateButton ? "none" : "block",
              color: COLOR.FONT_ORANGE,
              border: "1px solid",
              borderColor: COLOR.ORANGE,
              textAlign: "center",
              leadingTrim: "both",

              fontFamily: Styles.FONT_FAMILY_POPPINS,
              fontSize: "12px",
              fontStyle: Styles.FONT_STYLE_NORMAL,
              fontWeight: "500",
              lineHeight: "22px",
              "&.MuiButton-root:hover": {
                borderColor: COLOR.ORANGE,
                border: "1px solid #F37021",
              },
            }}
          >
            De-roster
          </Button>
        );
      },
    },
  ];

  const dispatch = useAppDispatch();
  const {
    isLoading,
    derosteringTrackingList,
    derosteringTrackingListCount,
    errorMessage,
  } = useSelector((state) => state.derosteringTracking);

  const getDeRosteringTrackingListData = useCallback(
    async (sortColumn, sortOrder, page) => {
      if (!sortColumn || !sortOrder) {
        sortModel[0].field = "enrollmentEndDate";
        sortModel[0].sort = "asc";
        setSortModel(sortModel);
      }
      await dispatch(
        getDerosteringTrackingList({ search, sortColumn, sortOrder, page })
      );
    },
    [dispatch, search]
  );

  useEffect(() => {
    getDeRosteringTrackingListData();
    setSelectedRows([]);
    // setPage(1);
  }, [getDeRosteringTrackingListData, search, forceRefresh]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  useEffect(() => {
    if (auditorAction) {
      setPage(1);
      setAuditorAction(false);
    }
  }, [auditorAction]);

  const updateSearch = (search) => {
    setForceRefresh((prev) => !prev);
    setSearch(search);
  };

  function getRowId(row) {
    return row.ourID; //+ "_" + row.ourID; //+ "_" + row.profileId;
  }

  const handlePageChange = (page) => {
    getDeRosteringTrackingListData(sortModel[0].field, sortModel[0].sort, page);
  };

  useEffect(() => {
    // if (page !== 1)
    if (!initialLoad) {
      handlePageChange(page);
    } else {
      setInitialLoad(false);
    }
    // handlePageChange(page);
    //getFamilyMemberProfileListData("","",);
  }, [page]);

  const handleSortModelChange = (sortModel) => {
    let sort = null;
    if (sortModel.length > 0) {
      const { field, sort: sortOrder } = sortModel[0];
      sort = `${field}:${sortOrder}`;
      if (sortOrder === "asc") {
        sort = `${field}:desc`;
      }
    }
    setSortModel(sortModel);
    // setSortColumn(sortModel[0].field);

    // setSortOrder(sortModel[0].sort);
    setPage(1);
    getDeRosteringTrackingListData(sortModel[0].field, sortModel[0].sort, 1);
  };

  const handleSelectionChange = (selectionModel) => {
    const selectedIDs = new Set(selectionModel);
    const selectedData = derosteringTrackingList.filter((row) =>
      selectedIDs.has(row.ourID)
    );
    setSelectedRows(selectedData);
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      setEnableBulkUpdateButton(true);
    } else {
      setEnableBulkUpdateButton(false);
    }
  }, [selectedRows]);

  const handleBulkUpdateClick = () => {
    setBulkUpdateModalOpen(true);
  };

  const isRowSelectable = (params) => {
    if (!!params.row.enrollmentEndDate)
      if (
        params.row.derosteringStatusId === 2 ||
        params.row.derosteringStatusId === 3
      )
        return false;
      else return true;
    else return false; //If the member is not de rostered then row is not selectable
  };

  return (
    <Box
      sx={{
        width: "100%",
        //  p: { xs: 2, sm: 2 }
      }}
    >
      <DerosteringTrackingHeader
        accessObj={accessObj}
        // isGlobalAdmin={accessObj.isGlobalAdmin}
        updateSearch={updateSearch}
        enableBulkUpdateButton={enableBulkUpdateButton}
        handleBulkUpdateClick={handleBulkUpdateClick}
      />
      {!accessObj.hasAccess ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <div>
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <Grid item>
              <DataGridCustom
                columns={columns}
                checkboxSelection={isAuditor}
                onSelectionModelChanged={(newSelection) => {
                  handleSelectionChange(newSelection);
                }}
                className="pafGrid"
                isRowSelectable={isRowSelectable}
                rows={derosteringTrackingList}
                getRowId={getRowId}
                loading={isLoading}
                sortingOrder={["asc", "desc"]}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={(e) => handleSortModelChange(e)}
                slots={{
                  pagination: CustomPaginationSlot,
                }}
                slotProps={{
                  pagination: {
                    setPage: setPage,
                    paginationModel: {
                      count: Math.ceil(derosteringTrackingListCount / 10),
                      page: page,
                      totalRows: derosteringTrackingListCount,
                    },
                    colorTheme: "orangeTheme",
                    className: "pageNumber",
                  },
                }}
              />
            </Grid>
          )}
        </div>
      )}
      <DerosteringTrackingUpdateDeroster
        isOpen={showModal}
        onClose={closeModal}
        rowData={selectedRowForModal}
      />
      <DerosteringMemberDetails
        isOpen={showMemberDetailsModal}
        onClose={closeMemberDetailsModal}
        rowData={selectedRowForModal}
      />
      <DerosteringAuditorModal
        isOpen={bulkUpdateModalOpen}
        onClose={closeModal}
        rowData={selectedRows}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
        auditorAction={auditorAction}
        setAuditorAction={setAuditorAction}
      />
    </Box>
  );
};

export default DerosteringTracking;
