import { axiosRequests } from "../AxiosRequest";

function auditSignIn() {
  return axiosRequests.post(`/SignIn/AuditSignIn`);
}
function auditSignOut() {
  return axiosRequests.post(`/SignIn/AuditSignOut`);
}

export const LoginAgent = {
  auditSignIn: auditSignIn,
  auditSignOut: auditSignOut,
};
