
import React, { useState } from "react";
import { Download } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import PageHeader from "../../../component/common/header/PageHeader";
import { useSelector } from "react-redux";
import { COLOR, Styles } from "../../../Styles";
import agent from "../../../app/api/Agent";
import SnackbarAlert from "../../../component/common/snackbarAlert/SnackbarAlert";
import SDOHExcelExportTimelineModal from "../ExcelExport/SDOHExcelExportTimelineModal";
const SDOHAssessmentDashboardHeader = ({
    accessObj,
  }) => {
    const [open, setOpen] = React.useState(false);
    const title = "SDOH Assessment";
    const sevenPillarTitle = "Consumer Community Services";
    const [showTimelineModal, setShowTimelineModal] = useState(false);
    const [customModalCancel, setCustomModalCancel] = useState(false);

    const { showDashboardPage } = useSelector(
        (state) => state.filterDetails
    );

    const filter = useSelector(
        (state) => state.filterDetails.filterDetail
    );

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const openTimelineModal = (row) => {
        setShowTimelineModal(true);
    };
    function closeModal() {
        setShowTimelineModal(false);
        //setShowAddDateModal(false);
    }


    return (
        <div style={{
            display: (showDashboardPage === true) ? "" : "none"
        }}>
            <PageHeader title={title} sevenPillarTitle={sevenPillarTitle}>
                <Stack
                    direction="row"
                    spacing={2}
                    style={{
                        marginLeft: "auto",

                        float: "right",
                        alignItems: "center",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    <Button
                        sx={{
                            textTransform: "none",
                            color: "#111827",
                            border: "1px solid",
                            borderColor: COLOR.ORANGE,
                            textAlign: "center",
                            leadingTrim: "both",
                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                            fontSize: "12px",
                            fontStyle: Styles.FONT_STYLE_NORMAL,
                            fontWeight: "500",
                            lineHeight: "22px",
                            display: !accessObj || !accessObj.isGlobalAdmin ? "none" : "",
                            "&.MuiButton-root:hover": {
                                borderColor: COLOR.ORANGE,
                                border: "1px solid #F37021",
                            },
                        }}
                        variant="outlined"
                        color="orangeTheme"
                        onClick={() => openTimelineModal()}
                    >
                        <Stack
                            spacing={1}
                            direction="row"
                            sx={{
                                margin: 0,
                                padding: 0,
                                height: "100%",
                                alignContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Download
                                fontSize="small"
                            />
                            <Typography
                                sx={{
                                    color: "#343A40",
                                    textAlign: "center",
                                    leadingTrim: "both",
                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "22px"
                                }}
                            >
                                Export Report
                            </Typography>
                        </Stack>
                    </Button>
                </Stack>
            </PageHeader>
            <SDOHExcelExportTimelineModal
                isOpen={showTimelineModal}
                onClose={closeModal}
                setCustomModalCancel={setCustomModalCancel}
                formFilterData ={filter}
            />
            <SnackbarAlert
                message="Report Downloaded Successfully."
                open={open}
                handleClose={handleClose}
            />
            
        </div>
    );
}
export default SDOHAssessmentDashboardHeader;