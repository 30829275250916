import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  Slider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import { Styles } from "../../Styles";
import "../sdohNewAssessmentForm/SDOHAssessmentFormCSS.css";
import { useNavigate } from "react-router-dom";
import { PagesPath } from "../../PagesPath";
import { axiosRequests } from "../../app/api/AxiosRequest";
import {
  getShowAssessmentDetailPage,
  getShowDashboardPage,
} from "../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
const SDOHNewAssessmentForm_Preview = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [assessmentArrPreview, setAssessmentArrPreview] = useState([]);

  const { assessmentStore, memberId } = useSelector(
    (state) => state.filterDetails
  );

  useEffect(() => {
    setAssessmentArrPreview(assessmentStore.assessmentArr);
    window.scrollTo(0, 0);
  }, [assessmentStore]);

  useEffect(() => {
    if (
      assessmentStore &&
      assessmentStore?.assessmentArr != undefined &&
      assessmentStore?.assessmentArr.length > 0
    )
      setAssessmentArrPreview(assessmentStore.assessmentArr);
  }, []);

  const handleBack = (e) => {
    let hasError = false;
    props.onBackClick();
  };

  const handleFinishForm = (e) => {
    let hasError = false;
    props.onDataChange();

    let formDataArray = [];
    let obj = {};
    let score = 0;
    let categoryScore = 0;
    let categoryRiskScore = "";
    let memberRiskScore = "";
    let isHigh = false;
    let isMedium = false;
    let isLow = false;
    let checkedValues = "";
    let selectedDragValue = "";

    assessmentArrPreview.map((category) => {
      let objArr = [];
      categoryScore = 0;
      categoryRiskScore = "";
      category.questions.map((question) => {
        if (question.type === "Radio") {
          if (question.selectedValue != null) {
            score = 0;
            question.answers.map((answer) => {
              if (answer.answer == question.selectedValue) {
                score = answer.score;
                categoryScore = categoryScore + answer.score;
              }
            });
            objArr.push({
              assessmentName: question.question,
              selectedValue: question.selectedValue,
              answerType: question.type,
              valueScore: score,
            });
          }
        }
        if (question.type === "Drag") {
          score = 0;
          selectedDragValue = "";
          if (question.selectedValue != null) {
            score = question.selectedValue;
            categoryScore = categoryScore + question.selectedValue;
            question.answers.map((answer) => {
              if (answer.score == question.selectedValue) {
                selectedDragValue = answer.answer;
              }
            });
          } else {
            score = 1;
            categoryScore = categoryScore + 1;
            selectedDragValue = "Never";
          }

          objArr.push({
            assessmentName: question.question,
            selectedValue: selectedDragValue, //question.selectedValue,
            answerType: question.type,
            valueScore: score,
          });
        }
        if (question.type === "Checkbox") {
          score = 0;
          checkedValues = "";
          question.answers.map((answer) => {
            if (answer?.checked) {
              score = score + answer.score;
              categoryScore = categoryScore + answer.score;
              if (checkedValues != "")
                checkedValues = checkedValues + "|" + answer.answer;
              else checkedValues = answer.answer;
            }
          });
          objArr.push({
            assessmentName: question.question,
            selectedValue: checkedValues, //question.selectedValue,
            answerType: question.type,
            valueScore: score,
          });
        }
      });
      if (category.category === "Living situation") {
        if (categoryScore == 0) categoryRiskScore = "Low Risk";
        else if (categoryScore == 1) categoryRiskScore = "Medium Risk";
        else categoryRiskScore = "High Risk";
      }
      if (category.category === "Food") {
        if (categoryScore == 0) categoryRiskScore = "Low Risk";
        else if (categoryScore >= 1 && categoryScore <= 2)
          categoryRiskScore = "Medium Risk";
        else categoryRiskScore = "High Risk";
      }
      if (category.category === "Transportation") {
        if (categoryScore == 0) categoryRiskScore = "Low Risk";
        else categoryRiskScore = "High Risk";
      }
      if (category.category === "Utilities") {
        if (categoryScore == 0) categoryRiskScore = "Low Risk";
        else if (categoryScore == 1) categoryRiskScore = "Medium Risk";
        else categoryRiskScore = "High Risk";
      }
      if (category.category === "Safety") {
        if (categoryScore <= 6) categoryRiskScore = "Low Risk";
        else if (categoryScore > 6 && categoryScore <= 10)
          categoryRiskScore = "Medium Risk";
        else categoryRiskScore = "High Risk";
      }
      formDataArray.push({
        name: category.category,
        categoryScore: categoryScore,
        categoryRiskScore: categoryRiskScore,
        assessments: objArr,
      });
      if (categoryRiskScore === "High Risk") isHigh = true;
      else if (categoryRiskScore === "Medium Risk") isMedium = true;
      else isLow = true;
    });

    if (isHigh) memberRiskScore = "High";
    else if (isMedium) memberRiskScore = "Medium";
    else memberRiskScore = "Low";

    const formData = {
      OurId: props.ourId ? props.ourId : memberId,
      memberRiskScore: memberRiskScore, //"High",
      formData: formDataArray,
    };

    axiosRequests.post("/SDOH/AddNewAssesment", formData).then((resp) => {
      // console.log("resp",resp);
      if (resp === 1) {
          props.onDataChange();
      }
    });
  };

  const onClose = () => {
    // var idUrl = PagesPath.SDOHAssessmentDashboard_URL;
    // navigate(idUrl, { searchValue: "" });
    if(props.handleAssessmentCancel){
      props.handleAssessmentCancel();
    }
    else{
    dispatch(getShowAssessmentDetailPage(false));
    dispatch(getShowDashboardPage(true));
    }
  };

  const marks = [
    {
      value: 1,
      label: "Never",
    },
    {
      value: 2,
      label: "Rarely",
    },
    {
      value: 3,
      label: "Sometimes",
    },
    {
      value: 4,
      label: "Fairly Often",
    },
    {
      value: 5,
      label: "Frequently",
    },
  ];

  function valuetext(value) {
    return value; //`${value}°C`;
  }

    return (
        <Box sx={{ p: { xs: 2, sm: 2 } }}>
            <Grid container>
                <Grid item xs={12} >
                    <div style={{ padding: 10 }}>
                        {/* {assessmentLoad} */}
                        {
                            (assessmentArrPreview ? assessmentArrPreview?.map(detail =>
                                <Accordion defaultExpanded key={detail.category}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        sx={{
                                            borderBottom: '1px solid rgba(0, 0, 0, .125)',
                                            minHeight: '45px !important',
                                            '& .Mui-expanded': {
                                                margin: '0 !important',
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                textAlign: "left",
                                                letterSpacing: "1px",
                                                color: "#111827B8",
                                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                fontWeight: "500",
                                                lineHeight: "17px",
                                                fontSize: "14px",
                                                paddingRight: 2

                                            }}
                                        >
                                            {detail.category}
                                        </Typography>
                                    </AccordionSummary>

                                    <AccordionDetails >
                                        <span>{detail?.questions.map(detailAssessment =>

                                            <FormControl sx={{ paddingTop: 2, paddingLeft: 2 }} key={detailAssessment.question}>
                                                <FormLabel id="demo-radio-buttons-group-label">
                                                    <Grid container>
                                                        <Typography
                                                            sx={{
                                                                textAlign: "left",
                                                                letterSpacing: "1px",
                                                                color: "#111827B8",
                                                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                fontWeight: "500",
                                                                lineHeight: "17px",
                                                                fontSize: "14px",
                                                                paddingTop: 1
                                                            }}
                                                        >
                                                            {detailAssessment.question}
                                                        </Typography>
                                                    </Grid>
                                                </FormLabel>

                                                {(detailAssessment.type == 'Radio') ? (
                                                    <RadioGroup
                                                        className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                                                        style={{
                                                            padding: 10,
                                                            color: "orangeTheme",
                                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                            fontWeight: "400",
                                                            lineHeight: "24px",
                                                            fontSize: "16px",
                                                            letterSpacing: "0.15px",

                                                        }}
                                                        // defaultValue={(detailAssessment.defaultValue != "") ? detailAssessment.defaultValue : ""}
                                                        sx={{
                                                            '& .Mui-checked': {
                                                                color: '#333 !important',
                                                            },
                                                        }}
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        name="radio-buttons-group"
                                                        value={(detailAssessment.selectedValue != null ?
                                                            detailAssessment.selectedValue : "")
                                                        }
                                                    >
                                                        {detailAssessment.answers.map(detailChecklist =>
                                                            <FormControlLabel value={detailChecklist.answer} control={<Radio />} color="orangeTheme" label={detailChecklist.answer} 
                                                            key={detailChecklist.answer}/>
                                                        )}

                                                    </RadioGroup>
                                                ) : (<span></span>)}

                                                {(detailAssessment.type == 'Checkbox') ? (
                                                    <FormGroup
                                                        className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                                                        style={{
                                                            padding: 10,
                                                            color: "#000000DE",
                                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                            fontWeight: "400",
                                                            lineHeight: "24px",
                                                            fontSize: "16px",
                                                            letterSpacing: "0.15px",
                                                        }}
                                                        sx={{
                                                            '& .Mui-checked': {
                                                                color: '#333 !important',
                                                            },
                                                        }}
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        name="radio-buttons-group"
                                                    >
                                                        {detailAssessment.answers.map(detailChecklist =>
                                                            <FormControlLabel value={detailChecklist.answer} control={<Checkbox
                                                                style={{ fontSize: "14px" }}
                                                                checked={detailChecklist?.checked != undefined ? detailChecklist.checked : false}
                                                            />} color="orangeTheme" key={detailChecklist.answer} label={detailChecklist.answer}
                                                            />
                                                        )}

                                                    </FormGroup>
                                                ) : (<span></span>)}


                                                {(detailAssessment.type == 'Drag') ? (
                                                    <Box sx={{ width: "95%", padding: 2 }}>
                                                        <Slider
                                                        className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                                                            aria-label="Custom marks"
                                                            value={(detailAssessment.selectedValue != null ?
                                                                detailAssessment.selectedValue : "")}
                                                            color="orangeTheme"
                                                            getAriaValueText={valuetext}
                                                            step={1}
                                                            min={1}
                                                            max={5}
                                                            valueLabelDisplay="auto"
                                                            marks={marks}
                                                            sx={{
                                                                '& .MuiSlider-markLabel': {
                                                                    paddingLeft: 4,
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                ) : <span></span>}
                                            </FormControl>
                                        )}</span>
                                    </AccordionDetails>
                                </Accordion>

                            ) : <span />)



                        }
                    </div>
                </Grid>
            </Grid>

            <Stack
                direction="column"
                sx={{
                    marginTop: 4,
                }}
            ></Stack>
            <div style={{
                display:
                    (props.isDisabledMode || !props.isButtonsReqd) ? 'none' : 'block'
            }}>
                <Grid container justifyContent="left">
                    <Grid item xs={12}>
                        <Button style={{ color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="contained" color="orangeTheme" disableElevation onClick={handleFinishForm}>Finish And Preview</Button>
                        &nbsp;
                        <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={onClose}>Cancel</Button>
                    </Grid>
                </Grid>
            </div>

            <div style={{
                display:
                    (!props.isDisabledMode || !props.isButtonsReqd) ? 'none' : 'block'
            }}>
                <Grid container spacing={1} justifyContent="left">
                    <Grid item xs={12}>
                        <Button style={{ color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="contained" color="orangeTheme" disableElevation onClick={handleFinishForm}>Submit</Button>
                        &nbsp;
                        <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={handleBack}>Back</Button>
                        &nbsp;
                        <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={onClose}>Cancel</Button>
                    </Grid>
                </Grid>
            </div>

        </Box>
    );
}

export default SDOHNewAssessmentForm_Preview;
