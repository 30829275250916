import { Block, Image } from "@mui/icons-material";
import React, { useEffect, useState, useCallback, useRef } from "react";
import education from "../../../assets/education.svg";
import educationOrange from "../../../assets/EducationOrange.svg";
import practiceAdminToolOrange from "../../../assets/practiceAdminToolOrange.svg";
import landingPageBackgroudImage from "../../../assets/Landing_page_BG_image.jpg";
// import landingPageBackgroudImage from "../../../assets/Overlayed_BG.jpg";
import familyMemberToolOrange from "../../../assets/familyMemberToolOrange.svg";
import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { Styles } from "../../../Styles";
import ImageBlock from "../imageBlock/ImageBlock";
import { Link } from "react-router-dom";
import SevenPillarMenu from "../servenPillarMenu/sevenPillarMenu";
import Marquee from "react-fast-marquee";
import { PagesPath } from "../../../PagesPath";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import { getPillarsToolsScorecardsList } from "../../../features/pillarsToolsScorecards/pillarsToolsScorecardsSlice";
import { useAppDispatch } from "../../../app/store";

const LandingComponent = () => {
  const [marqueeText, setMarqueeText] = useState("");
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const [isPracticeAdmin, setIsPracticeAdmin] = useState(false);
  const hasHealthHomeAccess =
    parseInt(localStorage.getItem("hasHealthHomeAccess")) === 1 ? true : false;
  const dispatch = useAppDispatch();

  useEffect(() => {
    axiosRequests.get("/Banner").then((resp) => {
      //setBannerText(resp);
      setMarqueeText(
        resp.join("\xa0\xa0\xa0\xa0\xa0\xa0\xa0|\xa0\xa0\xa0\xa0\xa0\xa0\xa0")
      );
      console.log(resp);
    });
  }, []);
  useEffect(() => {
    var globalAdmin = localStorage.getItem("globalAdmin");
    if (parseInt(globalAdmin) === 1) {
      setIsGlobalAdmin(true);
    }
    if (JSON.parse(localStorage.getItem("userAccess"))) {
      var practiceAdmin = JSON.parse(
        localStorage.getItem("userAccess")
      ).roleType;
      if (parseInt(practiceAdmin) === 2) {
        setIsPracticeAdmin(true);
      }
    }
  }, []);

  const getPillarsToolsScorecardListData = useCallback(async () => {
    await dispatch(getPillarsToolsScorecardsList());
  }, [dispatch]);

  useEffect(() => {
    getPillarsToolsScorecardListData();
  }, [getPillarsToolsScorecardListData]);

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.6)),url(${landingPageBackgroudImage})`,
        backgroundSize: "cover",
        height: "100%",
        width: "100%",
        backgroundRepeat: `no-repeat`,
        backgroundSize: "115%",
      }}
    >
      <Marquee
        className="testMarquee"
        style={{
          color: "#2A2A2A",
          // opacity: "85%",
          fontFamily: Styles.FONT_FAMILY_POPPINS,
          fontWeight: 500,
          fontSize: "12px",
          paddingTop: "5px",
          background: "#EDEDED",
          alignItems: "center",
        }}
      >
        {marqueeText}
      </Marquee>
      <Stack
        sx={{
          alignItems: "center",
          width: "fit-content",
          // background: "#212121",
          position: "absolute",
          bottom: 0,
          marginLeft: 13,
          marginBottom: 13,
          p: 1,
          borderRadius: "4px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#FFF",
            leadingTrim: "both",
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            fontSize: "48px",
            fontStyle: "normal",
            fontWeight: "600",
            //lineHeight: "22px;",
            textAlign: "left",
            // marginLeft: "-35px",
          }}
        >
          Seven Pillar Platform
        </Typography>
        <Divider
          sx={{
            background: "rgba(200, 202, 204, 0.32)",
            height: "1px",
            marginLeft: 2.1,
            marginRight: 2.1,
          }}
          flexItem
        />
        <Stack spacing={0}>
          <Stack direction="row" spacing={2} sx={{ m: 2 }}>
            <Link
              to={PagesPath.Programs_URL}
              key="Programs"
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
              }}
            >
              <ImageBlock img={educationOrange} text="Education" />
            </Link>
            <Link
              to={PagesPath.FAMILY_MEMBER_PROFILE_URL}
              key="FamilyMemberProfile"
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
              }}
            >
              <ImageBlock img={familyMemberToolOrange} text="Family Member" />
            </Link>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.findhelp.org/"
              target="_blank"
            >
              <ImageBlock text="SDOH Help" />
            </a>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ mr: 2, ml: 2 }}>
            {(isGlobalAdmin || isPracticeAdmin) && (
              <Link
                to={PagesPath.UserAdminConsoleURL}
                key="BannerComponentURL"
                style={{
                  textDecoration: "none",
                  underline: "hover",
                  color: "inherit",
                }}
              >
                <ImageBlock
                  img={practiceAdminToolOrange}
                  text="Practice Admin Tool"
                />
              </Link>
            )}
            {hasHealthHomeAccess && (
              <Link
                to={PagesPath.HealthHomeTrainingPrograms_URL}
                key="HealthHomeConfig"
                style={{
                  textDecoration: "none",
                  underline: "hover",
                  color: "inherit",
                }}
              >
                <ImageBlock
                  img={educationOrange}
                  text="Health Home Education"
                />
              </Link>
            )}
          </Stack>
        </Stack>
      </Stack>

      <SevenPillarMenu />
    </Box>
  );
};

export default LandingComponent;
