import React, { useEffect, useState, useCallback, useRef } from "react";
import {
    Box,
    Grid,
    Modal,
    Stack,
    Typography,
    Button,
    TextField,
    FormControl, FormHelperText, Tooltip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { COLOR, Styles } from "../../../../Styles";
import agent from "../../../../app/api/Agent";
import { format } from "date-fns";
import { alpha, styled } from '@mui/material/styles';
import Loader from "../../../../component/common/loader/Loader";
const MemberFilterDetailsModal = ({ isOpen, onClose, rowData, cohortId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [memberCostDetails, setMemberCostDetails] = useState({});
    const [memberEventsList, setMemberEventsList] = useState([]);
    const [cohortFilterDetails, setCohortFilterDetails] = useState({});
    const { admissionTrendsDetails } = useSelector(
        (state) => state.filterDetails
    );
    const [startDate, setStartDate] = React.useState(new Date());
    const [podName, setPodName] = React.useState("");
    const [cohortName, setCohortName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [cohortOwner, setCohortOwner] = React.useState("");
    const [admissionTrsValue, setAdmissionTrsValue] = React.useState("");
    // const { cohortDetails, cohortFilterDetails } = useSelector(
    //     (state) => state.filterDetails
    // );
    const [payerDtl, setPayerDtl] = useState("");
    const [prodDtl, setProdDtl] = useState("");
    useEffect(() => {
        if (isOpen === true) {
            setIsLoading(true);

            agent.SDOHAssessmentTrackingAgent.GetCohortViewDetails(cohortId
            )
                .then((response) => {
                    if (response[0] != undefined && response[0]?.cohortId != undefined) {
                        let cohortDetails = response[0];
                        let responseobj = {
                            podName: cohortDetails?.podName,
                            cohortName: cohortDetails?.cohortName,
                            description: cohortDetails?.description,
                            cohortOwner: cohortDetails?.cohortOwner,
                            eventStartDate: cohortDetails?.startDate,

                            EventYear: cohortDetails?.dateRange,
                            payerDtl: cohortDetails?.payerName,
                            prodDtl: cohortDetails?.productName,
                            eventFromDate: cohortDetails?.fromDate,
                            eventEndDate: cohortDetails?.toDate
                        }
                        let stDate = format(cohortDetails?.startDate, "yyyy-MM-dd");
                        setStartDate(stDate);
                        setPodName(cohortDetails?.podName);
                        setCohortName(cohortDetails?.cohortName);
                        setDescription(cohortDetails?.description);
                        setCohortOwner(cohortDetails?.cohortOwner);
                        setCohortFilterDetails(responseobj);

                        if ( cohortDetails?.dateRange == 1) {
                            setAdmissionTrsValue("2024");
                        }
                        else if ( cohortDetails?.dateRange == 2) {
                            setAdmissionTrsValue("2023");
                        }
                        else if ( cohortDetails?.dateRange == 3) {
                            setAdmissionTrsValue("0 - 3 Months");
                        }
                        else if ( cohortDetails?.dateRange == 4) {
                            setAdmissionTrsValue("3 - 6 Months");
                        }
                        else if ( cohortDetails?.dateRange == 5) {
                            setAdmissionTrsValue("0 - 6 Months");
                        }
                        else if ( cohortDetails?.dateRange == 6) {
                            setAdmissionTrsValue("6 - 9 Months");
                        }
                        else if ( cohortDetails?.dateRange == 7) {
                            setAdmissionTrsValue("0 - 9 Months");
                        }
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log("exc-GetCOHORTMemberCostDetails", err);
                });
        }
    }, [isOpen])


    // if (!rowData) return;

    const OutlinedButton = styled(Button)(() => ({
        borderRadius: "4px",
        padding: "6px",
        justifyContent: "center",
        color: COLOR.BLACK,
        borderColor: COLOR.BLACK,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "capitalize",
    }));

    const ContainedButton = styled(Button)(() => ({
        variant: "contained",
        borderRadius: "4px",
        padding: "6px",
        background: COLOR.PRIMARY_ORANGE,
        justifyContent: "center",
        color: COLOR.WHITE,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "none",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
        ":hover": {
            background: COLOR.SECONDARY_ORANGE,
        },
    }));

    const DefaultTypography = styled(Typography)({
        color: "#000",
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    });

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            slotProps={{ backdrop: { onClick: null } }}
        >
            <Box sx={{
                position: "absolute",
                top: "40%",
                left: "78%",
                transform: "translate(-50%,-40%)",
                width: "38rem",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                pt: 1,
                height: "100vh",
                overflow: "auto",
                backgroundColor: "#FFF9F6"

            }}>
                <h3
                    style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
                >
                    Cohort Details
                    <span>
                        <CloseIcon onClick={onClose} style={{ float: "right" }} />
                    </span>
                </h3>
                <hr
                    style={{
                        width: "auto",
                        opacity: "0.3",
                        marginTop: "-11px",
                        marginBottom: "5px",
                    }}
                />
                <Grid container sx={{
                    paddingTop: 1,
                    // minHeight: "100%",
                    // // marginLeft: "1%",
                    // boxShadow: "0px 3px 46px 0px rgba(0, 0, 0, 0.12)",
                    // border: "1px solid rgba(0,0,0,.12)",
                    // backgroundColor: "white",
                }} >
                    <Grid item xs={12} >
                        <Stack direction="column" spacing={1}
                        // sx={{ paddingLeft: "25px", paddingRight: "25px" }}
                        >
                            <Grid container >
                                <Grid item xs={7} >
                                    <Stack direction="column" spacing={1} sx={{
                                        paddingRight: "25px",

                                    }}
                                    >
                                        <TextField
                                            style={{
                                                width: "34rem",
                                                backgroundColor: "white"
                                            }}
                                            className="disabledAccordian"
                                            id="outlined-multiline-static"
                                            label="POD Name"
                                            value={podName}
                                            // disabled={props.isDisabledMode}
                                            // onChange={(e) => handlePodNameChange(e)}
                                            multiline
                                            // className="denyComments"
                                            color="orangeTheme"
                                            rows={1}
                                            variant="outlined"
                                        />
                                        <TextField
                                            style={{
                                                width: "34rem",
                                                backgroundColor: "white"
                                            }}

                                            id="outlined-multiline-static"
                                            label="Cohort Name"
                                            value={cohortName}
                                            // disabled={props.isDisabledMode}
                                            multiline
                                            // className="denyComments"
                                            className="disabledAccordian"
                                            color="orangeTheme"
                                            rows={1}
                                            variant="outlined"
                                        />
                                        <TextField
                                            style={{
                                                width: "34rem",
                                                backgroundColor: "white"
                                            }}
                                            id="outlined-multiline-static"
                                            label="Description"
                                            value={description}
                                            className="disabledAccordian"
                                            // disabled={props.isDisabledMode}
                                            multiline
                                            // className="denyComments"
                                            color="orangeTheme"
                                            rows={2}
                                            variant="outlined"
                                        />

                                        <TextField
                                            style={{
                                                width: "34rem",
                                                backgroundColor: "white"
                                            }}
                                            id="outlined-multiline-static"
                                            label="Cohort Owner"
                                            value={cohortOwner}
                                            // disabled={props.isDisabledMode}
                                            multiline
                                            // className="denyComments"
                                            className="disabledAccordian"
                                            color="orangeTheme"
                                            rows={1}
                                            variant="outlined"
                                        />
                                        <TextField
                                            style={{
                                                maxWidth: "180px",
                                                minWidth: "160px",
                                                backgroundColor: "white"
                                            }}
                                            id="outlined-multiline-static"
                                            label="Start Date"
                                            value={startDate}
                                            // disabled={props.isDisabledMode}
                                            multiline
                                            // className="denyComments"
                                            className="disabledAccordian"
                                            color="orangeTheme"
                                            rows={1}
                                            variant="outlined"
                                        />
                                        
                                    </Stack>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12}>
                                    <Stack direction="column" spacing={1} sx={{
                                        // border: "1px solid #FFDFC3",
                                        // backgroundColor: "white",
                                        padding: "15px",
                                        width: "34rem",
                                        border: "1px solid #E0E0E0",
                                        backgroundColor: "#FAFAFA"

                                    }}>
                                        <h3
                                            style={{
                                                fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px",
                                            }}
                                        >
                                            Filters
                                        </h3>

                                        <Typography style={{
                                            fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                        }}>
                                            Event Year : &nbsp;
                                            {admissionTrsValue},

                                        </Typography>

                                        <Typography style={{
                                            fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                        }}>
                                            Payer : &nbsp;
                                            {cohortFilterDetails?.payerDtl}
                                        </Typography>

                                        <Typography style={{
                                            fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                        }}>
                                            Product : &nbsp;
                                            {cohortFilterDetails?.prodDtl}
                                        </Typography>

                                        <h3
                                            style={{
                                                fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px",
                                            }}
                                        >
                                            Date Range
                                        </h3>
                                        <Stack direction="row" spacing={1} sx={{
                                            // border: "1px solid #FFDFC3",
                                            // backgroundColor: "white",
                                            width: "95%",
                                        }}>
                                            <Typography style={{
                                                fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                            }}>
                                                From : &nbsp;
                                                {cohortFilterDetails?.eventFromDate
                                                    ? format(new Date(cohortFilterDetails?.eventFromDate), "yyyy-MM-dd")
                                                    : ""}
                                            </Typography>

                                            <Typography style={{
                                                fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                            }}>
                                                To : &nbsp;
                                                {cohortFilterDetails?.eventEndDate
                                                    ? format(new Date(cohortFilterDetails?.eventEndDate), "yyyy-MM-dd")
                                                    : ""}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>

                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default MemberFilterDetailsModal;