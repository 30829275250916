import axios from "axios";

import { powerBiAuthenticationScope } from "../../authConfig";
import { msalInstance } from "../../index";

export async function getToken() {
  // var token = `Bearer ${localStorage.getItem("accessToken")}`;
  var scopes = powerBiAuthenticationScope;
  const currentAccount = msalInstance.getActiveAccount();
  const accessTokenRequest = {
    scopes: scopes,
    account: currentAccount,
  };
  if (currentAccount) {
    // if (currentAccount.tenantId == msalConfig.auth.tenantId) {
    const accessTokenResponse = await msalInstance.acquireTokenSilent(
      accessTokenRequest
    );
    localStorage.setItem("powerBiAccessToken", accessTokenResponse.accessToken);
    return `Bearer ${accessTokenResponse.accessToken}`;
    // }
  }
  return null;
}

const responseBody = (response) => ({
  ...response.data,
  accessToken: localStorage.getItem("powerBiAccessToken"),
});
export const powerBiAxiosRequests = {
  getPowerBi: (url) => powerBiAxiosPrivate.get(url).then(responseBody),
};

export const powerBiAxiosPrivate = axios.create({});

powerBiAxiosPrivate.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      return Promise.reject({
        ...error,
        message: "Error: You don't have access to Power BI Report",
      });
    }
    return Promise.reject(error);
  }
);

powerBiAxiosPrivate.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = await getToken();
    return config;
  },
  async function (error) {
    if (error.response.status === 401) {
      return Promise.reject({
        ...error,
        message: "Error: You don't have access to Power BI Report",
      });
    }
    return Promise.reject(error);
  }
);
