import React from "react";
import {
    Box, Grid, Stack, Typography, Chip, Link, Divider, Button, FormControl,
    FormLabel, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import { Styles } from "../../../Styles";
import { format } from "date-fns";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../PDFDownload/PDFDownloadcss.css";
const TypographyLabel = ({ children }) => {
    return (
        <Typography
            sx={{
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
            }}
        >
            {children}
        </Typography>
    );
};

const TypographyDetail = ({ children }) => {
    return (
        <Typography
            sx={{

                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
            }}
        >
            {children}
        </Typography>
    );
};
const PdfTemplateMemberDetails = ({ logoSrc, titleText, formData, signatureImage }) => (

    <div style={{ padding: '20px' }}>
        <Grid container direction="column" alignItems="center">
            <Grid item >

                <h3
                    style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
                >
                    Member Details
                </h3>

                <Stack spacing={2} sx={{ marginTop: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Typography sx={{ fontSize: "14px" }}>
                                <b>Name :</b> {formData.memberName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography sx={{ fontSize: "14px" }}>
                                <b>DOB :</b> {formData?.dob
                                    ? format(new Date(formData?.dob), "dd MMM yyyy")
                                    : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{ fontSize: "14px" }}>
                                <b>Gender :</b> {formData.gender}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography sx={{ fontSize: "14px" }}>
                                <b>Phone :</b> {formData.phone}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <Typography sx={{ fontSize: "14px" }}>
                                <b>Address :</b> {formData.address}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography sx={{ fontSize: "14px" }}>
                                <b>Email :</b> {formData.email}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>

                        <Grid item xs={7}>

                            <Typography sx={{ fontSize: "14px" }}>
                                <b>Practice Name :</b> {formData.practiceName}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography sx={{ fontSize: "14px" }}>
                                <b>Provider Name :</b> {formData.providerName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography sx={{ fontSize: "14px" }}>
                                <b>Language : </b>
                                {formData.language}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{ fontSize: "14px" }}>
                                <b>Ethnicity : </b>
                                {formData.ethinicity}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography sx={{ fontSize: "14px" }}>
                                <b>Race : </b>
                                {formData.race}
                            </Typography>
                        </Grid>

                        {/*<Grid item xs={4.5}>
                                                         <Typography sx={{ fontSize: "14px" }}>
                                                                <b>Provider NPI:</b> {member.providerNPI}
                                                            </Typography> 
                                                    </Grid>*/}
                    </Grid>

                </Stack>

                <Divider
                    sx={{
                        margin: 1.5,
                        background: "#E0E0E0",
                        color: "#E0E0E0"
                    }}
                    orientation="horizontal"
                />

                <Grid container>
                    <Grid item xs={6} md={6} alignContent="center">
                        <h3 style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }} >
                            Highest SDOH Risk And Areas Of Concern
                        </h3>
                    </Grid>
                    <Grid item xs={6} md={6} alignContent="center">

                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} >
                        <Stack spacing={2} sx={{ marginTop: "20px" }}>
                            <Stack direction="row" alignItems="baseline" spacing={2}>
                                <Stack direction="row" spacing={2} >
                                    <TypographyLabel>SDOH Risk :</TypographyLabel>
                                    <TypographyDetail>

                                        <div item xs={2.28} className="statusClass"
                                            style={{
                                                alignContent: "center",
                                                borderRadius: "24px",
                                                height: "40px",
                                                width: "100px",
                                                backgroundColor: `${formData?.scoreBackgroundColor}`,//,//"#FFCD3D",//`${color}`,//{{value>100?'#FFCD3D':'#FFFBF7'}},
                                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                color: "white",
                                                textAlign: "center",
                                                fontSize: "12px",
                                                marginTop: "-10px",
                                                fontStyle: "normal",
                                                fontWeight: "300",
                                                lineHeight: "normal"
                                            }}
                                        >{formData?.memberRiskScore}</div>
                                    </TypographyDetail>
                                </Stack>

                                <Stack direction="row" spacing={2} marginRight={1}>
                                    <TypographyLabel>Areas of Concern :</TypographyLabel>
                                    <TypographyDetail>
                                        {(formData?.name) ? formData?.name.substring(0, 120) : ""}
                                    </TypographyDetail>
                                </Stack>
                            </Stack>


                        </Stack>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item >
                        <h3 style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }} >
                            Section wise Score
                        </h3>
                    </Grid>
                </Grid>
                <Grid container >
                    {formData.statusOrderJson?.map((row, index) => {
                        const { value, label, backgroundColor, borderColor, hoverColor, redirectURL } =
                            row;
                        return (
                            <Grid item xs={2.28} className="statusClass"
                                sx={{
                                    alignContent: "center",
                                    borderRadius: "4px",
                                    height: "70px",
                                    backgroundColor: `${backgroundColor}`,//,//"#FFCD3D",//`${color}`,//{{value>100?'#FFCD3D':'#FFFBF7'}},
                                    marginRight: "1%",
                                    borderBottom: `3px solid ${borderColor}`,//"3px solid rgba(255, 205, 61, 1)",
                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                }}
                                key={index}
                            >
                                <a
                                    style={{ textDecoration: "none", marginLeft: "-10px !important" }}
                                    href={redirectURL}
                                    target="_blank"
                                >
                                    <Stack
                                        direction="row"
                                        spacing={1.5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                            // marginLeft:"1%"
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: "#212121",
                                                fontSize: "16px",
                                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                lineHeight: "30px",
                                                letterSpacing: "0.15px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {value}
                                        </Typography>
                                        <Typography
                                            style={{
                                                color: "#212121",
                                                fontSize: "16px",
                                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                lineHeight: "30px",
                                                letterSpacing: "0.15px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {label}
                                        </Typography>
                                        <div className="statusOverlay" >
                                            <ChevronRightIcon fontSize="medium" className="statusOverlayIcon"
                                                sx={{ display: "flex", color: `${borderColor}`, alignItems: "center" }} />
                                        </div>
                                    </Stack>
                                </a>
                            </Grid>
                        );
                    })}
                </Grid>

                <Grid container>
                    <Grid item xs={6} md={6} alignContent="center">
                        <h3 style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }} >
                            Assessment Results
                        </h3>
                    </Grid>
                    <Grid item xs={6} md={6} alignContent="center">
                        <Stack direction="row" spacing={2} marginRight={1} justifyContent="flex-end">
                            <TypographyLabel>Submitted Date :</TypographyLabel>
                            <TypographyDetail>
                                {formData?.submittedDate
                                    ? format(new Date(formData?.submittedDate), "dd MMM yyyy")
                                    : ""}
                            </TypographyDetail>
                        </Stack>
                    </Grid>
                </Grid>
                
                <Grid container>
                    <Grid item xs={12} >
                        <div style={{ marginTop: "16px" }}>
                            {
                                (formData.assessmentQuestionsPreview ? formData.assessmentQuestionsPreview?.map((detail, index) =>

                                    <div item xs={2.28}
                                    style={{paddingTop: "15px",}}
                                    >
                                        <Grid container>

                                            <Grid item
                                                className="statusClass"
                                                style={{
                                                    alignContent: "center",
                                                    // borderRadius: "24px",
                                                    minHeight: "140px",
                                                    border: "1px solid #E2E2E2",
                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                    fontSize: "12px",
                                                    fontStyle: "normal",
                                                    fontWeight: "300",
                                                    lineHeight: "normal",
                                                    paddingTop: "15px",
                                                    paddingBottom: "15px"
                                                }}>

                                                <Grid container>
                                                    <Grid item xs={12} style={{
                                                        fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px',
                                                        borderBottom: '1px solid rgba(0, 0, 0, .125)',
                                                        minHeight: '45px !important',
                                                        paddingTop: "10px",
                                                        paddingBottom: "10px"
                                                    }}>
                                                        <Typography sx={{
                                                            textAlign: "left",
                                                            letterSpacing: "1px",
                                                            color: "#111827B8",
                                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                            fontWeight: "500",
                                                            lineHeight: "17px",
                                                            fontSize: "14px",
                                                            paddingRight: 2,
                                                            paddingLeft: "15px",
                                                        }} >
                                                            {detail.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <span>{detail?.assessmentDetails.map((detailAssessment, index) =>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                sx={{
                                                                    textAlign: "left",
                                                                    letterSpacing: "1px",
                                                                    color: "#111827B8",
                                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                    fontWeight: "500",
                                                                    lineHeight: "17px",
                                                                    fontSize: "14px",
                                                                    paddingTop: 1,
                                                                    paddingLeft: "15px",
                                                                }}
                                                            >
                                                                {detailAssessment.flagDescription}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Typography
                                                                sx={{
                                                                    textAlign: "left",
                                                                    letterSpacing: "1px",
                                                                    color: "#000",
                                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                    fontWeight: "500",
                                                                    lineHeight: "17px",
                                                                    fontSize: "14px",
                                                                    paddingTop: 1.5,
                                                                    paddingLeft: "15px",
                                                                }}
                                                            >
                                                                {detailAssessment.value}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                )}</span>
                                            </Grid>
                                        </Grid>
                                        {
                                            (detail.name === 'Living situation') ?
                                                <div className="break-page">&nbsp;</div>
                                                // style={{ pageBreakBefore: 'always' }}
                                                : <span></span>}
                                    </div>
                                ) : <span />)
                            }
                        </div>
                    </Grid>
                </Grid>
                
            </Grid>
        </Grid>
    </div>
)

export default PdfTemplateMemberDetails;