const UI_URLS = {
  LOCAL_DEV: "http://localhost:3000",
  UAT: "https://7pillars-uat.azurewebsites.net/",
  SIT: "https://7pillars-sit.azurewebsites.net/",
  DEV: "https://7pillars-dev.azurewebsites.net/",
  PROD: "https://7pillars.azurewebsites.net/",
  DEFAULT: "https://7pillersui.azurewebsites.net/",
};

export const getRedirectURL = () => {
  let location = window.location.href;
  if (location.toLowerCase().includes("localhost")) return UI_URLS.LOCAL_DEV;
  else if (location.toLowerCase().includes("7pillars-uat")) return UI_URLS.UAT;
  else if (location.toLowerCase().includes("7pillars-sit")) return UI_URLS.SIT;
  else if (location.toLowerCase().includes("7pillars-dev")) return UI_URLS.DEV;
  else if (location.toLowerCase().includes("7pillars.azurewebsites.net"))
    return UI_URLS.PROD;
  else return UI_URLS.DEFAULT;
};

const EXTERNAL_URLS = {
  LOCAL_DEV: "http://localhost:4000/",
  UAT: "https://7pillars-uat-external.azurewebsites.net/",
  SIT: "https://7pillars-sit-external.azurewebsites.net/",
  DEV: "https://7pillars-dev-external.azurewebsites.net/",
  PROD: "https://7pillars-external.azurewebsites.net/",
  DEFAULT: "https://7pillers-external.azurewebsites.net/",
};

export const getRedirectExternalURL = () => {
  let location = window.location.href;
  if (location.toLowerCase().includes("localhost")) return EXTERNAL_URLS.LOCAL_DEV;
  else if (location.toLowerCase().includes("7pillars-uat")) return EXTERNAL_URLS.UAT;
  else if (location.toLowerCase().includes("7pillars-sit")) return EXTERNAL_URLS.SIT;
  else if (location.toLowerCase().includes("7pillars-dev")) return EXTERNAL_URLS.DEV;
  else if (location.toLowerCase().includes("7pillars.azurewebsites.net"))
    return EXTERNAL_URLS.PROD;
  else return EXTERNAL_URLS.DEFAULT;
};
const API_URLS = {
  LOCAL_DEV: "https://localhost:7060/api",
  UAT: "https://7pillarsapi-uat.azurewebsites.net/api",
  SIT: "https://7pillarsapi-sit.azurewebsites.net/api",
  DEV: "https://7pillarsapi-dev.azurewebsites.net/api",
  PROD: "https://7pillarsapi.azurewebsites.net/api",
  DEFAULT: "https://7pillersapi.azurewebsites.net/api",
};

export const getAPI_URL = () => {
  let location = window.location.href;
  if (location.toLowerCase().includes("localhost")) return API_URLS.LOCAL_DEV;
  else if (location.toLowerCase().includes("7pillars-uat")) return API_URLS.UAT;
  else if (location.toLowerCase().includes("7pillars-sit")) return API_URLS.SIT;
  else if (location.toLowerCase().includes("7pillars-dev")) return API_URLS.DEV;
  else if (location.toLowerCase().includes("7pillars.azurewebsites.net"))
    return API_URLS.PROD;
  else return API_URLS.DEFAULT;
};

const PILLAR_URLS = {
  LOCAL_DEV: "pillarReturn=http:$$localhost:3000",
  UAT: "pillarReturn=https:$$7pillars-uat.azurewebsites.net",
  SIT: "pillarReturn=https:$$7pillars-sit.azurewebsites.net",
  DEV: "pillarReturn=https:$$7pillars-dev.azurewebsites.net",
  PROD: "pillarReturn=https:$$7pillars.azurewebsites.net",
  DEFAULT: "pillarReturn=https:$$7pillersui.azurewebsites.net",
};

export const getPILLAR_URL = () => {
  let location = window.location.href;
  if (location.toLowerCase().includes("localhost"))
    return PILLAR_URLS.LOCAL_DEV;
  else if (location.toLowerCase().includes("7pillars-uat"))
    return PILLAR_URLS.UAT;
  else if (location.toLowerCase().includes("7pillars-sit"))
    return PILLAR_URLS.SIT;
  else if (location.toLowerCase().includes("7pillars-dev"))
    return PILLAR_URLS.DEV;
  else if (location.toLowerCase().includes("7pillars.azurewebsites.net"))
    return PILLAR_URLS.PROD;
  else return PILLAR_URLS.DEFAULT;
};

export const PerformanceURL = "https://performancedemo.cinq.care/";

const DailyCensusReturnURL = `&${getPILLAR_URL()}$sevenpillar$AvoidableInpatientStays$tools$CensusToolMarketHospitalPratice`;

export const DAILY_CENSUS_URLS = {
  DailyCensus: `${PerformanceURL}CINQ/DailyCensus/?ViewType=IP${DailyCensusReturnURL}`,
  FDCByDayAndTime: `${PerformanceURL}Census/CensusDayTime/?ViewType=IP${DailyCensusReturnURL}`,
  FDCByDay: `${PerformanceURL}Census/CensusEventByDay/?ViewType=IP${DailyCensusReturnURL}`,
  FDCByMember: `${PerformanceURL}Census/CensusEventByMember/?ViewType=IP${DailyCensusReturnURL}`,
  FDCByPostpartum: `${PerformanceURL}Census/CensusPostPartum/?ViewType=IP${DailyCensusReturnURL}`,
  FDCByPractice: `${PerformanceURL}Census/CensusWeekPractice/?ViewType=IP${DailyCensusReturnURL}`,
  FDCbyWeekTrend: `${PerformanceURL}Census/CensusWeekERTrend/?ViewType=IP${DailyCensusReturnURL}`,
};

const DailyCensusERReturnURL = `&${getPILLAR_URL()}$sevenpillar$NonEmergentER$tools$CensusToolMarketHospitalPraticeER`;

export const DAILY_CENSUS_ER_URLS = {
  DailyCensus: `${PerformanceURL}CINQ/DailyCensus/?ViewType=ER${DailyCensusERReturnURL}`,
  FDCByDayAndTime: `${PerformanceURL}Census/CensusDayTime/?ViewType=ER${DailyCensusERReturnURL}`,
  FDCByDay: `${PerformanceURL}Census/CensusEventByDay/?ViewType=ER${DailyCensusERReturnURL}`,
  FDCByMember: `${PerformanceURL}Census/CensusEventByMember/?ViewType=ER${DailyCensusERReturnURL}`,
  FDCByPostpartum: `${PerformanceURL}Census/CensusPostPartum/?ViewType=ER${DailyCensusERReturnURL}`,
  FDCByPractice: `${PerformanceURL}Census/CensusWeekPractice/?ViewType=ER${DailyCensusERReturnURL}`,
  FDCbyWeekTrend: `${PerformanceURL}Census/CensusWeekERTrend/?ViewType=ER${DailyCensusERReturnURL}`,
};

const ERAlertClosureReturnURL = `${getPILLAR_URL()}$sevenpillar$NonEmergentER$tools$ERAlertToolfromTexting`;

export const ER_ALERT_CLOSURE_URL = {
  ERAlertClosure: `${PerformanceURL}Census/PERDA?${ERAlertClosureReturnURL}`,
};

const IPAlertToolReturnURL = `${getPILLAR_URL()}$sevenpillar$AvoidableInpatientStays$tools$IPAlertToolFromTexting`;

export const IP_ALERT_TOOL_URL = {
  IPAlertTool: `${PerformanceURL}Census/PIPDA?${IPAlertToolReturnURL}`,
};

const CohortRegistryReturnURL = `${getPILLAR_URL()}$sevenpillar$HighRiskFamilyMemberCare$tools$CohortRegistryManagement`;
const CohortCensusReturnURL = `&${getPILLAR_URL()}$sevenpillar$HighRiskFamilyMemberCare$tools$CohortRegistryManagement`;
export const COHORT_REGISTRY_URL = {
  CohortRegistry: `${PerformanceURL}Census/cohort?${CohortRegistryReturnURL}`,
  CohortCensus: `${PerformanceURL}Census/CensusEventByMember/?ViewType=IP${CohortCensusReturnURL}`,
};

const PillarReturn = `${getPILLAR_URL()}`;

export const PERFORMANCE_DIRECT_URLS = {
  ERAlertClosureURL: `${PerformanceURL}Census/PERDA?${PillarReturn}`,
  IPAlertToolURL: `${PerformanceURL}Census/PIPDA?${PillarReturn}`,
  CohortRegistryURL: `${PerformanceURL}Census/cohort?${PillarReturn}`,
};

export const FamilyMemberProfilePillarReturn = `?${getPILLAR_URL()}$FamilyMemberProfile`;
